import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import "../components/side-nav.element"
import { ProxyService } from "../../services/proxy.service";
import { DestinationService } from "../../services/destination.service";

@customElement("se-user-settings")
export class SeUserSettingsElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _userService: UserService;
    private _proxyService: ProxyService;
    private _destinationService: DestinationService;
    @state() private _menuItems = [];
    @state() private _titleOverride;
    @state() private _currentBreadcrumb: string = '';
    @state() private _userName: string = '';
    @state() private _userRole: string = '';
    @state() private _userEmail: string = '';
    @state() private _currentPath: string = '';

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
        
        this._userService = container.resolve(UserService);
        this._proxyService = container.resolve(ProxyService);
        this._destinationService = container.resolve(DestinationService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -4;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._initializeMenuItems();
        this._initializeUserInfo();
        
        this.handleRouteChange();
        this._updateCurrentPath();
        window.addEventListener('vaadin-router-location-changed', () => {
            this.handleRouteChange();
            this._updateCurrentPath();
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('vaadin-router-location-changed', () => {
            this.handleRouteChange();
            this._updateCurrentPath();
        });
    }

    private _updateCurrentPath() {
        const fullPath = window.location.pathname;
        const baseUrlPattern = new RegExp(`^/user/`);
        this._currentPath = fullPath.replace(baseUrlPattern, '').split('/')[0];
    }

    private _handleNavClick(path: string) {
        Router.go(`/user/${path}`);
        this._currentPath = path;
    }

    private _initializeUserInfo() {
        // Get user name from auth service
        this._userName = this._authService.user?.name || this._authService.user?.email || 'User';
        
        // Set user role based on auth service permissions
        this._userRole = this._authService.isSE4Admin ? 'SE4 Admin' : 
                        this._authService.isOrgAdmin ? 'Org Admin' : 'User';

        // set user email
        this._userEmail = this._authService.user?.email || '';
    }

    private _getBreadcrumbFromPath(): string {
        const pathParts = window.location.pathname.split('/');
        const lastPart = pathParts[pathParts.length - 1];
        // url to readable names
        const routeMap: { [key: string]: string } = {
            'api-keys': 'Api Keys',
            'change-password': 'Change Password',
            'logout': 'Logout',
        };

        return routeMap[lastPart] || lastPart;
    }

    private async handleRouteChange() {
        const pathParts = window.location.pathname.split('/');
        
        if(pathParts.includes('user'))
        {
            if (pathParts.includes('api-keys')) {
                await this.updateApiKeysTitle(pathParts);
            } else if (pathParts.includes('new') || pathParts.includes('invite')) {
                // handle new case
                const entityType = pathParts[pathParts.indexOf(pathParts.includes('new') ? 'new' : 'invite') - 1];
                await this.updateNewTitle(entityType);
            } else {
                // handle default case
                this._titleOverride = '';
                this._currentBreadcrumb = this._getBreadcrumbFromPath();
            }
        }
    }

    /**
     * Update the breadcrumb for the API Keys page
     */
    private async updateApiKeysTitle(pathParts: string[]) {
        // handle api keys special url case
        if (pathParts.includes('edit')) {

            //update for editing keys
            const keyId = pathParts[pathParts.indexOf('edit') + 1];
            this._titleOverride = html`<a href="/user/api-keys">API Keys</a> <span>/</span> <span>Edit</span>`;
            this._currentBreadcrumb = this._titleOverride;
        } else if(pathParts.includes('new')){
            this._titleOverride = html`<a href="/user/api-keys">API Keys</a> <span>/</span> <span>New</span>`;
            this._currentBreadcrumb = this._titleOverride;
        } else {
            this._titleOverride = html`<span>API Keys</span>`;
            this._currentBreadcrumb = this._titleOverride;
        }
    }


    private async updateNewTitle(entityType: string) {
        switch (entityType) {
            // case 'users':
            //     this._titleOverride = html`<a href="/org/users">Users</a><span>/</span><span>Invite</span>`;
            //     break;
            // case 'proxies':
            //     this._titleOverride = html`<a href="/org/proxies">Proxy Pools</a><span>/</span><span>New</span>`;
            //     break;
            // case 'destinations':
            //     this._titleOverride = html`<a href="/org/destinations">Destinations</a><span>/</span><span>New</span>`;
            //     break;
        }
        this._currentBreadcrumb = this._titleOverride;
    }

    public async onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ){
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }            
    }

    private _initializeMenuItems() {
        const baseItems = [
            { label: "Api Keys", path: "api-keys" },
            { label: "Change Password", path: "change-password" },
            { label: "Logout", path: "logout" }
        ];


        this._menuItems = [...baseItems];
    }

    render() {
        return html`
            <div class="body">
                <div class="top-bar">
                    <div class="breadcrumbs">
                        <a>Manage</a>
                        <span>/</span>
                        <a href="/user/${window.location.pathname?.split('/')?.[2] || 'api-keys'}">User</a>
                        ${this._currentBreadcrumb ? html`
                            <span>/</span>
                            ${this._currentBreadcrumb}
                        ` : ''}
                    </div>
                </div>
                
                <div class="content-container">
                    <div class="sidebar">
                        
                        <div class="nav-list">
                            ${this._menuItems.map(item => html`
                                <div
                                    @click=${() => this._handleNavClick(item.path)}
                                    class="nav-item ${this._currentPath === item.path ? 'active-nav-item' : ''}"
                                >
                                    ${item.label}
                                </div>
                            `)}
                        </div>
                        
                        <div class="user-info">
                            <div class="user-name">${this._userName}</div>
                            <div class="user-email">${this._userEmail}</div>
                            <div class="user-role">${this._userRole}</div>
                        </div>
                    </div>
                    
                    <div class="main-content">
                        <h2>${this._titleOverride || this._menuItems.find(item => item.path === this._currentPath)?.label || ''}</h2>
                        <slot></slot>
                    </div>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        .top-bar {
            background-color: var(--color-blue-25);
            padding: 15px;
            border-bottom: solid 1px var(--color-navy-3);
            width: 100%;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a{
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover{
            text-decoration: underline;
        }
        .content-container {
            display: flex;
            flex: 1;
            overflow: hidden;
        }
        .sidebar {
            display: flex;
            flex-direction: column;
            background-color: var(--color-blue-25);
            border-right: solid 1px var(--color-gray-2);
            min-width: 200px;
            max-width: 350px;
            width: fit-content;
            padding: 15px 0 0;
            justify-content: space-between;
        }
        .nav-list {
            display: flex;
            flex-direction: column;
            padding: 0 10px;
        }
        .user-info {
            display: flex;
            flex-direction: column;
            padding: 15px 20px;
            border-top: solid 1px var(--color-gray-2);
            width: 100%;
            box-sizing: border-box;
        }
        .user-name, .user-email, .user-role {
            word-break: break-word;
            max-width: 100%;
            overflow-wrap: break-word;
            hyphens: auto;
        }
        .user-name {
            font-weight: 600;
            font-size: 1.2rem;
            color: var(--color-navy-9);
            overflow-wrap: break-word;
            hyphens: auto;
        }
        .user-email {
            font-size: 14px;
            color: var(--color-navy-7);
            font-weight: 400;
            overflow-wrap: break-word;
            hyphens: auto;
            margin-bottom: 8px;
        }
        .user-role {
            font-size: 14px;
            color: var(--color-navy-7);
            font-weight: 600;
            overflow-wrap: break-word;
            hyphens: auto;
        }
        .nav-item {
            cursor: pointer;
            padding: 5px 10px;
            position: relative;
            color: var(--color-navy-3);
            text-wrap: nowrap;
            margin-bottom: 5px;
        }
        .active-nav-item {
            color: var(--color-purple);
        }
        .active-nav-item::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .nav-item:hover {
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px;
        }
        .main-content {
            flex-grow: 1;
            padding: 15px 15px 55px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            overflow-y: auto;
        }
        h2 {
            font: var(--font-h2);
            font-weight: 600;
            margin: 0px 0px 10px;
        }
        
        /* Rest of the styles */
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .header {
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
            margin-bottom: 10px;
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
        }
    `;
}

