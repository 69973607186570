import { css, html, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { OrganizationViewModel } from "../../models/organization-view-model";
import { SubscriptionPlan } from "../../models/subscription-plan-view-model";
import { OrganizationSubscription } from "../../models/organization-subscription";
import { ToasterService } from "se-shared/services/toaster.service";
import { SubscriptionPlanService } from "../../services/subscription-plan.service";
import { AuditLogService } from "../../services/audit-log.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import "../components/organizations/organization-subscription-form.element";
import { AccountAuditLogModel, AuditAccountObjectType, AuditLogOperation } from "../../models/audit-log";
import { DataGridColumn } from "../components/data-grid-template";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import "../components/data-grid.element";
import "../components/pagination.element";
import { SortOrder } from "se-shared/enums/sort-order";
import { RouterLocation, PreventAndRedirectCommands } from "@vaadin/router";
import { OrganizationService } from "../../services/organization.service";
import { Router } from "@vaadin/router";

@customElement("se-organization-subscription-editor")
export class SeOrganizationSubscriptionEditorElement extends LitElement {
    private _toasterService: ToasterService;
    private _subscriptionPlanService: SubscriptionPlanService;
    private _auditService: AuditLogService;
    private _modalService: ModalDialogService;

    @property() organization: OrganizationViewModel;
    @state() private _subscriptionPlans: SubscriptionPlan[] = [];
    @state() private _selectedPlanId: number = 0;
    @state() private _isNew: boolean = true;
    @state() private _subscription: OrganizationSubscription = new OrganizationSubscription();
    private _originalCustomSubscription: OrganizationSubscription | null = null;

    // Audit log related properties
    @state() private _auditData: AccountAuditLogModel[] = [];
    @state() private _auditColumns: DataGridColumn[] = [];
    @state() private _auditPageIndex = 1;
    @state() private _auditRecordsPerPage = 10;
    @state() private _auditTotalRecordCount: number = 0;
    @state() private _isAuditLoading = true;
    @state() private _auditSortColumn = "created";
    @state() private _auditSortOrder = 0;

    // URL synchronization state variables
    private _auditPaginationInitialized = false;
    private _auditSortInitialized = false;

    constructor() {
        super();
        this._toasterService = container.resolve(ToasterService);
        this._subscriptionPlanService = container.resolve(SubscriptionPlanService);
        this._auditService = container.resolve(AuditLogService);
        this._modalService = container.resolve(ModalDialogService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        const orgId = Number(location.params.orgId);
        
        this.organization = new OrganizationViewModel();
        this.organization.id = orgId;
    }

    connectedCallback() {
        super.connectedCallback();
        
        // Listen for URL initialization events from pagination and data grid
        this.addEventListener('url-initialized', this._handleAuditPaginationInitialized);
        this.addEventListener('sort-initialized', this._handleAuditSortInitialized);
        
        this.loadOrganizationData();
        this.loadSubscriptionPlans();
        this.loadCurrentSubscription();
        this.setupAuditColumns();
    }
    
    disconnectedCallback() {
        // Remove URL initialization event listeners
        this.removeEventListener('url-initialized', this._handleAuditPaginationInitialized);
        this.removeEventListener('sort-initialized', this._handleAuditSortInitialized);
        
        super.disconnectedCallback();
    }

    private _handleAuditPaginationInitialized = (event: CustomEvent) => {
        this._auditPaginationInitialized = true;
        
        // Update page index from the event detail
        if (event.detail && event.detail.pageIndex) {
            this._auditPageIndex = event.detail.pageIndex;
        }
        
        this._tryLoadAuditData();
    }

    private _handleAuditSortInitialized = (event: CustomEvent) => {
        this._auditSortInitialized = true;
        
        // Update local sort variables from the event detail
        if (event.detail) {
            const { sortColumn, sortOrder } = event.detail;
            if (sortColumn) {
                this._auditSortColumn = sortColumn;
                this._auditSortOrder = sortOrder === SortOrder.Ascending ? 1 : 0;
            }
        }
        
        this._tryLoadAuditData();
    }

    private _tryLoadAuditData() {
        // Load data when both components have initialized
        if (this._auditPaginationInitialized && this._auditSortInitialized) {
            this.loadAuditData();
        }
    }

    private async loadOrganizationData() {
        if (!this.organization?.id) {
            return;
        }
        
        const organizationService = container.resolve(OrganizationService);
        const res = await organizationService.api.getAsync(this.organization.id);
        if (res.isOk) {
            this.organization = res.value;
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    private async loadSubscriptionPlans() {
        const result = await this._subscriptionPlanService.api.getAllSubscriptionPlansAsync();
        if (result.isOk) {
            this._subscriptionPlans = result.value.subscriptionPlans;
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    private async loadCurrentSubscription() {
        const result = await this._subscriptionPlanService.api.getOrganizationSubscriptionAsync(this.organization.id);
        if (result.isOk) {
            this._subscription = result.value;
            this._selectedPlanId = this._subscription.subscriptionPlanId || 0;
            this._isNew = false;
            if (!this._subscription.subscriptionPlanId) {
                this._originalCustomSubscription = { ...this._subscription };
            }
        } else {
            this._subscription = new OrganizationSubscription();
            this._subscription.orgId = this.organization.id;
            this._selectedPlanId = 0;
            this._isNew = true;
        }
    }

    private setupAuditColumns() {
        this._auditColumns = [
            { field: "description", title: "Description" },
            { field: "initiatedBy", title: "Initiated By", align: "center" },
            {
                field: "created",
                title: "Created",
                align: "center",
                sortable: true,
                template: (row) => {
                    const date = new Date(row.created);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
        ];
    }

    private async loadAuditData() {
        this._isAuditLoading = true;
        try {
            const result = await this._auditService.api.getSubscriptionLogAsync(
                this.organization.id,
                this._auditPageIndex,
                this._auditRecordsPerPage,
                this._auditSortColumn,
                this._auditSortOrder
            );
            
            if (result.isOk) {
                if (!result.value.auditLog) {
                    this._toasterService.showWarning("Audit Logs are disabled for this Organization.");
                    return;
                }
                this._auditData = result.value.auditLog;
                this._auditTotalRecordCount = result.value.totalRecordCount;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isAuditLoading = false;
        }
    }

    private async openAuditDetailsAsync(row: AccountAuditLogModel) {
        const details = await this._auditService.api.getAccountLogDetailsAsync(this.organization.id, row.id);
        if (details.isOk) {
            this._modalService.openObjectInfoDialogAsync({
                title: "Subscription Audit Log Details",
                anyObject: {
                    ...Object.keys(details.value).reduce((data, key) => {
                        data[this.formatKey(key)] = details.value[key];
                        return data;
                    }, {}),
                    ...Object.keys(row).reduce((data, key) => {
                        data[this.formatKey(key)] = row[key];
                        return data;
                    }, {}),
                },
            });
        } else {
            this._toasterService.showNetworkError(details.err);
        }
    }

    // Format key strings like "configName" to "Config Name"
    private formatKey(key: string): string {
        return key.replace(/([A-Z])/g, ' $1').trim()
            .replace(/^[a-z]/, letter => letter.toUpperCase());
    }

    private onAuditPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        if (this._auditPageIndex !== evt.detail.pageIndex) {
            this._auditPageIndex = evt.detail.pageIndex;
            this.loadAuditData();
        }
    }

    private refreshAuditLog() {
        this.loadAuditData();
    }

    private async onToggleCancel() {
        const confirmResult = await this._modalService.openConfirmDialogAsync({
            title: this._subscription.isCanceled ? "Resume Subscription" : "Cancel Subscription",
            body: this._subscription.isCanceled 
                ? `Are you sure you want to resume the subscription for ${this.organization.name}?`
                : `Are you sure you want to cancel the subscription for ${this.organization.name}?`,
            saveCaption: this._subscription.isCanceled ? "Resume" : "Cancel",
        });

        if (confirmResult.isSave) {
            const result = this._subscription.isCanceled
                ? await this._subscriptionPlanService.api.resumeOrganizationSubscriptionAsync(this.organization.id)
                : await this._subscriptionPlanService.api.cancelOrganizationSubscriptionAsync(this.organization.id);

            if (result.isOk) {
                this._toasterService.showSuccess(`Subscription ${this._subscription.isCanceled ? 'resumed' : 'canceled'} successfully`);
                Router.go("/manage/admin/organizations");
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async onSave(name: string, price: number, runTime: number, exportGb: number, proxyData: number) {
        this._subscription.name = name;
        this._subscription.price = price;
        this._subscription.runUsage = runTime;
        this._subscription.exportedData = exportGb;
        this._subscription.proxyUsage = proxyData;
        this._subscription.endDate = null;

        const result = this._isNew
            ? await this._subscriptionPlanService.api.createOrganizationSubscriptionAsync(this._subscription)
            : await this._subscriptionPlanService.api.updateOrganizationSubscriptionAsync(this._subscription);

        if (result.isOk) {
            this._toasterService.showSuccess(`Subscription ${this._isNew ? 'created' : 'updated'} successfully`);
            Router.go("/manage/admin/organizations");
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    private onPlanChange(planId: number) {
        this._selectedPlanId = planId;
        const existingId = this._subscription.id;
        
        if (planId === 0) {
            if (this._originalCustomSubscription) {
                this._subscription = { ...this._originalCustomSubscription };
            } else {
                var subId = this._subscription.id;
                this._subscription = new OrganizationSubscription();
                this._subscription.id = subId;
                this._subscription.orgId = this.organization.id;
                this._subscription.name = '';
                this._subscription.price = 0;
                this._subscription.runUsage = 0;
                this._subscription.exportedData = 0;
                this._subscription.proxyUsage = 0;
            }
        } else {
            const selectedPlan = this._subscriptionPlans.find(p => p.id === planId);
            if (selectedPlan) {
                this._subscription = {
                    ...this._subscription,
                    id: existingId,
                    orgId: this.organization.id,
                    subscriptionPlanId: selectedPlan.id,
                    name: selectedPlan.name,
                    price: selectedPlan.price,
                    runUsage: selectedPlan.runUsage,
                    exportedData: selectedPlan.exportedData,
                    proxyUsage: selectedPlan.proxyUsage
                };
            }
        }
        this._isNew = !existingId;
        this.requestUpdate();
    }

    private cancel() {
        Router.go("/manage/admin/organizations");
        this.dispatchEvent(new CustomEvent("close", { detail: { isSave: false } }));
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const newSortColumn = evt.detail.sortColumn || this._auditSortColumn;
        const newSortOrder = evt.detail.sortOrder === SortOrder.Ascending ? 1 : 0;
        
        if (newSortColumn !== this._auditSortColumn || newSortOrder !== this._auditSortOrder) {
            this._auditSortColumn = newSortColumn;
            this._auditSortOrder = newSortOrder;
            this.loadAuditData();
        }
    }

    render() {
        return html`
            <se-organization-subscription-form
                .isNew=${this._isNew}
                .subscriptionPlans=${this._subscriptionPlans}
                .selectedPlanId=${this._selectedPlanId}
                .subscription=${{
                    name: this._subscription.name,
                    price: this._subscription.price,
                    runUsage: this._subscription.runUsage,
                    exportedData: this._subscription.exportedData,
                    proxyUsage: this._subscription.proxyUsage,
                    isCanceled: this._subscription.isCanceled,
                    endDate: this._subscription.endDate
                }}
                .onSave=${this.onSave.bind(this)}
                .onCancel=${this.cancel.bind(this)}
                .onPlanChange=${this.onPlanChange.bind(this)}
                .onToggleCancel=${this.onToggleCancel.bind(this)}
            ></se-organization-subscription-form>

            <div class="audit-section">
                <h2>Subscription History</h2>
                <div class="audit-header">
                    <div class="left-header">
                        <se-secondary-button
                            @click=${this.refreshAuditLog}
                            ${htmlTitle("Refresh")}
                            .customStyle=${{ padding: "1px 6px", margin: 0 }}
                            icon="far fa-redo"
                        ></se-secondary-button>
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._auditData}
                    .recordsPerPage=${this._auditRecordsPerPage}
                    .pageIndex=${this._auditPageIndex}
                    .columns=${this._auditColumns}
                    placeholder="No subscription audit data available."
                    .isLoading=${this._isAuditLoading}
                    @sortdata=${this.sortDataGrid}
                    .sortColumn=${this._auditSortColumn}
                    .sortOrder=${this._auditSortOrder === 1 ? SortOrder.Ascending : SortOrder.Descending}
                    .syncWithUrl=${true}
                ></se-data-grid>
                <se-pagination 
                    .recordCount=${this._auditTotalRecordCount} 
                    .recordsPerPage=${this._auditRecordsPerPage} 
                    @pagechanged=${this.onAuditPageChanged}
                    .pageIndex=${this._auditPageIndex}
                    .syncWithUrl=${true}
                ></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
        }
        .form {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px;
        }
        .audit-section {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            max-width: 1000px;
        }
        .audit-header {
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            min-height: 200px;
        }
        h2 {
            margin: 10px 0;
            font-weight: 600;
        }
    `;
}