import { AnyObject } from 'chart.js/dist/types/basic';
import { container, singleton } from 'tsyringe';
import { AgentPricingViewModel } from '../models/agent-pricing-view-model';
import { AuthService } from './auth.service';
import { BaseApi } from './base.api';
import { CombinedPricing } from '../models/combined-pricing';


@singleton()
export class AgentPricingApi extends BaseApi {

    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(configId: number) {
        return this.getObjectAsync<AgentPricingViewModel>(`/api/pricing/${configId}`, this._authService.token);
    }

    async getSystemLevelPricingAsync() {
        return this.getObjectAsync<string>(`/api/pricing/systemLevel`, this._authService.token);
    }

    async saveAgentPricingAsync(pricing: AgentPricingViewModel) {
        return this.postObjectReturnObjectAsync<string>(`/api/pricing/save`, pricing, this._authService.token);
    }

    async updateAgentPricingAsync(pricing: AgentPricingViewModel) {
        return this.putObjectReturnObjectAsync<string>(`/api/pricing/update`, pricing, this._authService.token);
    }

    async getCombinedPricingAsync(configId: number) {
        return this.getObjectAsync<CombinedPricing>(`/api/pricing/combined/${configId}`, this._authService.token);
    }
    
}