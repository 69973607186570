import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { styleMap } from "lit/directives/style-map.js";
import { when } from "lit/directives/when.js";
import prettyBytes from "pretty-bytes";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { RunDetailType } from "../../enums/run-detail-type";
import { isQueuingOrStarting, isRunningOnServer, RunStatus } from "../../enums/run-status";
import { RunFileViewModel } from "../../models/run-file-model";
import { RunDetailsViewModel, RunViewModel } from "../../models/run-view-model";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { RunService } from "../../services/run.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import "../components/secondary-button-link.element";
import downloadIcon from "../../../../assets/download-icon.svg";
import { SeDataGrid } from "../components/data-grid.element";
import { ParallelExport } from "../../enums/parallel-export";
import { formatSize } from "se-shared/utils/utils";
import { classMap } from "lit/directives/class-map.js";

@customElement("se-run-details")
export class RunDetailsElement extends LitElement {
    private _modalService: ModalDialogService;
    private _runService: RunService;
    private _authService: AuthService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _ws: WebSocket;
    private _isOpen = false;
    private _isImage = false;
    private _browserId: number;
    private _browsers: {
        [browserId: number]: {
            data?: unknown;
            dataChanged?: boolean;
            imageUrl?: string;
            log?: { level: number; mes: string }[];
            logCounter?: number;
            logChanged: boolean;
            html?: string;
            htmlChanged?: boolean;
            showLog?: boolean;
            pauseLog?: boolean;
            progress?: { message?: string; url?: string; status?: RunStatus };
            progressChanged?: boolean;
        };
    } = {};

    refreshTimer?: number;

    private _columns: DataGridColumn[] = [];
    private _fileColumns: DataGridColumn[] = [];
    @state() private _data: RunDetailsViewModel;
    @state() private _files: RunFileViewModel[];
    private _gridStyle = {};

    @property({ type: Number }) configId: number;
    @property({ type: Number }) runId: number;

    readonly BROWSERS_PER_PAGE = 8;
    private pageIndex = 1;

    @state() private _isLoading = true;

    @query("grid") private _dataGrid: SeDataGrid;

    constructor() {
        super();
        this._runService = container.resolve(RunService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.initializeAsync();
        window.addEventListener("resize", () => this.calculateGridStyle());
    }
    disconnectedCallback() {
        window.removeEventListener("resize", () => this.calculateGridStyle());
        super.disconnectedCallback();
    }

    private getStatus(color: string, text: string, row: RunDetailsViewModel): TemplateResult {
        if (row.message && !isRunningOnServer(row.status)) {
            return html`<se-status
                ${htmlTitle(row.message, true)}
                status-message="${text}" 
                status-color="${color}" 
                style="width: 100%; max-width: 110px"
                dotted
                ></se-status>`;
        } else {
            return html`<se-status style="width: 100%; max-width: 110px" status-message="${text}" status-color="${color}"></se-status>`;
        }
    }

    private getRate(row) {
        const seconds = row.runTimeSec ?? row.toalTime ?? 0;
        return seconds ? (row.pageCount / seconds).toFixed(2) : "n/a";
    }

    private getStats(row: RunDetailsViewModel): TemplateResult {
        if (!row.actionCount && !row.dataCount && !row.errorCount) {
            if (isRunningOnServer(row.status)) {
                return html`<fa-icon single-color="silver" fa-class="far fa-spinner fa-spin"></fa-icon>`;
            } else {
                return html`n/a`;
            }
        } else {
            const rate = this.getRate(row);
            const totalTime = this.formatTotalTime(row);
            return html`<span
                ${htmlTitle(
                    html` <style>
                            .stats-table td:nth-child(2) {
                                text-align: right;
                            }
                            .stats-table td:nth-child(1) {
                                padding-right: 5px;
                            }
                        </style>
                        <table class="stats-table">
                            <tr>
                                <td>Actions:</td>
                                <td>${row.actionCount}</td>
                            </tr>
                            <tr>
                                <td>Data:</td>
                                <td>${row.dataCount}</td>
                            </tr>
                            <tr>
                                <td>Errors:</td>
                                <td>${row.errorCount}</td>
                            </tr>
                        </table>
                        <hr />
                        <table class="stats-table">
                            <tr>
                                <td>Total Pages:</td>
                                <td>${row.pageCount}</td>
                            </tr>
                            <tr>
                                <td>Dynamic pages:</td>
                                <td>${row.dynamicPageCount}</td>
                            </tr>
                            <tr>
                                <td>Rate (pages/sec):</td>
                                <td>${rate}</td>
                            </tr>
                            <tr>
                                <td>Requests:</td>
                                <td>${row.requestCount}</td>
                            </tr>
                            ${row.inputCount
                                ? html`<tr>
                                      <td>Inputs:</td>
                                      <td>${row.inputCount}</td>
                                  </tr>`
                                : html``}
                            ${row.exportCount || row.exportCount === 0
                                ? html`<tr>
                                      <td>Exported data:</td>
                                      <td>${row.exportCount}</td>
                                  </tr>`
                                : html``}
                            <tr>
                                <td>Traffic:</td>
                                <td>${formatSize(row.traffic)}</td>
                            </tr>
                            ${row.parallelism > 1
                                ? html`<tr>
                                      <td>Parallel export:</td>
                                      <td>${row.parallelExport ?? "Combined"}</td>
                                  </tr>`
                                : html``}
                            <tr>
                                <td>${row.parallelism > 1 && row.parallelSet === 0 ? "Active" : "Run time"}:</td>
                                <td style="white-space: nowrap;">${totalTime}</td>
                            </tr>
                        </table>`,
                    true
                )}
                style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
                >a:${row.actionCount}&nbsp;d:${row.dataCount}&nbsp;e:${row.errorCount}</span
            >`;
        }
    }

    private getParallelism(row: RunViewModel): TemplateResult {
        return html`<span
            ${htmlTitle(
                html`<table>
                    <tr>
                        <td>Parallel sets:</td>
                        <td>${row.parallelism}</td>
                    </tr>
                    <tr>
                        <td>Concurrency:</td>
                        <td>${row.parallelMaxConcurrency ?? "Unlimited"}</td>
                    </tr>
                    <tr>
                        <td>Export:</td>
                        <td>${row.parallelExport ?? ParallelExport.Combined}</td>
                    </tr>
                </table>`,
                true
            )}
            style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
            >${row.parallelism}</span
        >`;
    }

    private formatTotalTime(row) {
        let seconds = 0;
        if (row.parallelism > 1 && row.parallelSet === 0 && row.startTime) {
            const endTime = row.endTime ? new Date(row.endTime) : new Date();
            const startTime = new Date(row.startTime);
            seconds = (endTime.getTime() - startTime.getTime()) / 1000;
        } else {
            seconds = row.runTimeSec ?? row.toalTime ?? 0;
        }

        if (!seconds) {
            return "n/a";
        }
        if (seconds < 60) {
            return Math.round(seconds) + " sec";
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = Math.round(seconds % 60);
            if (remainingSeconds === 0) {
                return minutes + " min";
            } else {
                return minutes + " min" + " " + remainingSeconds + " sec";
            }
        } else {
            const hours = Math.floor(seconds / 3600);
            const remainingMinutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = Math.round(seconds % 60);
            let result = "";
            if (hours > 1) {
                result += hours + " hours";
            } else {
                result += hours + " hour";
            }
            if (remainingMinutes > 0) {
                result += " " + remainingMinutes + " min";
            }
            if (remainingSeconds > 0) {
                result += " " + remainingSeconds + " sec";
            }
            return result;
        }
    }

    private async stopRunAsync() {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Stop Run",
            body: `Are you sure you want to stop this run?`,
            saveCaption: "Stop Run",
        });
        if (result.isSave) {
            const result = await this._runService.api.stopMany(this.configId, [this.runId]);
            if (result.isOk) {
                this._data.status = RunStatus.stopping;
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    //called when a user clicks the download button for a row
    private onDownloadFile(row: RunFileViewModel) {
        if (row === null) {
            return;
        }
        this._runService.api.downloadFile(row.id, false);
    }
    private onOpenFile(row: RunFileViewModel) {
        if (row === null) {
            return;
        }

        this._runService.api.downloadFile(row.id, true);
    }

    private async initializeAsync() {
        //Load the columns for the run details
        this._columns = [
            { field: "sequence", title: "Sequence", align: "center" },
            { field: "serverName", title: "Server" },
            {
                field: "parallelism",
                title: "Parallelism",
                align: "center",
                hidden: true,
                template: (row) =>
                    row.parallelSet
                        ? html`${row.parallelSet}/${row.parallelism}`
                        : (row.parallelism ?? 1) > 1
                          ? this.getParallelism(row)
                          : html``,
            },
            { field: "proxyPoolName", title: "Proxy Pool" },
            {
                field: "status",
                title: "Status",
                align: "center",
                template: (row) =>
                    html`${choose(
                        row.status,
                        [
                            [undefined, () => html``],
                            [null, () => html``],
                            [RunStatus.waiting, () => this.getStatus("teal", "Waiting", row)],
                            [RunStatus.queuing, () => this.getStatus("--color-gray-4", "Queuing", row)],
                            [RunStatus.starting, () => this.getStatus("--color-purple", "Starting", row)],
                            [RunStatus.success, () => this.getStatus("--color-status-blue", "Success", row)],
                            [RunStatus.stopping, () => this.getStatus("--color-purple", "Stopping", row)],
                            [RunStatus.stopped, () => this.getStatus("--color-gray-4", "Stopped", row)],
                            [RunStatus.failure, () => this.getStatus("--color-status-red", "Failure", row)],
                            [RunStatus.failed, () => this.getStatus("--color-status-red", "Failed", row)],
                            [RunStatus.completed, () => this.getStatus("--color-status-blue", "Completed", row)],
                            [RunStatus.running, () => this.getStatus("--color-purple", "Running", row)],
                            [RunStatus.exporting, () => this.getStatus("--color-purple", "Exporting", row)],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "startTime",
                title: "Started",
                align: "center",
                template: (row) => {
                    const date = new Date(row.startTime);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
            {
                field: "endTime",
                title: "Completed",
                align: "center",
                hidden: false,
                template: (row) => {
                    const date = row.endTime ? new Date(row.endTime) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },
            { field: "message", title: "Progress", hidden: true },
            { title: "Stats", align: "center", template: (row) => this.getStats(row) },
            {
                name: "stop",
                cellStyle: { textAlign: "center", width: "20px" },
                hidden: true,
                button: () => this.stopRunAsync(),
                icon: "fas fa-square",
                iconColor: "Crimson",
                htmlTitle: "Stop Run",
            },
        ];

        //load the columns for the files
        this._fileColumns = [
            {
                cellStyle: { textAlign: "center", width: "20px" },
                action: (row) => this.onDownloadFile(row),
                template: () => {
                    return html`<img
                        style="width: 20px"
                        title="Download"
                        src=${downloadIcon}
                    ></img>`;
                },
            },
            {
                title: "File",
                template: (row) => {
                    return html`<a href="javascript:;" @click=${() => this.onOpenFile(row)}>${row.name}</a>`;
                },
            },
            {
                title: "Size",
                align: "right",
                template: (row) => {
                    //transform file size to human readable format
                    return html`<a>${prettyBytes(row.fileSize)}</a>`;
                },
            },
            {
                field: "created",
                title: "Uploaded",
                align: "left",
                template: (row) => {
                    const date = new Date(row.created);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
        ];

        this._isLoading = true;
        try {
            const result = await this._runService.api.getAsync(this.runId);
            if (result.isOk) {
                this._data = result.value.run;
                this._files = result.value.files;
                this._columns.find((p) => p.field === "parallelism").hidden = !this._data?.parallelism || this._data.parallelism <= 1;
                this._columns.find((p) => p.field === "endTime").hidden = !this._data.endTime;
                this._columns.find((p) => p.field === "message").hidden = !isRunningOnServer(this._data.status);
                this._columns.find((p) => p.field === "proxyPoolName").hidden = !this._data.proxyPoolName;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }

        if (this._data && RunViewModel.canShowBrowserImages(this._data.status)) this.startBrowserAsync();
    }

    private async retryStartBrowserAsync() {
        const result = await this._runService.api.getAsync(this.runId);
        if (result.isOk) {
            this._data = result.value.run;
            this._files = result.value.files;
            if (this._data) this.startBrowserAsync();
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private isParallelMasterSet() {
        return this._data && this._data.parallelism > 1 && this._data.parallelSet === 0;
    }

    private startBrowserAsync() {
        if (isQueuingOrStarting(this._data.status)) {
            setTimeout(() => this.retryStartBrowserAsync(), 1000);
        } else if (isRunningOnServer(this._data.status) && !this.isParallelMasterSet()) {
            const wssProxy = this._data.proxy.toLowerCase().startsWith("https://")
                ? "wss://" + this._data.proxy.substring(8)
                : "ws://" + this._data.proxy.substring(7);
            this.startBrowser(`${wssProxy}/ws/server/${this._data.host}/api/ws/stats/run/${this._data.id}`);
            this._columns.find((p) => p.name === "stop").hidden = false;
        }
    }

    private close() {
        if (this._ws) {
            this._ws.close();
        }
    }

    private async writeFrameAsync(id: string) {
        this.requestUpdate();
        await this.updateComplete;
        const browser = this._browsers[id];
        const browserCount = Object.keys(this._browsers).slice(
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
        ).length;
        const iframe = this.shadowRoot.querySelector("#iframe" + id) as HTMLIFrameElement;
        if (iframe) {
            if (!iframe.contentWindow) {
                iframe.src = "javascript:void(0);";
                iframe.onload = function () {
                    iframe.contentWindow.document.open();
                    iframe.contentWindow.document.write(browser.html);
                    iframe.contentWindow.document.close();
                    iframe.contentWindow.document.documentElement.style.overflow = "hidden";
                    if (navigator.userAgent.search("Firefox") >= 0) {
                        iframe.contentWindow.document.body.style.width = iframe.contentWindow.innerWidth * browserCount + "px";
                        iframe.contentWindow.document.body.style.transformOrigin = "0 0";
                        iframe.contentWindow.document.body.style.transform = "scale(" + (1.0 / browserCount).toString() + ")";
                    } else {
                        iframe.contentWindow.document.documentElement.style["zoom"] = 100 / browserCount + "%";
                    }
                };
            } else {
                iframe.contentWindow.document.open();
                iframe.contentWindow.document.write(browser.html);
                iframe.contentWindow.document.close();
                iframe.contentWindow.document.documentElement.style.overflow = "hidden";
                if (navigator.userAgent.search("Firefox") >= 0) {
                    iframe.contentWindow.document.body.style.width = iframe.contentWindow.innerWidth * browserCount + "px";
                    iframe.contentWindow.document.body.style.transformOrigin = "0 0";
                    iframe.contentWindow.document.body.style.transform = "scale(" + (1.0 / browserCount).toString() + ")";
                } else {
                    iframe.contentWindow.document.documentElement.style["zoom"] = 100 / browserCount + "%";
                }
            }
        }
    }

    writePageOfFrames() {
        const browsers = Object.keys(this._browsers).slice(
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
        );
        for (const key of browsers) {
            this.writeFrameAsync(key);
        }
    }

    private addEmptyBrowser(id: string) {
        const browserPageCount = Object.keys(this._browsers).slice(
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
        ).length;
        this._browsers[id] = {};
        const newBrowserPageCount = Object.keys(this._browsers).slice(
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
        ).length;
        if (browserPageCount !== newBrowserPageCount) {
            this.calculateGridStyle();
            this.calculateFrameZoom();
        }
    }

    private async onBrowsersFinished() {
        this._isLoading = true;
        try {
            const result = await this._runService.api.getAsync(this.runId);
            if (result.isOk) {
                this._data = result.value.run;
                this._files = result.value.files;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }

    private async refreshBrowsersAsync() {
        const browsers = Object.keys(this._browsers).slice(
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
        );
        let changed = false;
        for (const key of browsers) {
            if (this._browsers[key].htmlChanged) {
                changed = true;
                this._browsers[key].htmlChanged = false;
                await this.writeFrameAsync(key);
            }
            if (this._browsers[key].dataChanged) {
                changed = true;
                this._browsers[key].dataChanged = false;
                this._browsers[this._browserId].imageUrl = URL.createObjectURL(this._browsers[this._browserId].data as Blob | MediaSource);
            }
            if (this._browsers[key].logChanged) {
                changed = true;
                this._browsers[key].logChanged = false;
            }
            if (this._browsers[key].progressChanged) {
                changed = true;
                this._browsers[key].progressChanged = false;
            }
        }
        if (changed) {
            this.requestUpdate();
            this.refreshTimer = setTimeout(() => this.refreshBrowsersAsync(), 100) as unknown as number;
        }
        this.refreshTimer = undefined;
    }
    private refreshBrowsersIfReady() {
        if (!this.refreshTimer) {
            this.refreshTimer = setTimeout(() => {
                this.refreshBrowsersAsync();
            }) as unknown as number;
        }
    }

    startBrowser(serverUrl: string) {
        try {
            if (this._ws) {
                this._ws.close();
            }

            this._ws = new WebSocket(serverUrl, ["jwt", this._authService.token]);
            this._ws.binaryType = "blob";
            this._ws.onopen = () => {
                this._isOpen = true;
            };
            this._ws.onerror = () => {
                this._isOpen = false;
                this._toasterService.showUnexpectedError("Unable to establish live connection to the agent server.");
            };
            this._ws.onclose = () => {
                this._isOpen = false;
            };
            this._ws.onmessage = (msg) => {
                if (this._isImage) {
                    this._isImage = false;
                    if (!this._browsers[this._browserId]) {
                        this.addEmptyBrowser(this._browserId.toString());
                    }
                    this._browsers[this._browserId].data = msg.data;
                    this._browsers[this._browserId].dataChanged = true;
                    this.refreshBrowsersIfReady();
                } else {
                    const message = JSON.parse(msg.data);
                    if (message.messageType === RunDetailType.Failure) {
                        this._ws.close();
                        this._ws = undefined;
                        this._toasterService.showUnexpectedError("Live connection to the agent server failed. " + message.message);
                    } else {
                        this.hidden = false;

                        if (message.messageType === RunDetailType.Image) {
                            this._browserId = message.browserId;
                            this._isImage = true;
                        } else if (message.messageType === RunDetailType.Html) {
                            if (!this._browsers[message.browserId]) {
                                this.addEmptyBrowser(message.browserId.toString());
                            }
                            this._browsers[message.browserId].html = message.html;
                            this._browsers[message.browserId].htmlChanged = true;
                            this.refreshBrowsersIfReady();
                        } else if (message.messageType === RunDetailType.Stats) {
                            if (!isRunningOnServer(message.statusAndStats.status)) {
                                this._data.endTime = new Date();
                                this._columns.find((p) => p.field === "endTime").hidden = false;
                                this._columns.find((p) => p.name === "stop").hidden = true;
                                this._columns.find((p) => p.field === "message").hidden = true;
                                this._data.status = message.statusAndStats.status;
                                this.onBrowsersFinished(); //switch to file now that we're done
                            } else if (this._data.status !== RunStatus.stopping) {
                                this._data.status = message.statusAndStats.status;
                            }
                            this._data.pageCount = message.statusAndStats.stats.pageCount;
                            this._data.dynamicPageCount = message.statusAndStats.stats.dynamicPageCount;
                            this._data.requestCount = message.statusAndStats.stats.requestCount;
                            this._data.actionCount = message.statusAndStats.stats.actionCount;
                            this._data.errorCount = message.statusAndStats.stats.errorCount;
                            this._data.dataCount = message.statusAndStats.stats.dataCount;
                            if (message.statusAndStats.stats.message) this._data.message = message.statusAndStats.stats.message;
                            this.requestUpdate();
                        } else if (message.messageType === RunDetailType.Progress) {
                            if (message.browserId) {
                                if (!this._browsers[message.browserId]) {
                                    this.addEmptyBrowser(message.browserId.toString());
                                }
                                if (!this._browsers[message.browserId].progress) {
                                    this._browsers[message.browserId].progress = {};
                                }
                                this._browsers[message.browserId].progress.status = message.status;
                                if (message.message) this._browsers[message.browserId].progress.message = message.message;
                                if (message.url) this._browsers[message.browserId].progress.url = message.url;
                                this._browsers[message.browserId].progressChanged = true;
                                this.refreshBrowsersIfReady();
                            }
                        } else if (message.messageType === RunDetailType.Log) {
                            if (!this._browsers[message.browserId]) {
                                this.addEmptyBrowser(message.browserId.toString());
                            }
                            if (!this._browsers[message.browserId].log) {
                                this._browsers[message.browserId].log = [];
                                this._browsers[message.browserId].logCounter = 0;
                            }
                            this._browsers[message.browserId].logCounter++;
                            if (!this._browsers[message.browserId].pauseLog) {
                                this._browsers[message.browserId].log.unshift({
                                    level: message.logLevel,
                                    mes: this._browsers[message.browserId].logCounter.toString() + " " + message.log,
                                });
                                if (this._browsers[message.browserId].log.length > 100) {
                                    this._browsers[message.browserId].log.length = 100;
                                    this._browsers[message.browserId].log[99].mes = "Log limited to 100 entries.";
                                }
                                this.refreshBrowsersIfReady();
                            }
                        }
                    }
                }
            };
        } catch (err) {
            this._toasterService.showError(err.toString());
        }
    }

    private async calculateGridStyle() {
        const browsers = Object.keys(this._browsers).slice(
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
        );
        if (browsers.length > 0) {
            let x = Math.sqrt((this.clientWidth / this.clientHeight) * browsers.length);
            const y = browsers.length / x;
            if (x - Math.floor(x) > y - Math.floor(y)) {
                x = Math.ceil(x);
            } else {
                x = Math.ceil(browsers.length / Math.ceil(y));
            }
            this._gridStyle = { gridTemplateColumns: `repeat(${x}, minmax(0, 1fr))` };
        }

        this.requestUpdate();
    }

    private async calculateFrameZoom() {
        const browsers = Object.keys(this._browsers).slice(
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
            (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
        );
        let hasUpdated = false;
        for (const key of browsers) {
            const iframe = this.shadowRoot.querySelector("#iframe" + key.toString()) as HTMLIFrameElement;
            if (iframe && iframe.contentWindow) {
                if (navigator.userAgent.search("Firefox") >= 0) {
                    if (!hasUpdated) {
                        //We need to make sure iframe.contentWindow.innerWidth is correct.
                        hasUpdated = true;
                        this.requestUpdate();
                        await this.updateComplete;
                    }
                    const width = iframe.contentWindow.innerWidth;
                    iframe.contentWindow.document.body.style.width = width * browsers.length + "px";
                    iframe.contentWindow.document.body.style.transformOrigin = "0 0";
                    iframe.contentWindow.document.body.style.transform = "scale(" + (1.0 / browsers.length).toString() + ")";
                } else {
                    iframe.contentWindow.document.documentElement.style["zoom"] = 100 / browsers.length + "%";
                }
            }
        }
    }

    showLogOrImage(browser: { showLog?: boolean }) {
        browser.showLog = !browser.showLog;
        for (const key in this._browsers) {
            if (browser !== this._browsers[key]) this._browsers[key].showLog = false;
        }
        this.requestUpdate();
    }

    pauseLog(browser: { pauseLog?: boolean }) {
        browser.pauseLog = !browser.pauseLog;
        this.requestUpdate();
    }

    private async onPageChanged(evt: CustomEvent) {
        this.pageIndex = evt.detail.pageIndex;
        await this.calculateGridStyle();
        this.writePageOfFrames();
    }

    /**
     * Refreshes the run details data by fetching the latest information from the server.
     * This method updates both the run data and associated files.
     * 
     * The method:
     * 1. Sets a loading state while fetching data
     * 2. Retrieves the latest run information using the run service
     * 3. Updates the component's data and files if successful
     * 4. Shows an error message if the request fails
     * 5. Clears the loading state when complete
     * 
     * @returns {Promise<void>} A promise that resolves when the refresh operation is complete
     */
    private async refreshDataAsync() {
        this._isLoading = true;
        try {
            const result = await this._runService.api.getAsync(this.runId);
            if (result.isOk) {
                this._data = result.value.run;
                this._files = result.value.files;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }

    render() {
        const browsers = Object.entries(this._browsers);
        const showFiles = this._files && this._files.length > 0 && !(this._data && RunViewModel.canShowBrowserImages(this._data.status));
        const rows = this._data ? [this._data] : this._data;

        return html`
            <div class="body">
                <div class="header">
                    <se-secondary-button
                        @click=${() => this.refreshDataAsync()}
                        icon="far fa-redo"
                        title="Refresh data"
                    >Refresh</se-secondary-button>
                </div>
                <se-data-grid
                    class="grid"
                    style="flex-shrink: 0;"
                    .rows=${rows}
                    .columns=${this._columns}
                    placeholder="No run details available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>

                ${showFiles
                    ? html`
                          <se-data-grid
                              class="grid"
                              .rows=${this._files}
                              .columns=${this._fileColumns}
                              .isLoading=${this._isLoading}
                          ></se-data-grid>
                      `
                    : html`
                          <div style="flex:1; min-height:0;">
                              ${when(
                                  browsers.length > 0,
                                  () => html`
                                      <div class="browser-container" style=${styleMap(this._gridStyle)}>
                                          ${browsers
                                              .slice(
                                                  (this.pageIndex - 1) * this.BROWSERS_PER_PAGE,
                                                  (this.pageIndex - 1) * this.BROWSERS_PER_PAGE + this.BROWSERS_PER_PAGE
                                              )
                                              .map(
                                                  ([key, val]) => html`
                                                      <div class="card">
                                                          <div
                                                              style="border-bottom:lightgray 1px solid;background-color:white;padding:3px;display:flex;flex-direction:row;gap:5px;justify-content: center"
                                                          >
                                                              ${val.progress?.status === 1
                                                                  ? html`<fa-icon
                                                                        single-color="gray"
                                                                        fa-class="far fa-spinner fa-spin"
                                                                    ></fa-icon>`
                                                                  : val.progress?.status === 2
                                                                    ? html`<fa-icon
                                                                          single-color="gray"
                                                                          fa-class="far fa-cog fa-spin"
                                                                      ></fa-icon>`
                                                                    : html`<fa-icon single-color="Khaki" fa-class="fas fa-moon"></fa-icon>`}
                                                              <div style="overflow:hidden">${val.progress?.url}</div>
                                                              ${val.showLog
                                                                  ? html` <se-secondary-button
                                                                        style="margin-left: auto;"
                                                                        @mousedown=${() => this.pauseLog(val)}
                                                                        .customStyle=${{
                                                                            padding: "0px 3px",
                                                                            margin: 0,
                                                                            font: "var(--font-small)",
                                                                        }}
                                                                        icon=${val.pauseLog ? "far fa-play" : "far fa-pause"}
                                                                        ${htmlTitle(val.pauseLog ? "Continue log" : "Pause log")}
                                                                    ></se-secondary-button>`
                                                                  : html``}
                                                              <se-secondary-button
                                                                  style="${styleMap({
                                                                      marginLeft: val.showLog ? undefined : "auto",
                                                                  })} margin-left: auto;"
                                                                  @mousedown=${() => this.showLogOrImage(val)}
                                                                  .customStyle=${{
                                                                      padding: "0px 3px",
                                                                      margin: 0,
                                                                      font: "var(--font-small)",
                                                                  }}
                                                                  icon=${val.showLog ? "far fa-image" : "far fa-align-justify"}
                                                                  ${htmlTitle(val.showLog ? "Show browser" : "Show log")}
                                                              ></se-secondary-button>
                                                          </div>
                                                          ${!val.showLog
                                                              ? html` <div
                                                                    style="overflow:hidden;min-height:0;text-align:center;height:100%"
                                                                >
                                                                    ${when(
                                                                        val.imageUrl,
                                                                        () =>
                                                                            html`<img
                                                                                style="max-height:100%;max-width:100%"
                                                                                src=${val.imageUrl}
                                                                            />`,
                                                                        () =>
                                                                            html`<iframe
                                                                                id="iframe${key}"
                                                                                style="width:100%; height:100%; border:none;pointer-events:none;"
                                                                            >
                                                                            </iframe>`
                                                                    )}
                                                                </div>`
                                                              : html` <div
                                                                    style="${styleMap({
                                                                        display: val.showLog ? "block" : "none",
                                                                    })} overflow: auto;background-color:white;font: var(--font-small); padding:2px"
                                                                >
                                                                    ${val.log
                                                                        ? val.log.map(
                                                                              (log, i) =>
                                                                                  html`<div
                                                                                      class="l ${classMap({
                                                                                          o: log.level >= 3 && i % 2 === 0,
                                                                                          w: log.level === 2,
                                                                                          e: log.level < 2,
                                                                                      })}"
                                                                                  >
                                                                                      ${log.mes}
                                                                                  </div>`
                                                                          )
                                                                        : html``}
                                                                </div>`}
                                                      </div>
                                                  `
                                              )}
                                      </div>
                                  `
                              )}
                          </div>
                          ${browsers.length > this.BROWSERS_PER_PAGE
                              ? html`
                                    <se-pagination
                                        .recordCount=${browsers.length}
                                        .recordsPerPage=${this.BROWSERS_PER_PAGE}
                                        @pagechanged=${(evt: CustomEvent) => this.onPageChanged(evt)}
                                    ></se-pagination>
                                `
                              : html``}
                      `}
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: block;
            height: 100%;
            font: var(--font);
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
            flex-shrink: 0;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .card {
            font: var(--font-small);
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            min-width: 0;
            min-height: 0;
            border: 1px solid darkgray;
            box-shadow: 2px 2px 2px lightGray;
            background-color: white;
            border-radius: 5px 5px 5px 5px;
        }
        .browser-container {
            width: 100%;
            height: 100%;
            min-width: 0;
            min-height: 0;
            display: grid;
            grid-auto-rows: 1fr;
            gap: 10px;
        }

        .l {
            margin: 2px;
        }
        .o {
            background-color: whitesmoke;
        }
        .e {
            background-color: crimson;
            color: white;
        }
        .w {
            background-color: gold;
        }
    `;
}
