import { container, singleton } from "tsyringe";
import { OrganizationApi } from "./organization.api";

@singleton()
export class OrganizationService {
    //private _userState: UserState;
    private _organizationApi: OrganizationApi;

    get api() {
        return this._organizationApi;
    }

    constructor() {
        //this._userState = container.resolve(UserState);
        this._organizationApi = container.resolve(OrganizationApi);
    }

    /*async createSpaceAsync(name: string, isDefaultAccess: boolean) {
        const res = await this.createAsync(name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async updateSpaceAsync(id: number, name: string, isDefaultAccess: boolean) {
        const res = await this.updateAsync(id, name, isDefaultAccess);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }
    async removeSpaceAsync(id: number) {
        const res = await this.removeAsync(id);
        if (res.isOk) {
            await this._userState.refreshUserSpacesAsync();
        }
        return res;
    }*/
    async organizationExists(name: string, token: string) {
        return this, this._organizationApi.organizationExists(name, token);
    }
}
