import { css, html, LitElement, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { RunStatus } from "../../enums/run-status";

@customElement("se-status")
export class SePrimaryButton extends LitElement {
    @property({ attribute: "status-message" }) message: RunStatus;
    @property({ attribute: "status-color" }) color: string;
    @property({ type: Boolean }) dotted = false;

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    resolveCssVariable(variableName: string): string {
        const elementStyle = getComputedStyle(this);
        return elementStyle.getPropertyValue(variableName).trim();
    }

    // convert hex color to rgba and apply alpha for opacity
    hexToRgba(hex: string, alpha: number): string {
        let r, g, b;
        if (hex.startsWith('#')) {
            // Convert hex color to RGB
            hex = hex.slice(1);
            if (hex.length === 3) {
                r = parseInt(hex[0] + hex[0], 16);
                g = parseInt(hex[1] + hex[1], 16);
                b = parseInt(hex[2] + hex[2], 16);
            } else if (hex.length === 6) {
                r = parseInt(hex.slice(0, 2), 16);
                g = parseInt(hex.slice(2, 4), 16);
                b = parseInt(hex.slice(4, 6), 16);
            }
        } else if (hex.startsWith('rgb')) {
            // Extract RGB values from rgb(r, g, b) format
            const result = hex.match(/\d+/g);
            if (result) {
                r = parseInt(result[0]);
                g = parseInt(result[1]);
                b = parseInt(result[2]);
            }
        }

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    render() {
        const resolvedColor = this.resolveCssVariable(this.color);
        const backgroundColor = this.hexToRgba(resolvedColor, 0.25);
        
        return html`
            <div class="status-container" style="background-color: ${backgroundColor};">
                <span class="status-text ${this.dotted ? 'dotted' : ''}" style="color: var(${this.color});">${this.message}</span>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        .status-container {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 5px 2px;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.25);
        }
        .status-text {
            font-size: 0.8rem;
            font-weight: 500;
            text-decoration: none;
            text-wrap: nowrap;
        }
        .status-text.dotted {
            border-bottom: 1px dotted;
            cursor: pointer;
            user-select: none;
        }
    `;
}
