import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

@customElement('se-form-toggle-editor')
export class FormToggleEditorElement extends LitElement {
    @property({ type: Boolean }) 
    set value(val: boolean) {
        const oldVal = this._value;
        this._value = val;
        this.liveValue = val;
        this.requestUpdate('value', oldVal);
    }
    get value() {
        return this._value;
    }
    private _value: boolean;

    @property() label?: string;
    @property() labelHelp?: string;

    oldValue?: boolean;
    @state() liveValue?: boolean;
    @query('input') private _editorElement: HTMLInputElement;   

    connectedCallback() {
        super.connectedCallback();
        this.liveValue = this.value;
    }
    constructor() {
        super();
    }    
    firstUpdated() {
    }

    onMouseDown() {
        switch (this.liveValue) {
            case true:
                this.liveValue = false;
                break;
            case false:
                this.liveValue = true;
                break;
        }
        setTimeout(() => {
            this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this, value: this.liveValue, oldValue: this.value } }));
        }, 500);    }

    render() {
        return html`
            <div class="form-row">
                <label for="toggle" class="form-label">
                    <div><span>${this.label}</span></div>
                    <div class="help">${this.labelHelp}</div>
                </label>
                <div class="toggle-container" >
                    <div class="toggle-switch" @mousedown="${this.onMouseDown}">
                        ${this.liveValue ?
                            html`<input name="toggle" checked type="checkbox" class="toggle-input">` :
                            html`<input name="toggle" type="checkbox" class="toggle-input">`}
                        <label for="toggle" class="toggle-label"></label>
                    </div>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            width: 100%;
        }
        :host * {
            box-sizing: border-box;
        }

        .toggle-switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
        }

        .toggle-input {
            display: none;
        }

        .toggle-label {
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 34px;
            border-radius: 17px;
            background-color: #ccc;
            cursor: pointer;
            transition: background-color 0.4s ease;
        }

        .toggle-label:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            transition: transform 0.4s ease;
        }

        .toggle-input:checked + .toggle-label {
            background-color: var(--color-status-blue);
        }

        .toggle-input:checked + .toggle-label:before {
            transform: translateX(26px);
        }

        .toggle-container {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            min-height: 34px;
        }

        .label-text {
            font: var(--font-input-label);
            color: var(--color-text);
        }
        .form-row {
            padding: 10px;
            align-items: center;
            display: flex;
            transition: background 0.15s;
            border-bottom: 1px solid rgb(240, 236, 243);
        }
        .form-label {
            font-weight: 400;
            display: inline;
            margin-bottom: 0px;
            width: 50%;
            flex-shrink: 0;
        }

        .help {
            color: rgb(128, 112, 143);
            font-size: 12px;
            margin-top: 4px;
            line-height: 1.4;
        }
    `;
}