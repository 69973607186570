export enum ServerStatus {
    Disconnected = 0,
    Running = 1,
    Paused = 2,
    Unavailable = 3,
    Stopped = 4,
    Connecting = 5,
    Pending = 6,
    Stopping = 7,
}
