import intersect from "fast_array_intersect";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { container } from "tsyringe";
import { ConfigType } from "../../enums/config-type";
import { ValidationStatus } from "../../enums/validation-status";
import { idName, idNameSort } from "../../models/id-name";
import { AgentTemplateService } from "../../services/agent-template.service";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { MenuService } from "../../services/menu.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { MenuDialog } from "../components/menu-template";
import { BaseEditor } from "../editors/base-editor";
import "../editors/checkbox-list-editor.element";
import { CheckboxListEditorElement, checkboxOption } from "../editors/checkbox-list-editor.element";
import "../editors/input-editor.element";
import { SeLoadingPanel } from "../components/loading-panel.element";

@customElement("se-apply-templates")
export class ApplyTemplatesElement extends LitElement implements MenuDialog {
    private _userState: UserState;
    private _agentTemplateService: AgentTemplateService;
    private _configService: ConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _menuService: MenuService;

    @query("#checkboxList") private _checkboxList: CheckboxListEditorElement;
    @query("#editorForm") private _editorForm: HTMLFormElement;
    @query("#editorContainer") private _editorContainer: HTMLDivElement;
    @query("#loadingPanel") private _loadingPanelElement: SeLoadingPanel;

    @property() name: string;

    @state() private _agentTemplates: checkboxOption[] = [];
    @state() private _hasChanged = false;
    @state() private _createLabel: string;
    @state() private _isLoading = true;

    private _selectedRows: any[];
    private _callback?: () => void;

    constructor() {
        super();

        this._userState = container.resolve(UserState);
        this._agentTemplateService = container.resolve(AgentTemplateService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._configService = container.resolve(ConfigService);
        this._menuService = container.resolve(MenuService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async showMenu(input: { selectedRows?: any[]; configId?: number; callback?: () => void }) {
        this.showMenuAsync(input);
    }

    private async showMenuAsync(input: { selectedRows?: any[]; configId?: number; callback?: () => void }) {
        try {
            this._isLoading = true;

            this._callback = input.callback;
            if (input.configId) {
                const result = await this._configService.api.getConfigAgentTemplateIdsAsync(input.configId);
                if (result.isOk) {
                    this._selectedRows = [
                        {
                            id: input.configId,
                            configType: ConfigType.Agent,
                            agentTemplates: result.value.map((m) => ({
                                id: m,
                            })),
                        },
                    ];
                } else {
                    this._toasterService.showNetworkError(result.err);
                    return;
                }
            } else {
                this._selectedRows = input.selectedRows;
            }

            let agentTemplates: checkboxOption[] = [];
            if (this._selectedRows.length > 1) {
                const agentTemplatesArrays = this._selectedRows.map((p) => p.agentTemplates as idNameSort[] as idName[]);
                const common = intersect(agentTemplatesArrays, (x: any) => x.id);
                const all = agentTemplatesArrays.flat(1);
                const allTemplates = [...all, ...common.map((p) => ({ ...p, value: true }))] as checkboxOption[];
                agentTemplates = [...new Map(allTemplates.map((item) => [item["id"], item])).values()];
            } else if (this._selectedRows.length > 0) {
                agentTemplates = this._selectedRows[0].agentTemplates.map((p) => ({ ...p, value: true }));
            }

            const availableTemplates = await this._configService.api.getAvailableAgentTemplatesAsync(this._userState.selectedSpaceId);
            if (availableTemplates.isOk) {
                this._agentTemplates = availableTemplates.value.map((p) => {
                    const agentTemplate = agentTemplates.find((s) => s.id === p.id);
                    return { id: p.id, name: p.name, value: agentTemplate ? agentTemplate.value : false };
                });
            } else {
                this._toasterService.showNetworkError(availableTemplates.err);
            }
        } finally {
            this._isLoading = false;
        }
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private onValueChanged(event: CustomEvent) {
        this._hasChanged = event.detail.hasChanged;
    }

    private searchChanged(event: CustomEvent) {
        this._checkboxList.filter = event.detail.value;
        if (!this._agentTemplates.some((s) => s.name.toLowerCase() === event.detail.value.toLowerCase())) {
            this._createLabel = event.detail.value;
        } else {
            this._createLabel = undefined;
        }
    }

    private async onApply() {
        let hasChanged = false;
        const changedValues = this._checkboxList.liveValue.filter((p) => p.liveValue !== p.oldValue);
        for (const row of this._selectedRows) {
            if (row.configType !== ConfigType.Agent) continue;

            const templates = row.agentTemplates as idNameSort[];
            for (const value of changedValues) {
                if (row.id === value.id) continue;

                if (value.liveValue) {
                    const template = templates.find((s) => s.id === value.id);
                    if (!template) {
                        const result = await this._agentTemplateService.api.addConfigAgentTemplateAsync(row.id, value.id, 1);
                        if (result.isErr) {
                            this._toasterService.showNetworkError(result.err);
                        } else {
                            if (template) template.sort = 1;
                            else templates.push({ id: value.id, name: value.name, sort: 1 });
                            row.validationStatus = ValidationStatus.Unknown;
                            hasChanged = true;
                        }
                    }
                } else {
                    const template = templates.find((s) => s.id === value.id);
                    if (template) {
                        const result = await this._agentTemplateService.api.removeConfigAgentTemplateAsync(row.id, value.id);
                        if (result.isErr) {
                            this._toasterService.showNetworkError(result.err);
                        } else {
                            templates.splice(templates.indexOf(template), 1);
                            row.validationStatus = ValidationStatus.Unknown;
                            hasChanged = true;
                        }
                    }
                }
            }
        }
        if (this._callback) {
            if (hasChanged) this._callback();
        } else {
            this._userState.gridRowsChanged.triggerVoid();
        }
        this._menuService.closeMenu();
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <span>Apply templates:</span>
                </div>
                <se-loading-panel
                    id="loadingPanel"
                    .loadingStyle=${{ borderTop: "solid 1px lightgray", backgroundColor: "white", minHeight: "50px", minWidth: "125px" }}
                    .isLoading=${this._isLoading}
                >
                    ${this._agentTemplates.length > 0
                        ? html` <div class="search">
                                  <se-input-editor maxlength="20" class="input" @editorChanged=${this.searchChanged}></se-input-editor
                                  ><fa-icon class="icon" fa-class="far fa-search" style="margin-top:5px"></fa-icon>
                              </div>
                              <div class="body">
                                  <div class="scroll-container" id="editorContainer">
                                      <se-checkbox-list-editor
                                          id="checkboxList"
                                          @valueChanged=${this.onValueChanged}
                                          .value=${this._agentTemplates}
                                      ></se-checkbox-list-editor>
                                  </div>
                                  <div
                                      class="savePanel"
                                      style="${styleMap({ display: this._hasChanged || this._createLabel ? "flex" : "none" })}"
                                  >
                                      <se-primary-button
                                          .hidden=${!this._hasChanged}
                                          .action="${() => this.onApply()}"
                                          action-delay="500"
                                          text="Apply"
                                      ></se-primary-button>
                                  </div>
                              </div>`
                        : html`
                              <div style="padding:10px; background-color: white;margin-top:2px">
                                  <p>No agent templates available.</p>
                              </div>
                          `}
                </se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            font: var(--font);
            box-sizing: border-box;
            background-color: var(--color-light);
            box-shadow: 1px 2px 2px 1px Gray;
            border-radius: 2px 2px 2px 2px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
        }
        .body {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            width: 100%;
        }
        .header {
            background-color: var(--color-light);
            padding: 5px 5px 0px 5px;
        }
        .search {
            background-color: white;
            padding: 5px 5px 0px 5px;
            border-top: solid 1px lightgray;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .icon {
            position: absolute;
            right: 15px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            max-height: 250px;
            max-width: 400px;
        }
        .savePanel {
            border-top: solid 1px lightgray;
            display: flex;
            flex-direction: rows;
            justify-content: end;
            padding: 8px 5px 10px 5px;
        }        
    `;
}
