import { BaseApi } from "./base.api";
import { singleton } from "tsyringe";

@singleton()

export class IdentityApi extends BaseApi {
    async getOktaAuthUrl(emailAddress: string) {
        return this.postObjectReturnObjectAsync<string>(`/identity/okta/authurl`, emailAddress);
    }

    async getGoogleAuthUrl(loginHint: string) {
        return this.postObjectReturnObjectAsync<string>(`/identity/google/authurl`, loginHint);
    }

    async getMicrosoftAuthUrl(loginHint: string) {
        return this.postObjectReturnObjectAsync<string>(`/identity/microsoft/authurl`, loginHint);
    }

    async getOktaRegistrationUrl(email: string) {
        return this.postObjectReturnObjectAsync<string>(`/identity/okta/regurl`, email
        );
    }

    async getGoogleRegistrationUrl(email: string) {
        return this.postObjectReturnObjectAsync<string>(`/identity/google/regurl`, email);
    }

    async getMicrosoftRegistrationUrl(email: string) {
        return this.postObjectReturnObjectAsync<string>(`/identity/microsoft/regurl`, email);
    }
}


