import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement, unsafeCSS } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import instagram from "../../../assets/instagram.svg";
import linkedin from "../../../assets/linkedin.svg";
import logo from "../../../assets/logo-tagline.svg";
import twitter from "../../../assets/twitter.svg";
import { LoginToken } from "../models/login";
import { AppConfigService } from "../services/app-config.service";
import { AuthService } from "../services/auth.service";
import "./components/primary-button.element";
import { SePrimaryButton } from "./components/primary-button.element";
import "./components/secondary-button.element";
import "./editors/checkbox-editor.element";
import { CheckboxEditorElement } from "./editors/checkbox-editor.element";
import "./editors/input-editor.element";
import { InputEditorElement } from "./editors/input-editor.element";
import backgroundMaze from "../../../assets/backgroundMaze.png";
import changeuser from "../../../assets/change-user.svg";
import { UserService } from "../services/user.service";
import { IdentityService } from "../services/identity.service";
import { UserLoginType } from "../models/user-login-type-model";
import { AuthenticationService } from "../models/authentication-service-model";

@customElement("se-login")
export class SeLoginElement extends LitElement {
    private _appConfigService: AppConfigService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _userService: UserService;
    private _identityService: IdentityService;
    private _message: string;
    private _error: string;
    private _currentYear: number;
    private _token: string;

    @query("#email") private _emailEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#stayLoggedIn") private _stayLoggedInEditor: CheckboxEditorElement;
    @query("#signInWithGoogleDiv") private _signInWithGoogleDiv: HTMLDivElement;
    @query("#loginButton") private _loginButton: SePrimaryButton;
    @query("#continueButton") private _continueButton: SePrimaryButton;
    @state() private _loginType: UserLoginType = null;
    constructor() {
        super();

        this.submitLogin = this.submitLogin.bind(this);
        this.loginWithPassword = this.loginWithPassword.bind(this);

        this._appConfigService = container.resolve(AppConfigService);
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userService = container.resolve(UserService);
        this._identityService = container.resolve(IdentityService);
        //this._onLoad = this._onLoad.bind(this);
        //document.addEventListener("load", this._onLoad, true);
        this._currentYear = new Date().getFullYear();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (this._appConfigService.isNew) {
            return commands.redirect("/create-first-user");
        } else {
            const params = new URLSearchParams(location.search);
            this._message = params.get("message");
            this._error = params.get("error");
            return undefined;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("keydown", this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        document.removeEventListener("keydown", this.submitLogin);
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }

    updateChangedValues() { }
    updated() {
        if (this._loginType?.authenticationService == AuthenticationService.Local) {
            this._passwordEditor.focus();
        }
    }

    async firstUpdated() {
        if (this._message) {
            this._toasterService.showSuccess(this._message);
        }
        if (this._error) {
            this._toasterService.showError(this._error);
        }
        await this.updateComplete;
        this._onLoad();
    }

    private _onLoad() {
        if (this._appConfigService.isGoogleAuthenticationSupport) {
            //this.googleInit();
        }
        if (this._appConfigService.isMicrosoftAuthenticationSupport) {
            //this.microsoftInit();
        }
        if (this._appConfigService.isOktaAuthenticationSupport) {
            //this.oktaInit();
        }
    }

    submitLogin(event) {
        if (event.key === "Enter") {
            if (this._loginType == null) {
                this._continueButton.onClick();
            }
            else {
                this._loginButton.onClick();
            }
        }
    }
    
    private onEditorChanged(ev) {
 
    }

    reportValidity(): boolean {
        if (this._loginType == null) {
            return this._emailEditor.reportValidity();
        }
        else if (this._loginType?.authenticationService == AuthenticationService.Local) {
            return this._passwordEditor.reportValidity();
        }
        return true;
    }

    private async tryLoginWithIdentityServers() {
        this._toasterService.clear();
        if (this.reportValidity()) {
            await this._userService.getUserLoginType(this._emailEditor.liveValue)
                .then((res) => {
                    this._loginType = res;
                    this._authService
                })
                .catch((err) => this.error(err));
        }

        if (this._loginType?.authenticationService == AuthenticationService.Google) {
            this.loginWithGoogle();
        }
        if (this._loginType?.authenticationService == AuthenticationService.Microsoft) {
            this.loginWithMicrosoft();
        }
        if (this._loginType?.authenticationService == AuthenticationService.Okta) {
            this.loginWithOkta(this._loginType.ssoId);
        }
    }

    private async loginWithPassword() {
        this._toasterService.clear();
        if (this.reportValidity()) {
            //start loading animation
            this._loginButton.loading = true;
            await this._authService
                .loginAsync(this._emailEditor.liveValue, this._passwordEditor.liveValue, true)
                .then((res) => this.loginAsyncSuccess(res.value))
                .catch((err) => this.error(err))
                .finally(() => this._loginButton.loading = false);
        }
    }

    private async register(ev) {
        ev.preventDefault();
        Router.go("/register");
    }
    private async forgotPassword(ev) {
        ev.preventDefault();
        Router.go("/forgot-password");
    }

    private loginAsyncSuccess(res: LoginToken) {
        if (res.userAccess.isMfaRequired && !res.isTwoFactorVerified) {
            if (!res.userAccess.isMfaEnabled) {
                Router.go("/multi-factor-authentication-setup");
            } else {
                Router.go("/multi-factor-authentication?rememberme=true"); // + this._stayLoggedInEditor.liveValue);
            }
        } else {
            Router.go("/");
        }
    }

    private async loginWithGoogle() {
        let url = "";
        const res = await this._identityService.getGoogleAuthUrl(this._emailEditor.liveValue);
        if (res.isOk) {
            url = res.value;
        }
        window.open(url, "_parent");
    }

    private async loginWithMicrosoft() {
        let url = "";
        const res = await this._identityService.getMicrosoftAuthUrl(this._emailEditor.liveValue);
        if (res.isOk) {
            url = res.value;
        }
        window.open(url, "_parent");
    }

    private async loginWithOkta(ssoId: string) {
        let url = "";
        await this._identityService.getOktaAuthUrl(this._emailEditor.liveValue).then((res) => {
            if (res.isOk) {
                url = res.value;
                window.open(url, "_parent");
            }
        }).catch((err) => this.error(err));
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
    }
    
    private changeUser() {
        this._loginType = null;
    }

    render() {
        return html`
    <form id="editorForm" class="editor">
       <div class="scroll-container">
          <div class="content">
            <div style="text-align: center;margin-bottom: 5px;">
                <img src=${logo} class="header-logo" />
            </div>
            <div ?hidden=${this._loginType?.authenticationService == AuthenticationService.Local}> <!-- email screen -->
                <se-input-editor
                    enableAutocomplete
                    .tabindex=${"1"}
                    class="inputEditor"
                    id="email"
                    name="email"
                    size="normal"
                    type="email"
                    placeholder="example@company.com"
                    label="Email"
                    labelPosition="top"
                    input-type="email"
                    required
                ></se-input-editor>
                <se-primary-button
                    id="continueButton"
                    class="inputEditor continueButton"
                    .action="${() => this.tryLoginWithIdentityServers()}"
                    action-delay="500"
                    text="Sign In"
                    position="first"
                    size="normal"
                ></se-primary-button>
                <div class="loginLink">
                    <a href="" class="link" @click="${this.register}">Register</a>
                </div>
            </div> <!-- end of email screen -->
            <!-- password scree -->
            <div ?hidden=${this._loginType?.authenticationService != AuthenticationService.Local}> 
                <div style="display:flex; align-items: center;">
                    <img src=${changeuser} class="changeuser" @click=${this.changeUser}></img>   
                    <h4>${this._emailEditor?.liveValue}</h4>
                </div>
                <div ?hidden=${this._loginType?.authenticationService != AuthenticationService.Local}>
                    <se-input-editor
                        enableAutocomplete
                        .tabindex=${"2"}
                        class="inputEditor"
                        size="normal"
                        id="password"
                        name="password"
                        type="password"
                        label="Password"
                        labelPosition="top"
                        input-type="password"
                        required
                        autoFocus=${true}
                    ></se-input-editor>
                    <se-primary-button
                        id="loginButton"
                        class="inputEditor loginButton"
                        .action="${() => this.loginWithPassword()}"
                        action-delay="500"
                        text="Login"
                        position="first"
                        size="normal"
                    ></se-primary-button>
                    <div class="loginLink">
                        <a href="" class="link" @click="${this.forgotPassword}">Forgot password?</a>
                    </div>
                </div> <!-- end of manual login -->
            </div> <!-- end of password screen -->
        </div> <!-- end of container -->
       </div> <!-- end of scroll-container-->
    </form>
    <div class="footer">
        <div>
            © ${this._currentYear} Sequentum •
            <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> •
            <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
        </div>
        <div style="text-align: right; flex:1">
            <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
            <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
            <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
        </div>
    </div>
    `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        :host * {
            box-sizing: border-box;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h3 {
            font: var(--font-h3);
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .link {
            color: var(--color-purple-dark);
            text-decoration: none;
        }
        .loginLink {
            display: flex;
            justify-content: center;
            padding: 10px;
            font: var(--font-button);
        }
        .loginButton {
            margin-top: 10px;
        }
        .continueButton {
            margin-top: 10px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 30px;
        }
        .content {
            width: 300px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .button {
            width: 30px;
            padding: 10px 10px 10px 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
        .primary-button {
            display: flex;
            flex-direction: rows;
            justify-content: space-evenly;
            margin-top: 15px;
        }
        .linkLabel {
            display: flex;
            flex: 2;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }

        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }
        .sign-spacer {
            width: 10px;
        }
        .inputEditor {
            width: 100%;
            margin-bottom: 10px;
        }
        .password-container {
            position: relative;
        }
        .forgot {
            display: flex;
            top: 0;
            right: 0;
            margin-top: 10px;
        }
        .loginbuttons{
            margin-bottom:10px; 
            margin-left:7px; 
            display: flex; 
            border: 2px solid #1c74ec; 
            width: 175px; 
            height:32px; 
            border-radius: 5px; 
            background-color:#1c74ec; 
            align-items:center";   
        }
        .loginbuttons:hover{
            background-color: #5194ee;
            cursor: pointer
        }
        .changeuser:hover{
            background-color: #ADD8E6;
            cursor: pointer;
            border-radius: 50%;
        }
    `;
}
