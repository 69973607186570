import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement, unsafeCSS } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import backgroundMaze from "../../../../assets/backgroundMaze.png";
import instagram from "../../../../assets/instagram.svg";
import linkedin from "../../../../assets/linkedin.svg";
import logo from "../../../../assets/logo-tagline.svg";
import twitter from "../../../../assets/twitter.svg";
import { AppConfigService } from "../../services/app-config.service";
import "../components/primary-button.element";
import "../components/secondary-button.element";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "../editors/input-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { SePrimaryButton } from "../components/primary-button.element";
import { AuthService } from "../../services/auth.service";
import okta from "../../../../assets/okta.jpg";
import mslogo from "../../../../assets/ms-logo.png";
import googlelogo from "../../../../assets/google_logo.png";
import { UserService } from "../../services/user.service";
import { IdentityService } from "../../services/identity.service";
import { OrganizationService } from "../../services/organization.service";

@customElement("se-user-registration")
export class UserRegistrationElements extends LitElement {
    private _appConfigService: AppConfigService;
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _userService: UserService;
    private _identityService: IdentityService;
    private _organizationService: OrganizationService;

    private _token: string = undefined;
    private _email: string = undefined;
    private _serviceId: number = undefined;
    private _name: string = undefined;

    @query("#editorForm") private _editorForm: HTMLFormElement;
    @query("#cancelButton") private _cancelButton: HTMLButtonElement;
    @query("#joinButton") private _joinButton: SePrimaryButton;
    @query("#finishButton") private _finishButton: SePrimaryButton;
    @query("#name") private _nameEditor: InputEditorElement;
    @query("#companyName") private _companyNameEditor: InputEditorElement;
    @query("#email") private _emailEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#confirmPassword") private _comparePasswordEditor: InputEditorElement;

    private _eulaAccepted = false;

    @state() private _submitted = false;
    @state() private _hasChanged = false;
    @state() private _finish = false;
    constructor() {
        super();
        this.submitLogin = this.submitLogin.bind(this);
        this.onContinueWithManualRegistration = this.onContinueWithManualRegistration.bind(this);

        this._appConfigService = container.resolve(AppConfigService);
        this._toasterService = container.resolve(ToasterService);
        this._authService = container.resolve(AuthService);
        this._userService = container.resolve(UserService);
        this._identityService = container.resolve(IdentityService);
        this._organizationService = container.resolve(OrganizationService);
    }

    async onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ) {
        const searchParams = new URLSearchParams(location.search);
        const message = searchParams.get("Error");
        if (message != null) {
            this._toasterService.showError(message);
            return;
        }
        const token = searchParams.get("Token");
        if (token != null) {
            this._token = token;
        }
        const suggestedName = searchParams.get("Name");
        if (suggestedName != null) {
            this._name = suggestedName;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("keydown", this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }

    disconnectedCallback() {
        document.removeEventListener("keydown", this.submitLogin);
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }

    submitLogin(event) {
        if (event.key === "Enter" && this._token == undefined && this._joinButton.loading === false) {
            this.onContinueWithManualRegistration(event);
        } else if (event.key === "Enter" && this._token !== undefined && !this._finish) {
            this.onFinish(event);
        }
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {}

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {}

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    /**
     * The eula checkbox was clicked
     * @param evt
     */
    private onEulaChanged(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._eulaAccepted = true;
            } else {
                this._eulaAccepted = false;
            }
        }
    }

    private success() {
        sessionStorage.setItem('pendingVerificationEmail', this._emailEditor.liveValue);
        this._toasterService.showSuccess(
            "Thank you for registering. Please check your email to verify your account."
        );
        Router.go("/pending-verification");
    }

    private async login(ev) {
        ev.preventDefault();
        Router.go("/login");
    }

    private async showEULA(ev) {
        ev.preventDefault();
        this.openEULADialog();
        //Router.go("/login");
    }

    private openEULADialog() {
        fetch("./eula.txt")
            .then((response) => response.text())
            .then((eulaContent) => {
                // Open a new window
                const eulaWin = window.open("", "EULA");

                // Write the content of the EULA into the new window
                eulaWin.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Sequentum EULA</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                margin: 20px;
                                overflow-y: scroll;
                                max-height: 90vh;
                            }
                        </style>
                    </head>
                    <body>
                        <pre>${eulaContent}</pre>
                    </body>
                    </html>
                `);

                // Close the document after writing
                eulaWin.document.close();
            })
            .catch((error) => console.error("Error fetching EULA:", error));
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        // validate password strength
        const passwordValidation = this._appConfigService.validatePassword(this._passwordEditor.liveValue);
        if (!passwordValidation.isValid) {
            this._passwordEditor.setCustomValidity(passwordValidation.message);
            this._passwordEditor.reportValidity();
            return;
        } else {
            this._passwordEditor.setCustomValidity("");
            this._passwordEditor.reportValidity();
        }

        // check if passwords match
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        } else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    private async continueWithGoogle(ev) {
        ev.preventDefault();
        await this._identityService.getGoogleRegistrationUrl(this._emailEditor.liveValue)
            .then((url) => {
                window.open(url, "_parent");
            })
            .catch((err) => { this.error(err) })
            ;
    }

    private async continueWithMicrosoft(ev) {
        ev.preventDefault();
        await this._identityService.getMicrosoftRegistrationUrl(this._emailEditor.liveValue)
            .then((url) => {
                window.open(url, "_parent");
            })
            .catch((err) => { this.error(err) })
            ;
    }

    private async continueWithOkta(ev) {
        ev.preventDefault();
        window.open('https://sequentum.atlassian.net/servicedesk/customer/portal/7/group/10/create/47', '_blank');
    }

    private async onContinueWithManualRegistration(ev) {
        ev.preventDefault();

        if (this._emailEditor.liveValue == "" || this._emailEditor.liveValue == undefined) {
            this._toasterService.showError("Please enter email address.");
            return;
        }

        if (this.reportValidity()) {
            this._joinButton.loading = true;
            this._hasChanged = false;
            await this._appConfigService
                .registerUserAsync(
                    this._emailEditor.liveValue,
                    this._emailEditor.liveValue + "'s Organization",
                    this._emailEditor.liveValue,
                    ""
                )
                .then(() => this.success())
                .catch((err) => {
                    this.error(err);
                    this._joinButton.loading = false;
                })
        }
    }

    private goToLogin(ev: Event) {
        ev.preventDefault();
        Router.go('/login');
    }

    getRegistrationPage1() {
        if (this._token === undefined) {
            return html`
            <div class="h2">Join Sequentum Cloud</div>
            <se-input-editor
                .tabindex=${"1"}
                size="normal"
                class="inputEditor"
                id="email"
                name="email"
                type="text"
                label="Email"
                placeholder="example@company.com"
                labelPosition="top"
                input-type="email"
                required
            ></se-input-editor>
            <div class="loginButton inputEditor">
                <se-primary-button
                    size="normal"
                    position="first"
                    id="joinButton"
                    class="joinButton"
                    @click="${this.onContinueWithManualRegistration}"
                    ?disabled="${!this._hasChanged}"
                    text="Join"
                ></se-primary-button>
            </div>

            <h5><span>OR</span></h5>
            <div style="display:flex; justify-content:center">
                ${this._appConfigService.isGoogleAuthenticationSupport ?
                html`<div class="loginbuttons" @click=${this.continueWithGoogle}>
                    <div style="background-color:white; border-top-left-radius: 3px; border-bottom-left-radius: 3px">
                        <img src=${googlelogo} style="padding: 2px;height: 32px; width:32px;"></img>
                    </div>
                    <div style="width:100%; display:flex;align-items:center;justify-content:center;">
                        <label style="color:white; font-size:16px; cursor: pointer;">Sign Up with Google</label>
                    </div>
                </div>` : ``}
            </div>


            <div style="display:flex; justify-content:center">
                ${this._appConfigService.isMicrosoftAuthenticationSupport ?
                html`<div class="loginbuttons" @click=${this.continueWithMicrosoft}>
                    <div style="background-color:white; border-top-left-radius: 3px; border-bottom-left-radius: 3px">
                        <img src=${mslogo} style="padding: 2px;height: 32px; width:32px;"></img>
                    </div>
                    <div style="width:100%; display:flex;align-items:center;justify-content:center;">
                        <label style="color:white; font-size:16px; cursor: pointer;">Sign Up with Microsoft</label>
                    </div>
                </div>` : ``}
            </div>

            <div style="display:flex; justify-content:center">
                ${this._appConfigService.isOktaAuthenticationSupport ?
                html`<div class="loginbuttons" @click=${this.continueWithOkta}>
                    <div style="background-color:white; border-top-left-radius: 3px; border-bottom-left-radius: 3px">
                        <img src=${okta} style="padding: 2px;height: 32px; width:32px;"></img>
                    </div>
                    <div style="width:100%; display:flex;align-items:center;justify-content:center;">
                        <label style="color:white; font-size:16px; cursor: pointer;">Sign Up with Okta</label>
                    </div>
                </div>` : ``}
            </div>
            <div class="loginLink">
                <a href="Forgot Password" class="link" @click="${this.login}">Go to Login</a>
            </div>`;
        }
        else if (!this._finish) {
            return html`
                <div class="h2">Complete the registration</div>
                <se-input-editor
                    size="normal"
                    enableAutocomplete
                    class="inputEditor gap"
                    id="name"
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="John Doe"
                    labelPosition="top"
                    required
                    size="30"
                    .value=${this._name}
                ></se-input-editor>

                <se-input-editor
                    size="normal"
                    class="inputEditor"
                    id="companyName"
                    name="companyName"
                    type="text"
                    label="Organization"
                    placeholder="Company Name"
                    labelPosition="top"
                    required
                    size="30"
                ></se-input-editor>

                <div class="inputEditor" style="flex:1">
                    <div class="linkLabel">
                        <se-checkbox-editor
                            id="eula"
                            name="eula"
                            .value=${this._eulaAccepted}
                            @valueChanged=${this.onEulaChanged}
                            tristate="auto"
                        ></se-checkbox-editor>

                        <a href="EULA" class="link" @click="${this.showEULA}" style="margin-left: 10px">I agree to EULA </a>
                    </div>
                </div>

                <div class="loginButton inputEditor">
                    <se-primary-button
                        size="normal"
                        position="first"
                        id="finishButton"
                        class="saveButton"
                        @click="${this.onFinish}"
                        ?disabled="${!this._hasChanged}"
                        text="Finish"
                    ></se-primary-button>
                </div>
            `;
        }
        else {
            return html`
            <div class="h2">
                Congratulations!
            </div>
            <div class="message">
                You are successfully registered with Sequentum Cloud.
            </div>
            <se-primary-button
                size="normal"
                position="first"
                @click="${this.goToLogin}"
                text="Go to Login"
                class="inputEditor loginButton"
            ></se-primary-button>
            `;
        }
    }

    private async onFinish(ev) {
        ev.preventDefault();
        this._toasterService.clear();

        //make sure that EULA has been accepted
        if (this._eulaAccepted !== true) {
            this._toasterService.showError("Please accept the EULA");
            return;
        }

        if (this.reportValidity()) {
            this._finishButton.loading = true;
            await this._userService.api.verifyEmailAsync(this._token,
                this._nameEditor.liveValue,
                this._companyNameEditor.liveValue,
                null)
                .then(() => this._finish = true)
                .catch((err) => {
                    this.error(err);
                    this._finishButton.loading = false;
                })
        }
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="editorForm" class="editor" @keydown="${this.submitLogin}">
                <div class="scroll-container">
                    <div class="content">
                            <div style="text-align: center;margin-bottom: 20px;margin-top: 20px;">
                                <img src=${logo} class="header-logo" />
                            </div>
                            ${ this._submitted ? html`<div class="h2">Thank you for joining Sequentum Cloud. You should receive a confirmation email, and a representative will contact you shortly with more details.</div>` : this.getRegistrationPage1()} 
                    </div> <!-- end of content -->
                </div> <!-- end of scroll-container -->
            </form>
            <div class="footer">
                <div>
                    © 2024 Sequentum • <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> •
                    <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
                </div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h2 {
            font: var(--font-h2);
            color: var(--color-primary);
            font-weight: 600;
            padding: 0px 0px 10px;
        }
        .h3 {
            font: var(--font-h3);
            color: var(--color-primary);
            font-weight: 600;
        }
        .header-logo {
            width: 165px;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            padding-top: 0px;
            border-radius: 5px;
            height: auto;
        }
        .scroll-container {
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 30px 50px;
            height: auto;
            display: flex;
            flex-direction: column;
        }
        .link {
            color: var(--color-purple-dark);
            text-decoration: none;
        }
        .loginLink {
            display: flex;
            justify-content: center;
            padding: 10px;
            font: var(--font-button);
        }
        .loginButton {
            margin-top: 10px;
        }
        .content {
            width: 300px;
            //gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
            height: auto;
            flex: 1;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 10px;
        }
        .linkLabel {
            display: flex;
            flex: 2;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }
        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }

        h5 {
            width: 100%; 
            text-align: center; 
            border-bottom: 1px solid #000; 
            line-height: 0.1em;
            margin: 20px 0 20px;
        } 

        h5 span { 
            background:#fff; 
            padding:0 10px; 
        }

        .loginbuttons{
            margin-bottom:10px; 
            //margin-left:7px; 
            display: flex; 
            border: 2px solid #1c74ec; 
            width: 100%; 
            height:36px; 
            border-radius: 5px; 
            background-color:#1c74ec; 
            align-items:center";   
        }
        .loginbuttons:hover{
            background-color: #5194ee;
            cursor: pointer
        }
        .joinButton {
            margin-top: 10px;
        }
        .gap{
            margin-bottom: 10px;
        }
    `;
}
