import { html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { container } from "tsyringe";
import { Router, RouterLocation } from "@vaadin/router";
import { AuthService } from "../services/auth.service";

@customElement("se-login-redirect")
export class SeLoginRedirectElement extends LitElement {

    private _authService: AuthService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    async onBeforeEnter(location: RouterLocation) {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get("Token");
        if (token != null) {
            await this._authService.loginWithToken(token)
            .then(() => {
                return Router.go("/");
            })
            .catch(() => {
                return Router.go("/login");
            });
        }
    }

    render() {
        
        return html`
        <div style="top: 50%, left: 50%">
            <h3 style="color: white">Please wait. Redirecting to the login page...<h3>
        </div>
        `;
    }
}
