import { container, singleton } from "tsyringe";
import { IdentityApi } from "./identity.api";

@singleton()
export class IdentityService {
    private _identityApi: IdentityApi; 
    constructor() {
        this._identityApi = container.resolve(IdentityApi);
    }

    async getOktaAuthUrl(emailAddress: string) {
        const res = await this._identityApi.getOktaAuthUrl(emailAddress);
        if (res.isOk) {
            return res;
        } else {
            throw res.err;
        }
    }

    async getGoogleAuthUrl(loginHint: string) {
        const res = await this._identityApi.getGoogleAuthUrl(loginHint);
        if (res.isOk) {
            return res;
        } else {
            throw res.err;
        }
    }

    async getMicrosoftAuthUrl(loginHint: string) {
        const res = await this._identityApi.getMicrosoftAuthUrl(loginHint);
        if (res.isOk) {
            return res;
        } else {
            throw res.err;
        }
    }

    async getOktaRegistrationUrl(loginHint: string) {
        const res = await this._identityApi.getOktaRegistrationUrl(loginHint);
        if (res.isOk) {
            return res.value;
        } else {
            throw res.err;
        }
    }

    async getGoogleRegistrationUrl(loginHint: string) {
        const res = await this._identityApi.getGoogleRegistrationUrl(loginHint);
        if (res.isOk) {
            return res.value;
        } else {
            throw res.err;
        }
    }

    async getMicrosoftRegistrationUrl(loginHint: string) {
        const res = await this._identityApi.getMicrosoftRegistrationUrl(loginHint);
        if (res.isOk) {
            return res.value;
        } else {
            throw res.err;
        }
    }
}