import { singleton } from 'tsyringe';

declare global {
    interface Window {
        posthog: {
            identify: (id: string, properties?: Record<string, any>) => void;
            reset: () => void;
        };
    }
}

@singleton()
export class PostHogService {
    identify(user: {
        email?: string;
        name?: string;
        organizationName?: string;
        id?: string;
    }): void {
        if (typeof window.posthog !== 'undefined') {
            window.posthog.identify(user.email, {
                email: user.email,
                name: user.name,
                organization: user.organizationName,
                userId: user.id
            });
        }
    }

    reset(): void {
        if (typeof window.posthog !== 'undefined') {
            window.posthog.reset();
        }
    }
} 