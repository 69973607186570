import { PreventAndRedirectCommands, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { OrganizationViewModel } from "../../models/organization-view-model";
import { AuthService } from "../../services/auth.service";
import { OrganizationService } from "../../services/organization.service";
import { BaseEditor } from "../editors/base-editor";
import "../components/cards/edit-form-card.element";
import "../components/cards/card-buttons.element";
import { FormInputEditorElement } from "../editors/form/form-input-editor.element";
import { FormToggleEditorElement } from "../editors/form/form-toggle-editor.element";
import { FormNumberEditorElement } from "../editors/form/form-number-editor.element";
import { Router } from "@vaadin/router";

@customElement("se-organization-editor")
export class SeOrganizationEditorElement extends LitElement {
    private _authService: AuthService;
    private _organizationService: OrganizationService;
    private _toasterService: ToasterService;
    private _isNew = false;

    @property() organization?: OrganizationViewModel;

    @state() private _organization?: OrganizationViewModel;
    @state() private _isLoading = true;

    @query("#name") private _nameEditor: FormInputEditorElement;
    @query("#isBillable") private _billableEditor: FormToggleEditorElement;
    @query("#isMfaRequired") private _isMfaRequiredEditor: FormToggleEditorElement;
    @query("#isProxyRequired") private _isProxyRequiredEditor: FormToggleEditorElement;
    @query("#isAllowDevTools") private _isAllowDevToolsEditor: FormToggleEditorElement;
    @query("#isAuditLog") private _isAuditLogEditor: FormToggleEditorElement;
    @query("#maxParallelism") private _maxParallelism: FormNumberEditorElement;
    @query("#maxEditorServers") private _maxEditorServers: FormNumberEditorElement;
    @query("#maxRunServers") private _maxRunServers: FormNumberEditorElement;
    @query("#maxPendingRunServers") private _maxPendingRunServers: FormNumberEditorElement;
    @query("#minQueueSize") private _minQueueSize: FormNumberEditorElement;
    @query("#ssoId") private _ssoIdEditor: FormInputEditorElement;

    /**
     * Validates an organization name according to the following rules:
     * 1. Name cannot be null or empty
     * 2. Name must be at least 2 characters long
     * 3. Name cannot have leading or trailing spaces
     * 4. Name can only contain letters, numbers, and the following special characters:
     *    - Ampersand (&)
     *    - Period (.)
     *    - Hyphen (-)
     *    - Underscore (_)
     *    - Apostrophe (')
     *    - Forward Slash (/)
     *    - Parentheses (())
     *    - Comma (,)
     *    - Plus Sign (+)
     *    - At Sign (@)
     *    - Dollar Sign ($)
     *    - Space ( )
     * @param name - The organization name to validate
     * @returns Empty string if valid, error message if invalid
     */
    private validateOrganizationName(name: string): string {
        if (!name) {
            return "Organization name is required";
        }
        if (name.length < 2) {
            return "Organization name must be at least 2 characters long";
        }
        if (name.startsWith(" ") || name.endsWith(" ")) {
            return "Organization name cannot have leading or trailing spaces";
        }

        // Define allowed special characters
        const allowedSpecialChars = [" ", "-", "&", ".", "_", "'", "/", "(", ")", ",", "+", "@", "$"];

        // Check each character in the name
        for (let i = 0; i < name.length; i++) {
            const c = name[i];
            const isLetter = /[a-zA-Z]/.test(c);
            const isDigit = /[0-9]/.test(c);
            const isAllowedSpecial = allowedSpecialChars.includes(c);

            if (!isLetter && !isDigit && !isAllowedSpecial) {
                return "Organization name cannot contain special characters other than &, ., -, _, ', /, (), ,, +, @, $, and spaces";
            }
        }

        return "";
    }

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._organizationService = container.resolve(OrganizationService);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }

        const orgId = location.params.orgId ? Number(location.params.orgId) : undefined;

        if (orgId) {
            this._isNew = false;
            this._organization = new OrganizationViewModel();
            this._organization.id = orgId;

            // Update the public property
            this.organization = new OrganizationViewModel();
            this.organization.id = orgId;
        } else {
            this._isNew = true;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async loadData() {
        if (this._isNew) {
            // Create new organization with default values
            this._organization = new OrganizationViewModel();
            this._organization.isAllowDevTools = false;
            this._organization.isAuditLog = true;
            this._organization.isBillable = true;
            this._organization.isProxyRequired = true;
            this._organization.isMfaRequired = false;
            this._organization.maxParallelism = 10;

            this.organization = { ...this._organization };
        } else if (this._organization?.id) {
            // Load existing organization
            const res = await this._organizationService.api.getAsync(this._organization.id);
            if (res.isOk) {
                this._organization = { ...res.value };

                this.organization = { ...this._organization };
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        } else if (this.organization) {
            // Load from property if available
            this._organization = { ...this.organization };
        }

        this._isLoading = false;
    }


    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (!this.reportValidity()) {
            return;
        }

        const validationMessage = this.validateOrganizationName(this._nameEditor.liveValue);
        if (validationMessage) {
            this._nameEditor.setCustomValidity(validationMessage);
            this._nameEditor.reportValidity();
            return;
        }

        const res = this._isNew
            ? await this._organizationService.api.createAsync(
                  this._nameEditor.liveValue,
                  this._billableEditor.liveValue,
                  this._isMfaRequiredEditor.liveValue,
                  this._isAuditLogEditor.liveValue,
                  this._isProxyRequiredEditor.liveValue,
                  this._isAllowDevToolsEditor.liveValue,
                  this._maxParallelism.liveValue,
                  this._maxRunServers.liveValue,
                  this._maxEditorServers.liveValue,
                  this._maxPendingRunServers.liveValue,
                  this._minQueueSize.liveValue,
                  this._ssoIdEditor.liveValue
              )
            : await this._organizationService.api.updateAsync(
                  this.organization.id,
                  this._nameEditor.liveValue,
                  this._billableEditor.liveValue,
                  this._isMfaRequiredEditor.liveValue,
                  this._isAuditLogEditor.liveValue,
                  this._isProxyRequiredEditor.liveValue,
                  this._isAllowDevToolsEditor.liveValue,
                  this._maxParallelism.liveValue,
                  this._maxRunServers.liveValue,
                  this._maxEditorServers.liveValue,
                  this._maxPendingRunServers.liveValue,
                  this._minQueueSize.liveValue,
                  this._ssoIdEditor.liveValue
              );
        if (res.isOk) {
            this._toasterService.showSuccess("Organization saved successfully.");
            this.close(true);
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    close(isSave: boolean) {
        Router.go("/manage/admin/organizations");
        this.dispatchEvent(new CustomEvent("close", { bubbles: true, composed: true, detail: { isSave: isSave } }));
    }

    render() {
        return html`
            <se-loading-panel
                id="loadingPanel"
                .loadingStyle=${{
                    boxShadow: "2px 2px 2px lightGray",
                    border: "1px solid gray",
                    borderRadius: "5px 5px",
                    backgroundColor: "white",
                    minHeight: "50px",
                    minWidth: "125px",
                }}
                .isLoading=${this._isLoading}
            >
                <se-edit-form-card cardTitle="${this._isNew ? "Create" : "Edit"} Organization">
                    <se-form-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelHelp="The display name for this organization"
                        labelPosition="left"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._organization?.name}
                    ></se-form-input-editor>

                    ${this._authService.isSE4Admin
                        ? html`
                              <se-form-toggle-editor
                                  id="isAuditLog"
                                  .value=${this._organization?.isAuditLog || false}
                                  name="isAuditLog"
                                  label="Audit log enabled"
                                  labelHelp="Track and log all user actions within the organization"
                                  labelPosition="left"
                              ></se-form-toggle-editor>

                              <se-form-toggle-editor
                                  id="isProxyRequired"
                                  .value=${this._organization?.isProxyRequired || false}
                                  name="isProxyRequired"
                                  label="Proxy required"
                                  labelHelp="Require all connections to go through a proxy server"
                                  labelPosition="left"
                              ></se-form-toggle-editor>

                              <se-form-toggle-editor
                                  id="isAllowDevTools"
                                  .value=${this._organization?.isAllowDevTools || false}
                                  name="isAllowDevTools"
                                  label="Allow DevTools"
                                  labelHelp="Enable access to browser developer tools"
                                  labelPosition="left"
                              ></se-form-toggle-editor>

                              <se-form-toggle-editor
                                  id="isBillable"
                                  .value=${this._organization?.isBillable || false}
                                  name="isBillable"
                                  label="Billable"
                                  labelHelp="Enable billing for this organization"
                                  labelPosition="left"
                              ></se-form-toggle-editor>

                              <se-form-number-editor
                                  id="maxParallelism"
                                  label="Max parallelism"
                                  labelHelp="Maximum number of parallel sessions allowed per run"
                                  labelPosition="left"
                                  min="1"
                                  input-width="75px"
                                  .value=${this._organization?.maxParallelism}
                              ></se-form-number-editor>
                              <se-form-number-editor
                                  id="maxEditorServers"
                                  label="Max editor servers"
                                  labelHelp="Overwrites the default maximum number of editor servers allowed per organization"
                                  labelPosition="top"
                                  min="1"
                                  input-width="75px"
                                  .value=${this._organization?.maxEditorServers}
                              >
                              </se-form-number-editor>
                              <se-form-number-editor
                                  id="maxRunServers"
                                  label="Max run servers"
                                  labelHelp="Overwrites the default maximum number of run servers allowed per organization"
                                  labelPosition="top"
                                  min="1"
                                  input-width="75px"
                                  .value=${this._organization?.maxRunServers}
                              >
                              </se-form-number-editor>
                              <se-form-number-editor
                                  id="maxPendingRunServers"
                                  label="Max pending run servers"
                                  labelHelp="Overwrites the default maximum number of pending run servers allowed per organization"
                                  labelPosition="top"
                                  min="1"
                                  input-width="75px"
                                  .value=${this._organization?.maxPendingRunServers}
                              >
                              </se-form-number-editor>
                               <se-form-number-editor
                                  id="minQueueSize"
                                  label="Min queue size"
                                  labelHelp="Overwrites the default min queue size required before an organization can start new run servers"
                                  labelPosition="top"
                                  min="1"
                                  input-width="75px"
                                  .value=${this._organization?.minQueueSize}
                              >
                              </se-form-number-editor>

                              <se-form-input-editor
                                  id="ssoId"
                                  name="ssoId"
                                  type="text"
                                  label="SSO ID"
                                  labelHelp="Only required for Okta SSO"
                                  labelPosition="left"
                                  input-type="text"
                                  size="30"
                                  .value=${this._organization?.ssoId}
                              ></se-form-input-editor>

                              <se-form-toggle-editor
                                  id="isMfaRequired"
                                  .value=${this._organization?.isMfaRequired || false}
                                  name="isMfaRequired"
                                  label="Multi-factor authentication required"
                                  labelHelp="When enabled, users must use 2FA to access the organization"
                                  labelPosition="left"
                              ></se-form-toggle-editor>
                          `
                        : html``}
                </se-edit-form-card>

                <se-card-buttons>
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Create" : "Save"} Organization"
                    ></se-primary-button>
                    <se-secondary-button @click="${() => this.close(false)}" text="Cancel"></se-secondary-button>
                </se-card-buttons>
            </se-loading-panel>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
}
