import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { AuthService } from "../../services/auth.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { SubscriptionPlanService } from "../../services/subscription-plan.service";
import { SubscriptionPlan } from "../../models/subscription-plan-view-model";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { MenuItem } from "../components/menu.element";
import { SortOrder } from "se-shared/enums/sort-order";
import { SePaginationElement } from "../components/pagination.element";

@customElement("se-subscription-plans")
export class SeSubscriptionPlansElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _toasterService: ToasterService;
    private _subscriptionPlanService: SubscriptionPlanService;
    @state() private _hasSelectedRows = false;
    @state() private _isLoading = true;

    private _pageIndex = 1;
    private _recordsPerPage = 15;
    private _totalRecordCount: number;
    private _sortColumn = "name";
    private _sortOrder = 0;

    @state() private _data: SubscriptionPlan[] = [];
    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("se-pagination") private _pagination: SePaginationElement;

    private _columns: DataGridColumn[] = [];

    private _paginationInitialized = false;
    private _sortInitialized = false;


    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._subscriptionPlanService = container.resolve(SubscriptionPlanService);
    }

    connectedCallback() {
        super.connectedCallback();
        
        // Listen for URL initialization events from pagination and data grid
        this.addEventListener('url-initialized', this._handlePaginationInitialized);
        this.addEventListener('sort-initialized', this._handleSortInitialized);
                
        this.loadColumns();
    }
    
    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('url-initialized', this._handlePaginationInitialized);
        this.removeEventListener('sort-initialized', this._handleSortInitialized);
    }
    
    private _handlePaginationInitialized = (event: CustomEvent) => {
        this._paginationInitialized = true;
        
        // Update page index from the message
        if (event.detail && event.detail.pageIndex) {
            this._pageIndex = event.detail.pageIndex;
        }
        
        this._tryLoadData();
    }
    
    private _handleSortInitialized = (event: CustomEvent) => {
        this._sortInitialized = true;
        
        // Update local sort variables from the message
        if (event.detail) {
            const { sortColumn, sortOrder } = event.detail;
            if (sortColumn) {
                this._sortColumn = sortColumn;
                this._sortOrder = sortOrder === SortOrder.Ascending ? 1 : 0;
            }
        }
        
        this._tryLoadData();
    }
    
    private _tryLoadData() {
        // Load data when both components have initialized
        if (this._paginationInitialized && this._sortInitialized) {
            this.loadData();
        }
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    /**
     * Called when the user clicks the edit button for a subscription plan.
     * @param plan 
     */
    private onEdit(plan: SubscriptionPlan) {
        Router.go(`/manage/admin/subscription-plans/edit/${plan.id}`);
    }

    /**
     * Called when the user clicks the new button.
     */
    private onNew() {
        Router.go('/manage/admin/subscription-plans/new');
    }

    /**
     * Called when the user clicks the refresh button.
     */
    private onRefresh() {
        this.loadData();
    }

    /**
     * Called when the user clicks the delete button for a subscription plan.
     * @param plan 
     */
    private async deletePlanAsync(plan: SubscriptionPlan & { selected: boolean }) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Subscription Plan",
            body: `Are you sure you want to delete ${plan.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._subscriptionPlanService.api.deleteSubscriptionPlanAsync(plan.id);
            if (result.isOk) {
                await this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    /**
     * Called when the user clicks the delete button for multiple subscription plans.
     * @param planIds 
     */
    private async deleteAllPlansAsync(planIds: number[]) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Subscription Plans",
            body: `Are you sure you want to delete ${planIds.length === 1 ? "this plan" : `${planIds.length} subscription plans`}?`,
            saveCaption: "Delete",
        });
        
        // Always update the selection state after dialog closes
        this.gridSelectionChanged();
        
        if (result.isSave) {
            const result = await this._subscriptionPlanService.api.deleteAllSubscriptionPlansAsync(planIds);
            if (result.isOk) {
                await this.loadData();
                this._selectAll.value = false;
                this._hasSelectedRows = false;        
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async loadData() {
        this._isLoading = true;
        try {
            const result = await this._subscriptionPlanService.api.getSubscriptionPlansAsync({
                pageIndex: this._pageIndex,
                recordsPerPage: this._recordsPerPage,
                sortColumn: this._sortColumn,
                sortOrder: this._sortOrder
            });
            if (result.isOk) {
                this._data = result.value.subscriptionPlans;
                this._totalRecordCount = result.value.totalRecordCount;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const newSortColumn = evt.detail.sortColumn || this._sortColumn;
        const newSortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : 0;
        
        if (newSortColumn !== this._sortColumn || newSortOrder !== this._sortOrder) {
            this._sortColumn = newSortColumn;
            this._sortOrder = newSortOrder;
            this.loadData();
        }
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        if (this._pageIndex !== evt.detail.pageIndex) {
            this._pageIndex = evt.detail.pageIndex;
            this._dataGrid.pageIndex = this._pageIndex;
            if (this._selectAll) {
                this._selectAll.value = false;
                this._selectAll.liveValue = false;
            }
            this._hasSelectedRows = false;
            this.loadData();
        }
    }

    private menu(row: SubscriptionPlan, col: DataGridColumn): MenuItem[] {
        return [
            { text: "Edit", action: () => this.onEdit(row) },
            { text: "-" },
            { text: "Delete", action: () => this.deletePlanAsync(row as SubscriptionPlan & { selected: boolean }) },
        ];
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();
        const planIds = this._dataGrid.selectedRows.map((p) => p.id as number);
        await this.deleteAllPlansAsync(planIds);
    }

    private loadColumns() {
        this._columns = [
            { field: "name", title: "Name", actionLink: (row) => this.onEdit(row), sortable: true },
            { 
                field: "price", 
                title: "Price",
                template: (row) => html`$${row.price?.toFixed(2)}/mo`,
                sortable: true
            },
            {
                field: "runUsage",
                title: "Server Time",
                template: (row) => html`$${row.runUsage}/hrs`,
                sortable: true
            },
            {
                field: "exportedData",
                title: "Export GB",
                template: (row) => html`$${row.exportedData}/GB`,
                sortable: true
            },
            {
                field: "proxyUsage",
                title: "Proxy Data",
                template: (row) => html`$${row.proxyUsage}/GB`,
                sortable: true
            },
            {
                field: "isActive",
                title: "Status",
                align: "center",
                template: (row) => html`<se-status 
                    status-message=${row.isActive ? "Active" : "Disabled"}
                    status-color=${row.isActive ? "--color-status-green" : "--color-gray-4"}
                    style="width: 100%; max-width: 110px"
                ></se-status>`,
                sortable: true
            },
            {
                field: "updated",
                align: "center",
                title: "Updated",
                template: (row) => html`${new Date(row.updated).toLocaleDateString()} ${new Date(row.updated).toLocaleTimeString()}`,
                sortable: true
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>

                        ${
                            this._hasSelectedRows
                                ? html`
                                      <se-secondary-button
                                          @mousedown=${(event) => this.onDeleteMany(event)}
                                          ${htmlTitle("Delete Selected")}
                                          .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                          icon="far fa-trash-alt"
                                      ></se-secondary-button>
                                  `
                                : html`
                                      <se-secondary-button
                                          @click=${() => this.onRefresh()}
                                          ${htmlTitle("Refresh")}
                                          .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                          icon="far fa-redo"
                                      ></se-secondary-button>
                                  `
                        }
                    </div>
                    <div style="display: flex;gap:20px; font-size:0.95em;align-items: baseline">                            
                        <se-primary-button text="New Plan" @click="${() => this.onNew()}"></se-primary-button>                                                
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No subscription plans available."
                    .isLoading=${this._isLoading}
                    .pageIndex=${this._pageIndex}
                    .sortColumn=${this._sortColumn}
                    .sortOrder=${this._sortOrder === 1 ? SortOrder.Ascending : SortOrder.Descending}
                    .syncWithUrl=${true}
                ></se-data-grid>
                <se-pagination
                    .recordCount=${this._totalRecordCount}
                    .recordsPerPage=${this._recordsPerPage}
                    @pagechanged=${this.onPageChanged}
                    .pageIndex=${this._pageIndex}
                    .syncWithUrl=${true}
                ></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
    `;
}