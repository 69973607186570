import { LitElement } from "lit";
import { singleton } from "tsyringe";
import { ModalEditorResult } from "se-shared/utils/result";
import "../elements/modal-editors/confirm-dialog.element";
import "../elements/modal-editors/copy-dialog.element";
import "../elements/modal-editors/info-dialog.element";
import "../elements/modal-editors/publish-dialog.element";
import "../elements/modal-editors/publish-to-space-dialog.element";
import "../elements/modal-editors/rename-dialog.element";
import "../elements/modal-editors/object-info-dialog.element";
import "../elements/modal-editors/comment-dialog.element";
import "../elements/modal-editors/confirm-with-comments-dialog.element";
import "../elements/modal-editors/new-agent-from-template-dialog.element";

export interface ModelDialog {
    showDialog(options: any);
}

@singleton()
export class ModalDialogService {
    private _closeResolve: (value?: ModalEditorResult<any> | PromiseLike<ModalEditorResult<any>>) => void;
    private _closePromise: Promise<ModalEditorResult<any>>;

    private _modalDialog: LitElement & ModelDialog;

    private _isOpen = false;

    get isOpen() {
        return this._isOpen;
    }

    private createElement(component: string) {
        if (!this._modalDialog) {
            this._modalDialog = document.createElement(component) as LitElement & ModelDialog;
            document.body.appendChild(this._modalDialog);
            this._modalDialog.style.position = "fixed";
            this._modalDialog.style.zIndex = "2000";
            this._modalDialog.style.left = "0";
            this._modalDialog.style.top = "0";
            this._modalDialog.style.width = "100%";
            this._modalDialog.style.height = "100%";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.overflow = "auto";
            this._modalDialog.style.backgroundColor = "rgba(0,0,0,0.4)";
            (this._modalDialog.style as any).backdropFilter = "blur(1px)";
            this._modalDialog.style.font = "var(----font-ui)";
        }
    }

    close(isSave: boolean, value?: any) {
        if (this._modalDialog) {
            document.body.removeChild(this._modalDialog);
            this._modalDialog = undefined;
        }
        this._isOpen = false;
        const result = new ModalEditorResult(isSave, value);
        this._closeResolve(result);
    }

    private async openModelDialogAsync(component: string, options?: any) {
        if (this._modalDialog) {
            this.close(true);
        }
        this.createElement(component);
        await this._modalDialog.showDialog(options);
        this._isOpen = true;
        this._closePromise = new Promise((r) => {
            this._closeResolve = r;
        });
        return this._closePromise;
    }

    async openConfirmDialogAsync(options: { title: string; body: string; saveCaption?: string; cancelCaption?: string }) {
        return this.openModelDialogAsync("se-confirm-dialog", options);
    }
    async openInfoDialogAsync(options: { title: string; body: string }) {
        return this.openModelDialogAsync("se-info-dialog", options);
    }
    async openPublishToSpaceDialogAsync(options: { data: any[]; draftId: number; defaultName: string; defaultSpace: string }) {
        return this.openModelDialogAsync("se-publish-to-space-dialog", options);
    }
    async openPublishDialogAsync(options: { data: any[]; row: any }) {
        return this.openModelDialogAsync("se-publish-dialog", options);
    }
    async openCopyDialogAsync(options: { configId: number; defaultName: string }) {
        return this.openModelDialogAsync("se-copy-dialog", options);
    }
    async openNewAgentFromTemplateAsync() {
        return this.openModelDialogAsync("se-new-agent-from-template-dialog");
    }
    async openRenameDialogAsync(options: { configId: number; defaultName: string }) {
        return this.openModelDialogAsync("se-rename-dialog", options);
    }
    async openObjectInfoDialogAsync(options: { title: string; anyObject: unknown; lastObject?: unknown }) {
        return this.openModelDialogAsync("se-object-info-dialog", options);
    }
    async openCommentDialogAsync(options: { configId: number; defaultComments: string }) {
        return this.openModelDialogAsync("se-comment-dialog", options);
    }
    async openConfirmWithCommentsDialogAsync(options: {
        title: string;
        body: string;
        comments: string;
        saveCaption?: string;
        cancelCaption?: string;
    }) {
        return this.openModelDialogAsync("se-confirm-with-comments-dialog", options);
    }
}
