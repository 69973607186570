import { ToasterService } from "se-shared/services/toaster.service";
import { container, singleton } from "tsyringe";
import { ConfigType } from "../enums/config-type";
import { AuthService } from "./auth.service";
import { ModalDialogService } from "./modal-editor.service";
import { ServerService } from "./server.service";

@singleton()
export class EditorService {
    private _toasterService: ToasterService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _serverService: ServerService;

    constructor() {
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._authService = container.resolve(AuthService);
        this._serverService = container.resolve(ServerService);
    }

    async openEditorAsync(input: {
        configType?: ConfigType;
        draftId?: number;
        configId?: number;
        spaceId?: number;
        templates?: string;
    }): Promise<boolean> {
        //get SeEditor URL from server
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        let response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return false;
        } else {
            if (response.value.isServerPending) {
                const dialogResult = await this._modalService.openConfirmDialogAsync({
                    title: "All editor servers are busy",
                    body: "All editor servers are busy so we've started a new server for you, but this may take a minute or so. Please <b>Keep Waiting</b> to launch the editor automatically when ready, or <b>Cancel</b> to try again later.",
                    saveCaption: "Keep Waiting",
                    cancelCaption: "Cancel",
                });
                if (dialogResult.isSave) {
                    response = await this._serverService.api.getPendingEditorDetailsAsync();
                    if (response.isErr) {
                        console.error(response.err);
                        this._toasterService.showNetworkError(response.err);
                        return false;
                    }
                } else {
                    return false;
                }
            }
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        //this.dispatchEvent(new CustomEvent("startloading", { bubbles: true, composed: true, detail: {} }));
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);
        //form.setAttribute("target", "Sequentum Enterprise Editor");

        const params = {
            ...{
                redirectUrl: window.location.href, //callback url when editing is complete
                apiUrl: window.location.protocol + "//" + hostApi + "/api", //internal api url to use server side
                webApiUrl: window.location.origin + "/api", //external api url to use when querying control center
                token: this._authService.token, //auth token
                isInternalOrg: this._authService.user.organizationName === "Sequentum", // Hardcoded, internal organization which has special previllages
                editorServerHost: editorServer, //editor server that editor client should connect to
                orgId: this._authService.user.organizationId,
                isProxyRequired: this._authService.orgSettings.isProxyRequired,
                isDevTools: this._authService.orgSettings.isAllowDevTools,
            },
            ...input,
        };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return true;
    }
}
