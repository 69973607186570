import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { ModalEditorResult } from "se-shared/utils/result";
import { ConfigService } from "../../services/config.service";
import { DraftApi } from "../../services/draft.api";
import { ModalDialogService, ModelDialog } from "../../services/modal-editor.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { BaseEditor, ModalEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { CheckboxListEditorElement, checkboxOption } from "../editors/checkbox-list-editor.element";
import { ConfigType } from "../../enums/config-type";
import { EditorService } from "../../services/editor.service";

@customElement("se-new-agent-from-template-dialog")
export class NewAgentFromTemplateDialogElement extends LitElement implements ModelDialog {
    private _modalService: ModalDialogService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _configService: ConfigService;
    private _editorService: EditorService;

    @query("#checkboxList") private _checkboxList: CheckboxListEditorElement;

    @state() private _isLoading = true;
    @state() private _agentTemplates: checkboxOption[] = [];

    private _selectedRows: any[];

    constructor() {
        super();

        this._userState = container.resolve(UserState);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._configService = container.resolve(ConfigService);
        this._editorService = container.resolve(EditorService);
    }

    async showDialog() {
        try {
            this._isLoading = true;

            const availableTemplates = await this._configService.api.getAvailableAgentTemplatesAsync(this._userState.selectedSpaceId);
            if (availableTemplates.isOk) {
                this._agentTemplates = availableTemplates.value.map((p) => {
                    return { id: p.id, name: p.name, value: false };
                });
            } else {
                this._toasterService.showNetworkError(availableTemplates.err);
            }
        } finally {
            this._isLoading = false;
        }
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    updateChangedValues() {
        this.requestUpdate();
    }

    firstUpdated() {}

    private cancel(ev) {
        ev.preventDefault();
        this._modalService.close(false);
    }

    async saveAsync() {
        if (this.reportValidity()) {
            await this._editorService.openEditorAsync({
                configType: ConfigType.Agent,
                spaceId: this._userState.selectedSpaceId,
                templates: JSON.stringify(this._checkboxList.liveValue.filter((p) => p.liveValue).map((p) => p.id)),
            });
        }
    }

    private searchChanged(event: CustomEvent) {
        this._checkboxList.filter = event.detail.value;
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="header">
                    <div style="font-weight: bold">New Agent from One or More Templates</div>
                    <div class="icon" @click=${this.cancel}><fa-icon scale="1.2" fa-class="fas fa-times-circle"></fa-icon></div>
                </div>
                <div class="body">
                    <div class="container" id="editorContainer">
                        <se-loading-panel
                            id="loadingPanel"
                            .loadingStyle=${{
                                borderTop: "solid 1px lightgray",
                                backgroundColor: "white",
                                minHeight: "50px",
                                minWidth: "125px",
                            }}
                            .isLoading=${this._isLoading}
                        >
                            ${this._agentTemplates.length > 0
                                ? html` <div class="search">
                                          <se-input-editor
                                              maxlength="20"
                                              class="input"
                                              @editorChanged=${this.searchChanged}
                                          ></se-input-editor
                                          ><fa-icon class="icon" fa-class="far fa-search" style="margin-top:5px"></fa-icon>
                                      </div>
                                      <div class="body">
                                          <div class="scroll-container" id="editorContainer">
                                              <se-checkbox-list-editor
                                                  id="checkboxList"
                                                  .value=${this._agentTemplates}
                                              ></se-checkbox-list-editor>
                                          </div>
                                      </div>`
                                : html`
                                      <div style="padding:10px; background-color: white;margin-top:2px">
                                          <p>No agent templates available.</p>
                                      </div>
                                  `}
                        </se-loading-panel>
                    </div>
                    <div class="savePanel">
                        <se-primary-button
                            .action="${() => this.saveAsync()}"
                            action-text="Opening..."
                            action-delay="500"
                            text="New Agent"
                        ></se-primary-button>
                        <se-secondary-button @click="${this.cancel}" text="Cancel"></se-secondary-button>
                    </div>
                </div>
            </form>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
        }
        .editor {
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            margin: auto;
            margin-top: 20vh;
            box-shadow: 4px 4px 4px rgb(80, 80, 80);
            font: var(--font);
            width: 450px;
        }
        .body {
            background-color: white;
            display: flex;
            flex-direction: column;
            width: fit-content;
            min-height: 0;
            padding: 0px;
            border-radius: 0px 0px 5px 5px;
            width: 100%;
        }
        .header {
            background-color: var(--color-light);
            padding: 5px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: space-between;
            align-items: center;
            color: black;
            border-radius: 5px 5px 0px 0px;
            border-bottom: solid 1px whitesmoke;
        }
        .icon {
            color: gray;
        }
        .icon:hover {
            color: darkred;
        }
        .container {
            height: 100%;
            min-height: 60px;
            overflow: auto;
            padding: 15px;
            background-color: white;
            box-sizing: border-box;
            border-top: 1px solid gray;
            border-bottom: 1px solid gray;
            border-radius: 3px 3px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            padding: 8px 5px 10px 50px;
            background-color: var(--color-light);
        }
        .search {
            background-color: white;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
        }
        .icon {
            position: absolute;
            right: 15px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px 5px 10px 5px;
            background-color: white;
            max-height: 250px;
            max-width: 400px;
        }        
    `;
}
