export class OrganizationSubscription {
    constructor() {}

    id?: number;
    orgId: number;
    subscriptionPlanId?: number;
    name: string;
    price?: number;
    runUsage?: number;
    exportedData?: number;
    proxyUsage?: number;
    endDate?: Date;
    isActive?: boolean;
    isCustom: boolean;
    isCanceled?: boolean;
    startDate?: Date;
    created?: Date;
    updated?: Date;
} 