import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ProxyService } from "../../services/proxy.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-proxies")
export class SeProxiesElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    public _proxyService: ProxyService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _poolName = "null";
    private _sortColumn = "updated";
    private _sortOrder = 1;
    private _type = -1;
    @state() private _data: any[] = [];

    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("#filter") private _filterEditor: InputEditorElement;

    private _menuService: MenuService;
    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._proxyService = container.resolve(ProxyService);
        this._menuService = container.resolve(MenuService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            { text: "Edit", action: this.editProxyPoolAsync.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteProxyPoolAsync.bind(this, row, col) },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceId = -5;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();

    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    private async deleteProxyPoolAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Server",
            body: `Are you sure you want to delete ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._proxyService.api.deleteAsync(row.id);
            if (result.isOk) {
                const index = this._data.indexOf(row);
                this._data.splice(index, 1);
                if (row.selected) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                if (result.err.message.indexOf("SQLite Error 19") != -1) {
                    this._modalService.openInfoDialogAsync({
                        title: "Delete Server",
                        body: `Cannot delete proxy pool <strong>${row.name}</strong> because you have an agent that is scheduled to run using this proxy pool.<br/> Please assign the agent to a different proxy pool in order to continue with deleting this proxy pool.`,
                    });
                } else {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        }
    }

    private async editProxyPoolAsync(row: any, col: DataGridColumn) {
        Router.go(`/org/proxies/edit/${row.id}`);
    }

    firstUpdated() {}

    private async loadData() {
        this._columns = [
            { field: "name", title: "Pool Name", cellStyle: { textDecoration: "underline" }, action: this.editProxyPoolAsync.bind(this), sortable: true },
            { field: "description", title: "Description" },
            { field: "proxyCount", title: "Proxy Count", sortable: true },
            {
                field: "updated",
                title: "Updated",
                align: "center",
                sortable: true,
                template: (row, col) => html`${new Date(row.updated).toLocaleDateString()}`,
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
        const result = await this._proxyService.api.getAllProxyPoolsAsync(
            this._pageIndex,
            this._recordsPerPage,
            this._poolName,
            this._sortColumn,
            this._sortOrder
        );
        if (result.isOk) {
            this._data = result.value.proxyPools;
            this._totalRecordCount = result.value.totalRecordCount;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        this._sortColumn = evt.detail.sortColumn;
        this._sortOrder = evt.detail.sortOrder;
        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        if (this._selectAll) {
            this._selectAll.value = false;
            this._selectAll.liveValue = false;
        }
        this._hasSelectedRows = false;
        this.loadData();
    }

    // the Select element uses this function to filter the output
    private filterByPoolName(evt: any) {
        this._poolName = this._filterEditor.liveValue;
        this._poolName == "" ? (this._poolName = "null") : this._poolName;
        this.loadData();
    }

    private addNewProxyPool() {
        Router.go("/org/proxies/new");
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Proxy Pools",
            body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} proxy pools`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const proxyPoolIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._proxyService.api.deleteAllAsync(proxyPoolIds);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>
                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                        <se-input-editor
                            id="filter"
                            name="filter"
                            type="text"
                            label="Filter&nbsp;by&nbsp;Pool&nbsp;Name"
                            labelPosition="left"
                            gap="5px"
                            input-type="text"
                            size="30"
                            @editorChanged=${this.filterByPoolName}
                        ></se-input-editor>
                    </div>
                    <se-primary-button @click=${this.addNewProxyPool} text="New Proxy Pool">New Proxy Pool</se-primary-button>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No proxies available."
                ></se-data-grid>
                <se-pagination .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
