import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { container } from "tsyringe";
import { NetworkResult } from "se-shared/utils/result";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ConfigType } from "../../enums/config-type";
import { isReadyToRun, isRunningOnServerOrQueingOrWaiting, RunStatus } from "../../enums/run-status";
import { ValidationStatus } from "../../enums/validation-status";
import { ConfigSummaryViewModel } from "../../models/config-summary-view-model";
import { ConfigsSummaryViewModel } from "../../models/configs-summary-view-model";
import { DraftSummaryViewModel } from "../../models/draft-summary-view-model";
import { AuthService } from "../../services/auth.service";
import { ConfigApi } from "../../services/config.api";
import { ConfigService } from "../../services/config.service";
import { DraftService } from "../../services/draft.service";
import { FileUploadApi } from "../../services/file-upload.api";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ServerService } from "../../services/server.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { MenuItem } from "../components/context-menu.element";
import { DataGridColumn } from "../components/data-grid-template";
import "../components/data-grid.element";
import { SeDataGrid } from "../components/data-grid.element";
import "../components/dropdown-button.element";
import { SeDropDownButton } from "../components/dropdown-button.element";
import "../components/pagination.element";
import { SePaginationElement } from "../components/pagination.element";
import "../components/primary-button.element";
import "../components/secondary-button.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import "../menu-editors/apply-templates.element";
import "../menu-editors/assign-shared-files.element";
import "../menu-editors/copy-configs.element";
import "../menu-editors/move-configs.element";
import "./name-column";
import "./space-search-editor.element";
import "../components/status.element";
import { MultiSelectEditor } from "../editors/multi-select-editor.element";
import { RunApi } from "../../services/run.api";
Chart.register(...registerables);
import { Chart, registerables } from "chart.js";
import "../components/cards/card.element";
import { EditorService } from "../../services/editor.service";
import { SortOrder } from "se-shared/enums/sort-order";

@customElement("se-space")
export class SeSpaceElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _draftService: DraftService;
    private _configService: ConfigService;
    private _configApi: ConfigApi;
    private _runApi: RunApi;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _serverService: ServerService;
    private _fileUploadApi: FileUploadApi;
    private _editorService: EditorService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn = "name";

    private _sortOrder = -1;
    private _fileterType: "none" | "label" | "template" | "shared-file" | "status" = "none";
    private _filterId?: number;
    @state() private _data: (ConfigSummaryViewModel | DraftSummaryViewModel)[];

    @state() private _hasSelectedRows = false;
    @state() private _hasSelectedAgentsWithUnknownValidation = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("se-pagination") private _dataPagination: SePaginationElement;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("#newAgentButton") private _newAgentElement: SeDropDownButton;

    private loadDataTimeout: NodeJS.Timeout;

    private selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";

    @query("#doughnutChartCanvas") private _doughnutChartCanvas: HTMLCanvasElement;
    @query("#trendsChartCanvas") private _trendsChartCanvas: HTMLCanvasElement;
    private doughnutChart?: Chart;
    private trendsChart?: Chart;

    @query("#multiSelectLabel") private _multiSelectLabel: MultiSelectEditor;
    @query("#multiSelectStatus") private _multiSelectStatus: MultiSelectEditor;
    @query("#multiSelectTemplate") private _multiSelectTemplate: MultiSelectEditor;
    private filters: {
        status?: string;
        label?: string;
        template?: string;
    } = {};
    private _labels;
    private _templates;

    private _last2WeeksData;
    private _generalRunsStatus: number[];
    private _agentCount = 0;
    private _fileCount = 0;
    private _templatesCount = 0;
    @state() private _spaceName = "";
    private animateChart = true;
    private leftMenuChangedListener;

    private _sortInitialized = false;
    private _paginationInitialized = false;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._serverService = container.resolve(ServerService);
        this._toasterService = container.resolve(ToasterService);
        this._draftService = container.resolve(DraftService);
        this._userState = container.resolve(UserState);
        this._configApi = container.resolve(ConfigApi);
        this._runApi = container.resolve(RunApi);
        this._configService = container.resolve(ConfigService);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);
        this._fileUploadApi = container.resolve(FileUploadApi);
        this._editorService = container.resolve(EditorService);
    }

    private draftMenu(row: DraftSummaryViewModel, col: DataGridColumn): MenuItem[] {
        return [
            {
                text: "Open",
                menu: [
                    { text: "Open In Editor", action: this.openDraft.bind(this, row, col) },
                    { text: "Open As Text", action: this.openDraft.bind(this, row, col) },
                ],
            },
            { text: "-" },
            { text: "Publish", hidden: !row.configId, action: this.publishDraftAsync.bind(this, row, col) },
            { text: "Publish To...", action: this.publishDraftToSpaceAsync.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteDraftAsync.bind(this, row, col) },
        ];
    }
    async loadLabels() {
        if (this._userState.selectedSpaceId >= 0) {
            const labels = await this._userState.getUserLabelsAsync();
            if (labels.isOk) {
                this._labels = [...labels.value];
            } else {
                this._toasterService.showUnexpectedError(labels.err.message);
            }
        } else {
            this._labels = [];
        }
    }
    private configMenu(row: ConfigSummaryViewModel, col: DataGridColumn): MenuItem[] {
        return [
            /*{
                text: "Open",
                menu: [
                    { text: "Open In Editor", action: this.openConfig.bind(this, row, col) },
                    { text: "Open As Text", action: this.openConfig.bind(this, row, col) },
                ],
            }
            { text: "-" },*/
            {
                text: "Run",
                hidden: row.configType === ConfigType.SharedFile,
                menu: [
                    { text: "Setup Run", action: this.openRunEditor.bind(this, row) },
                    { text: "-" },
                    { text: "Current Runs", action: this.openActiveRuns.bind(this, row, col) },
                    { text: "Current Tasks", action: this.openActiveTasks.bind(this, row, col) },
                    { text: "-" },
                    { text: "Delete All Runs", action: this.deleteConfigRunsAsync.bind(this, row, col) },
                ],
            },
            { text: "-", hidden: row.configType === ConfigType.SharedFile },
            { text: "Copy Here", action: this.copyAsync.bind(this, row, col) },
            { text: "Copy To Personal", hidden: this._userState.selectedSpaceId === 0, action: this.copyToHomeAsync.bind(this, row, col) },
            { text: "Rename", action: this.renameAsync.bind(this, row, col) },
            { text: "-" },
            { text: "Delete", action: this.deleteConfigAsync.bind(this, row, col) },
        ];
    }

    private newMenu(): MenuItem[] {
        return [
            {
                text: "New Agent",
                action: () =>
                    this._newAgentElement.doAction(
                        this._editorService.openEditorAsync.bind(this, {
                            configType: ConfigType.Agent,
                            spaceId: this._userState.selectedSpaceId,
                        })
                    ),
            },
            {
                text: "New Agent from Template",
                action: () => this._modalService.openNewAgentFromTemplateAsync(),
            },
            { text: "-" },
            {
                text: "New Agent Template",
                action: () =>
                    this._newAgentElement.doAction(
                        this._editorService.openEditorAsync.bind(this, {
                            configType: ConfigType.Template,
                            spaceId: this._userState.selectedSpaceId,
                        })
                    ),
            },
            ...(this._userState.selectedSpaceId >= 0
                ? [
                      { text: "-" },
                      {
                          text: "New Shared File",
                          action: () => this._newAgentElement.doAction(this.newSharedFile.bind(this)),
                      },
                  ]
                : []),
        ];
    }

    async connectedCallback() {
        super.connectedCallback();
        this._userState.gridRowsChanged.bind(this, this.gridRowsChanged);
        this._userState.gridDataChanged.bind(this, this.onGridDataChanges);

        this.handleLeftMenuChanged = this.handleLeftMenuChanged.bind(this);
        this.leftMenuChangedListener = () => this.handleLeftMenuChanged();

        // Listen for URL initialization events from pagination and data grid
        this.addEventListener('url-initialized', this._handlePaginationInitialized);
        this.addEventListener('sort-initialized', this._handleSortInitialized);

        document.addEventListener("leftMenuChanged", this.leftMenuChangedListener);
        await this.loadLabels();
    }

    disconnectedCallback() {
        this._userState.gridRowsChanged.unbind(this, this.gridRowsChanged);
        this._userState.gridDataChanged.unbind(this, this.onGridDataChanges);
        document.removeEventListener("leftMenuChanged", this.leftMenuChangedListener);
        
        // Remove URL initialization event listeners
        this.removeEventListener('url-initialized', this._handlePaginationInitialized);
        this.removeEventListener('sort-initialized', this._handleSortInitialized);
        
        super.disconnectedCallback();
    }

    private _handlePaginationInitialized = (event: CustomEvent) => {
        this._paginationInitialized = true;
        
        // Update page index from the message
        if (event.detail && event.detail.pageIndex) {
            this._pageIndex = event.detail.pageIndex;
        }
        
        this._tryLoadData();
    }
    
    private _handleSortInitialized = (event: CustomEvent) => {
        this._sortInitialized = true;
        
        // Update local sort variables from the message
        if (event.detail) {
            const { sortColumn, sortOrder } = event.detail;
            if (sortColumn) {
                this._sortColumn = sortColumn;
                this._sortOrder = sortOrder === SortOrder.Ascending ? -1 : 1;
            }
        }
        
        this._tryLoadData();
    }
    
    private _tryLoadData() {
        // Load data when both components have initialized
        if (this._paginationInitialized && this._sortInitialized) {
            this.loadDataAsync();
        }
    }

    private async handleLeftMenuChanged() {
        this._sortColumn = "name";
        this._sortOrder = 0;
        await this.loadDataAsync(false);
        await this.loadLabels();
    }

    firstUpdated() {
        this.loadDoughnutChart = this.loadDoughnutChart.bind(this);
        window.addEventListener("resize", this.loadDoughnutChart);
    }
    private onGridDataChanges() {
        this._fileterType = this._userState.selectedLabelId > 0 ? "label" : "none";
        this.loadDataAsync();
    }

    private openRunEditor(row: ConfigSummaryViewModel) {
        Router.go(`space/${this._userState.selectedSpaceId}/label/${this._userState.selectedLabelId}/config/${row.id}/run`);
    }

    private gridRowsChanged() {
        this._hasSelectedAgentsWithUnknownValidation =
            this._dataGrid.selectedRows.filter(
                (p) => p.configType === ConfigType.Agent && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
            ).length > 0;
        this._dataGrid.requestUpdate();
    }

    private openDraft(row: DraftSummaryViewModel) {
        if (this._userState.selectedSpaceId === -1) {
            this._editorService.openEditorAsync({ draftId: row.id });
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async deleteDraftAsync(row: DraftSummaryViewModel) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete",
            body: `Are you sure you want to delete ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._draftService.api.deleteAsync(row.id);
            if (result.isOk) {
                this._data = this._data.filter((p) => p.id !== row.id);
                this._userState.updateDraftCount(this._data.length);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async publishDraftAsync(row: DraftSummaryViewModel) {
        if (this._userState.isDraftSpace) {
            await this._modalService.openPublishDialogAsync({ data: this._data, row: row });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private async publishDraftToSpaceAsync(row: DraftSummaryViewModel) {
        if (this._userState.isDraftSpace) {
            await this._modalService.openPublishToSpaceDialogAsync({
                data: this._data,
                draftId: row.id,
                defaultName: row.name,
                defaultSpace: row.spaceName,
            });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private openConfig(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            this._editorService.openEditorAsync({ configId: row.id });
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async deleteConfigRunsAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            const modalResult = await this._modalService.openConfirmDialogAsync({
                title: "Delete All Runs",
                body: `Are you sure you want to delete all ${row.name} runs? This will also reset the run sequence back to zero.`,
                saveCaption: "Delete all",
            });
            if (modalResult.isSave) {
                const result = await this._configService.api.deleteAllRunsAsync(row.id);
                if (result.isOk) {
                    this.loadDataAsync();
                } else {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async deleteConfigAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            const modalResult = await this._modalService.openConfirmDialogAsync({
                title: "Delete Config",
                body: `Are you sure you want to delete ${row.name}?`,
                saveCaption: "Delete",
            });
            if (modalResult.isSave) {
                const result = await this._configService.api.deleteAsync(row.id);
                if (result.isOk) {
                    const index = this._data.indexOf(row);
                    this._data.splice(index, 1);
                    if (row["selected"]) this.gridSelectionChanged();
                    this._dataGrid.requestUpdate();
                } else {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        } else {
            throw new Error("Invalid operation");
        }
    }
    private async copyToHomeAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            const result = await this._configService.api.copyToSpaceAsync(row.id, [0]);
            if (!result.isOk) {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } else {
            throw new Error("Invalid operation");
        }
    }

    private async copyAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            await this._modalService.openCopyDialogAsync({ configId: row.id, defaultName: row.name + " Copy" });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private async renameAsync(row: ConfigSummaryViewModel) {
        if (this._userState.selectedSpaceId !== -1) {
            await this._modalService.openRenameDialogAsync({ configId: row.id, defaultName: row.name });
        } else {
            throw new Error("Invalid operation");
        }
    }

    private openVersionHistory(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/versions`);
    }

    private openActiveRuns(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/runs`);
    }
    private openDetails(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        if (row.configType === ConfigType.SharedFile) {
            Router.go(`/space/${this._userState.selectedSpaceId}/shared-file/${row.id}/details`);
        } else {
            Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/runs`);
        }
    }
    private openActiveTasks(row: ConfigSummaryViewModel) {
        this.setCurrentConfig(row);
        Router.go(`/space/${this._userState.selectedSpaceId}/config/${row.id}/details/tasks`);
    }

    private setCurrentConfig(row: ConfigSummaryViewModel) {
        this._userState.selectedConfigName = row.name;
        this._userState.selectedConfigId = row.id;
        this._userState.selectedConfigType = row.configType;
        this._userState.selectedConfigValidationStatus = row.validationStatus;
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
        const spaceId = location.params.spaceId ? parseInt(location.params.spaceId.toString()) : 0;
        // if label is negative, use 0 as default
        const labelId = location.params.labelId ? Math.max(0, parseInt(location.params.labelId.toString())) : 0;

        this._userState.selectedSpaceId = spaceId;
        this._userState.selectedLabelId = labelId;

        if (location.params.templateId) {
            this._fileterType = "template";
            this._filterId = parseInt(location.params.templateId.valueOf() as string);
        } else if (location.params.sharedFileId) {
            this._fileterType = "shared-file";
            this._filterId = parseInt(location.params.sharedFileId.valueOf() as string);
        }
    }

    async saveTitleEditorAsync(newValue: string, row?: ConfigSummaryViewModel): Promise<{ success: boolean; errorMessage?: string }> {
        if (newValue === "invalid") {
            return new Promise((resolve) => {
                resolve({ success: false, errorMessage: "Value cannot change." });
            });
        } else {
            if (row.name == newValue) {
                return { success: true };
            }
            const result = await this._configService.api.renameAsync(row.id, newValue);
            if (result.isOk) {
                if (row.configType === ConfigType.Template) {
                    this.refresh();
                }
                return { success: true };
            } else {
                return { success: false, errorMessage: result.err.message };
            }
        }
    }

    private getIconTemplate(validationStatus: ValidationStatus, icon: string) {
        return html`<span
            ${validationStatus !== ValidationStatus.Valid
                ? htmlTitle(validationStatus === ValidationStatus.Invalid ? "Agent is invalid" : "Agent has not been validated")
                : undefined}
            ><fa-icon
                class="icon"
                single-color=${validationStatus === ValidationStatus.Valid
                    ? "gray"
                    : validationStatus === ValidationStatus.Invalid
                      ? "salmon"
                      : "goldenrod"}
                fa-class="far ${icon}"
            ></fa-icon
        ></span>`;
    }

    private async loadDataAsync(isForceRefresh = true) {
        if (this.loadDataTimeout) {
            clearTimeout(this.loadDataTimeout);
            this.loadDataTimeout = undefined;
        }

        if (this._userState.isDraftSpace) {
            this._columns = [
                {
                    field: "configType",
                    cellStyle: { width: "0px", "text-align": "center" },
                    title: "",
                    template: (row) =>
                        html`${choose(
                            row.configType,
                            [
                                [
                                    ConfigType.Agent,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-robot"></fa-icon>`,
                                ],
                                [
                                    ConfigType.Template,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-cubes"></fa-icon>`,
                                ],
                                [
                                    ConfigType.SharedFile,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-file"></fa-icon>`,
                                ],
                            ],
                            () => html`${row.configType}`
                        )}`,
                },
                {
                    field: "name",
                    title: "Name",
                    sortable: true,
                    action: this.openDraft.bind(this),
                    template: (row, col) =>
                        html` <a href="javascript:;" @click=${() => col.action(row, col)}
                                ><span
                                    style="${row.name?.length > 50
                                        ? "text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 20vw;display: inline-block;"
                                        : ""}"
                                    title="${row.name}"
                                    >${row.name}</span
                                ></a
                            >${row.isRecovery ? html`&nbsp;&nbsp;(Recovered)` : html``}`,
                },
                { field: "spaceName", title: "Space", sortable: true },
                { field: "configVersion", title: "Version", align: "center" },
                {
                    field: "updated",
                    title: "Updated",
                    sortable: true,
                    align: "right",
                    template: (row) => html`${new Date(row.updated).toLocaleDateString()}`,
                },
                { name: "menu", align: "center", cellStyle: { width: "20px" }, menu: (row, col) => this.draftMenu(row, col) },
            ];

            if (isForceRefresh) this._isLoading = true;
            try {
                const result = await this._draftService.api.getAllAsync(
                    this._pageIndex,
                    this._recordsPerPage,
                    this._sortColumn,
                    this._sortOrder
                );
                if (result.isOk) {
                    this._data = result.value.draftSummaries;
                    this._totalRecordCount = result.value.totalRecordCount;

                    this._userState.updateDraftCount(this._totalRecordCount);
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this.resetPagination();
                } else {
                    this._toasterService.showUnexpectedError(result.err.message);
                }
            } finally {
                this._isLoading = false;
            }
        } else if (
            (this._userState.selectedLabelId !== -1 || this._fileterType === "template" || this._fileterType === "shared-file") &&
            (isForceRefresh || (!this._hasSelectedRows && !this._menuService.isMenuOpen()))
        ) {
            //if selectedLabelId is -1 and agentTemplate is -1 then user probably navigating back and this function will be called again when selectedLabelId has been set correctly.
            this._columns = [
                {
                    field: "configType",
                    cellStyle: { width: "0px", "text-align": "center" },
                    title: "",
                    template: (row) =>
                        html`${choose(
                            row.configType,
                            [
                                [ConfigType.Agent, () => this.getIconTemplate(row.validationStatus, "fa-robot")],
                                [ConfigType.Template, () => this.getIconTemplate(row.validationStatus, "fa-cubes")],
                                [
                                    ConfigType.SharedFile,
                                    () => html`<fa-icon class="icon" single-color="gray" fa-class="far fa-file"></fa-icon>`,
                                ],
                            ],
                            () => html`${row.configType}`
                        )}`,
                },
                {
                    field: "name",
                    title: "Name",
                    sortable: true,
                    component: "se-name-column",
                    action: (row) => this.openDetails(row),
                    editor: "text",
                    setValue: (newValue: string, row?: ConfigSummaryViewModel) => this.saveTitleEditorAsync(newValue, row),
                },
                {
                    field: "version",
                    title: "Version",
                    align: "center",
                    cellStyle: { width: "50px" },
                    actionLink: this.openVersionHistory.bind(this),
                    template: (row) => {
                        return row.configType !== ConfigType.SharedFile ? html`<span title="View all versions">${row.version}</span>` : html``;
                    }
                },
                {
                    field: "updated",
                    title: "Updated",
                    sortable: true,
                    align: "right",
                    template: (row) => html`${new Date(row.updated).toLocaleDateString()}`,
                },
                {
                    field: "lastActivity",
                    title: "Activity",
                    sortable: true,
                    align: "right",
                    template: (row) => {
                        return row.configType !== ConfigType.SharedFile
                            ? when(row.lastActivity, () => html`${new Date(row.lastActivity).toLocaleDateString()}`)
                            : html``;
                    },
                },
                {
                    field: "status",
                    title: "Status",
                    align: "center",
                    sortable: true,
                    template: (row) => {
                        return row.configType !== ConfigType.SharedFile
                            ? html`<a
                                  ${htmlTitle("View run details")}
                                  href="javascript:;"
                                  style="width: 100%; max-width: 80px; text-decoration: none;"
                                  @click=${() => this.openActiveRuns(row)}
                                  >${choose(
                                      row.status,
                                      [
                                          [
                                              undefined || null,
                                              () =>
                                                  html`<se-status status-message="No Activity" status-color="--color-gray-4"></se-status>`,
                                          ],
                                          [
                                              RunStatus.success,
                                              () =>
                                                  html`<se-status status-message="Success" status-color="--color-status-blue"></se-status>`,
                                          ],
                                          [
                                              RunStatus.failure,
                                              () =>
                                                  html`<se-status status-message="Failure" status-color="--color-status-red"></se-status>`,
                                          ],
                                          [
                                              RunStatus.failed,
                                              () => html`<se-status status-message="Failed" status-color="--color-status-red"></se-status>`,
                                          ],
                                          [
                                              RunStatus.completed,
                                              () =>
                                                  html`<se-status
                                                      status-message="Completed"
                                                      status-color="--color-status-blue"
                                                  ></se-status>`,
                                          ],
                                          [
                                              RunStatus.running,
                                              () => html`<se-status status-message="Running" status-color="--color-purple"></se-status>`,
                                          ],
                                          [
                                              RunStatus.exporting,
                                              () => html`<se-status status-message="Exporting" status-color="--color-purple"></se-status>`,
                                          ],
                                          [
                                              RunStatus.starting,
                                              () => html`<se-status status-message="Starting" status-color="--color-purple"></se-status>`,
                                          ],
                                          [
                                              RunStatus.stopped,
                                              () => html`<se-status status-message="Stopped" status-color="--color-gray-4"></se-status>`,
                                          ],
                                          [
                                              RunStatus.stopping,
                                              () => html`<se-status status-message="Stopping" status-color="--color-purple"></se-status>`,
                                          ],
                                          [
                                              RunStatus.queuing,
                                              () =>
                                                  html`<span style="color:var(--color-gray-4);text-decoration: underline;">Queuing</span>`,
                                          ],
                                          [
                                              RunStatus.waiting,
                                              () => html`<span style="color:teal;text-decoration: underline;">Waiting</span>`,
                                          ],
                                      ],
                                      () => html`${row.status}`
                                  )}</a
                              >`
                            : html``;
                    },
                },
                {
                    name: "menu",
                    align: "center",
                    cellStyle: { width: "20px" },
                    menu: (row, col) => this.configMenu(row, col),
                    htmlTitle: "More options",
                },
            ];

            this._userState.selectedIds = undefined; //Cleanup.

            if (isForceRefresh) this._isLoading = true;

            //filters
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.size > 0) {
                this.filters["status"] = urlParams?.get("status") || "";
                this.filters["label"] = urlParams?.get("label") || "";
                this.filters["template"] = urlParams?.get("template") || "";
            }
            const strFilters = JSON.stringify(this.filters);

            try {
                let result: NetworkResult<ConfigsSummaryViewModel>;
                let chartData;
                if (this._fileterType === "template") {
                    result = await this._configApi.getManyByAgentTemplateAsync(
                        this._userState.selectedSpaceId,
                        this._filterId,
                        this._pageIndex,
                        this._recordsPerPage,
                        this._sortColumn,
                        this._sortOrder,
                        strFilters
                    );
                    chartData = await this._configApi.getChartDataAsync(
                        "template",
                        this._userState.selectedSpaceId,
                        strFilters,
                        this._filterId
                    );
                } else if (this._fileterType === "shared-file") {
                    result = await this._configApi.getManyBySharedFileAsync(
                        this._userState.selectedSpaceId,
                        this._filterId,
                        this._pageIndex,
                        this._recordsPerPage,
                        this._sortColumn,
                        this._sortOrder,
                        strFilters
                    );
                    chartData = await this._configApi.getChartDataAsync(
                        "shared-file",
                        this._userState.selectedSpaceId,
                        strFilters,
                        this._filterId
                    );
                } else {
                    const label = await this._userState.getSelectedLabel();
                    if (label.isErr) {
                        this._toasterService.showUnexpectedError(label.err.message);
                    } else {
                        result = await this._configApi.getManyByLabelAsync(
                            this._userState.selectedSpaceId,
                            label.value.id,
                            this._pageIndex,
                            this._recordsPerPage,
                            this._sortColumn,
                            this._sortOrder,
                            strFilters
                        );
                        chartData = await this._configApi.getChartDataAsync(
                            "label",
                            this._userState.selectedSpaceId,
                            strFilters,
                            label.value.id
                        );
                    }
                }
                if (chartData.isOk) {
                    this._generalRunsStatus = [
                        chartData.value.runsPassed,
                        chartData.value.runsFailed,
                        chartData.value.runsNotRun,
                        chartData.value.runsProgress,
                    ];
                    this._agentCount = chartData.value.agentsCount;
                    this._fileCount = chartData.value.filesCount;
                    this._templatesCount = chartData.value.templatesCount;
                    this._last2WeeksData = chartData.value.last2WeeksByDay;
                    this._spaceName = chartData.value.spaceName;
                    this._templates = this.transformTemplates(chartData.value.templates);
                    this.loadDoughnutChart();
                    this.loadTrendsChart();
                    this.animateChart = false;
                }
                if (result.isOk) {
                    this._data = result.value.configSummaries;
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this._totalRecordCount = result.value.totalRecordCount;
                    this.resetPagination();

                    const isQuickUpdate = result.value.configSummaries.some(
                        (row) => row.status === RunStatus.queuing || row.status === RunStatus.starting || row.status === RunStatus.stopping
                    );

                    clearTimeout(this.loadDataTimeout);
                    this.loadDataTimeout = setTimeout(() => this.loadDataAsync(false), isQuickUpdate ? 2000 : 30000);
                } else {
                    this._toasterService.showUnexpectedError(result.err.message);
                }
            } finally {
                this._isLoading = false;
            }
        }
    }
    private transformTemplates(templates) {
        const seenIds = new Set<number>();
        const result = [];

        templates.forEach((templateStr) => {
            const obj = JSON.parse(templateStr) as { [key: string]: [string, number] };
            for (const idStr in obj) {
                const id = Number(idStr);
                if (!seenIds.has(id)) {
                    seenIds.add(id);
                    result.push({ id, name: Array.isArray(obj[idStr]) ? obj[idStr][0] : obj[idStr] });
                }
            }
        });

        return result;
    }
    /*private async openEditor(input: { configType?: ConfigType; draftId?: number; configId?: number; spaceId?: number }): Promise<boolean> {
        //get SeEditor URL from server
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        let response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return false;
        } else {
            if (response.value.pendingServerId) {
                const dialogResult = await this._modalService.openConfirmDialogAsync({
                    title: "Starting new editor server",
                    body: "All editor servers were busy so we started a new server for you. This may take a minute or so, but please <b>Keep Waiting</b> and the editor will launch automatically when ready.",
                    saveCaption: "Keep Waiting",
                    cancelCaption: "Cancel",
                });
                if (dialogResult.isSave) {
                    response = await this._serverService.api.getPendingEditorDetailsAsync(response.value.pendingServerId);
                    if (response.isErr) {
                        this._toasterService.showNetworkError(response.err);
                        return false;
                    }
                } else {
                    return false;
                }
            }
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        //this.dispatchEvent(new CustomEvent("startloading", { bubbles: true, composed: true, detail: {} }));
        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);
        //form.setAttribute("target", "Sequentum Enterprise Editor");

        const params = {
            ...{
                redirectUrl: window.location.href, //callback url when editing is complete
                apiUrl: window.location.protocol + "//" + hostApi + "/api", //internal api url to use server side
                webApiUrl: window.location.origin + "/api", //external api url to use when querying control center
                token: this._authService.token, //auth token
                isInternalOrg: this._authService.user.organizationName === "Sequentum", // Hardcoded, internal organization which has special previllages
                editorServerHost: editorServer, //editor server that editor client should connect to
                orgId: this._authService.user.organizationId,
                isProxyRequired: this._authService.orgSettings.isProxyRequired,
                isDevTools: this._authService.orgSettings.isAllowDevTools,
            },
            ...input,
        };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return true;
    }*/

    private async newSharedFile() {
        Router.go(`/edit/shared-file/new`);
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
                this._hasSelectedAgentsWithUnknownValidation =
                    this._dataGrid.selectedRows.filter(
                        (p) =>
                            p.configType !== ConfigType.SharedFile &&
                            (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
                    ).length > 0;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== (this._data?.length ?? 0)) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
            this._hasSelectedAgentsWithUnknownValidation =
                this._dataGrid.selectedRows.filter(
                    (p) =>
                        p.configType !== ConfigType.SharedFile && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
                ).length > 0;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
            this._hasSelectedAgentsWithUnknownValidation =
                this._dataGrid.selectedRows.filter(
                    (p) =>
                        p.configType !== ConfigType.SharedFile && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
                ).length > 0;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const newSortColumn = evt.detail.sortColumn || this._sortColumn;
        const newSortOrder = evt.detail.sortOrder === SortOrder.Ascending ? -1 : 1;
        
        if (newSortColumn !== this._sortColumn || newSortOrder !== this._sortOrder) {
            this._sortColumn = newSortColumn;
            this._sortOrder = newSortOrder;
            this.loadDataAsync();
        }
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadDataAsync();
    }

    private resetPagination() {
        //this._pageIndex = 1;
        //this._dataGrid.pageIndex = 1;
        //this._dataPagination.pageIndex = 1;
    }

    private refresh() {
        this.loadDataAsync();
    }

    private openLabelsMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-add-labels",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    /*private openAgentOverridesMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({ component: 'se-apply-templates', button: event.currentTarget as HTMLElement, activeProperty: 'active', input: { selectedRows: this._dataGrid.selectedRows, sortOrder: 1 } });
    }*/

    private openAgentTemplatesMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-apply-templates",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private openSharedFilesMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-assign-shared-files",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private filterByLabel(event: CustomEvent) {
        event.stopPropagation();
        //this._userState.selectedLabelId = event.detail.labelId;
        //this._fileterType = this._userState.selectedLabelId > 0 ? "label" : "none";
        //this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._multiSelectLabel.selectedOptions = [event.detail.labelId];
        this.handleLabelChange(true);
    }

    private filterByAgentTemplate(event: CustomEvent) {
        event.stopPropagation();
        //this._fileterType = "template";
        //this._filterId = event.detail.agentTemplateId;
        //this._userState.selectedLabelId = -1;
        //this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        //window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/label/-1/template/${event.detail.agentTemplateId}`);
        //this.loadDataAsync();
        this._multiSelectTemplate.selectedOptions = [event.detail.agentTemplateId];
        this.handleTemplateChange(true);
    }
    private filterBySharedFile(event: CustomEvent) {
        event.stopPropagation();
        this._fileterType = "shared-file";
        this._filterId = event.detail.sharedFileId;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        window.history.pushState("", "", `/space/${this._userState.selectedSpaceId}/label/-1/shared-file/${event.detail.sharedFileId}`);
        this.loadDataAsync();
    }

    private openMoveMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-move-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private openCopyMenu(event: MouseEvent) {
        event.stopPropagation();

        this._menuService.openComponent({
            component: "se-copy-configs",
            button: event.currentTarget as HTMLElement,
            activeProperty: "active",
            input: { selectedRows: this._dataGrid.selectedRows },
        });
    }

    private async validateAgents(event: MouseEvent) {
        event.stopPropagation();
        if (!this._userState.isDraftSpace) {
            const agents = this._dataGrid.selectedRows.filter(
                (p) => p.configType !== ConfigType.SharedFile && (!p.validationStatus || p.validationStatus === ValidationStatus.Unknown)
            );
            const count = agents.length;
            if (count > 0) {
                const result = await this._modalService.openConfirmDialogAsync({
                    title: "Validate Agents",
                    body: `Are you sure you want to validate ${count === 1 ? `${agents[0].name}` : `${count} agents`}?`,
                    saveCaption: "Validate",
                });
                if (result.isSave) {
                    const result = await this._configService.api.validateAllAsync(agents.map((p) => p.id as number));
                    if (result.isOk) {
                        for (let i = 0; i < result.value.length; i++) {
                            if (agents.length > i) {
                                agents[i].validationStatus = result.value[i].validationStatus;
                            }
                        }
                        this._dataGrid.requestUpdate();
                    } else if (result.isErr) {
                        this._toasterService.showNetworkError(result.err);
                    }
                }
            }
        }
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        if (this._userState.isDraftSpace) {
            const count = this._dataGrid.selectedRows.length;
            const result = await this._modalService.openConfirmDialogAsync({
                title: "Delete Drafts",
                body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} drafts`}?`,
                saveCaption: "Delete",
            });
            if (result.isSave) {
                const result = await this._draftService.api.deleteAllAsync(this._dataGrid.selectedRows.map((p) => p.id as number));
                if (result.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this.loadDataAsync();
                } else if (result.isErr) {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        } else {
            const count = this._dataGrid.selectedRows.length;
            const result = await this._modalService.openConfirmDialogAsync({
                title: "Delete Configs",
                body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} configs`}?`,
                saveCaption: "Delete",
            });
            if (result.isSave) {
                const result = await this._configService.api.deleteAllAsync(this._dataGrid.selectedRows.map((p) => p.id as number));
                if (result.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this.loadDataAsync();
                } else if (result.isErr) {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        }
    }
    private onDragOver(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        if (e.dataTransfer.types.length === 1 && e.dataTransfer.types[0] === "Files") this._dataGrid.showActivated(true);
        else e.dataTransfer.dropEffect = "none";
    }
    private onDragLeave(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this._dataGrid.showActivated(false);
    }
    private async onDragDrop(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this._dataGrid.showActivated(false);
        if (e.dataTransfer.files.length === 0) return;
        if (e.dataTransfer.files.length > 1) {
            this._toasterService.showError(
                "You can only upload one file at a time. Bundle files in a zip file and upload the zip file if you need access to multiple files.",
                "Error uploading files"
            );
            return;
        }
        if (e.dataTransfer.items[0]?.webkitGetAsEntry && e.dataTransfer.items[0].webkitGetAsEntry().isDirectory) {
            this._toasterService.showError(
                "You can only upload one file at a time. Bundle files and directories in a zip file and upload the zip file if you need access to multiple files and directories.",
                "Error uploading directory"
            );
            return;
        }
        const file = e.dataTransfer.files[0];
        let size = Math.round(file.size / 1024);
        if (size < 1) size = 1;
        if (size > 52228) {
            this._toasterService.showError(
                `The file is too big (${(size / 1024).toFixed(2)}MB). Files must have a size less than 50MB.", "Error uploading file`
            );
            return;
        }
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Upload File",
            body: `Are you sure you want to upload the file ${e.dataTransfer.files[0].name} (${size.toFixed(2)}KB).`,
            saveCaption: "Upload",
        });
        if (result.isSave) {
            const uploadResult = await this._fileUploadApi.uploadSharedFileAsync(
                file,
                this._userState.selectedSpaceId,
                this._userState.selectedLabelId,
                function () {},
                function () {}
            );
            if (uploadResult.isErr) {
                this._toasterService.showNetworkError(uploadResult.err);
            } else {
                this._toasterService.showSuccess(`${file.name} uploaded successfully.`);
                this.refresh();
            }
        }
    }

    private async getAllRunableIdsAsync() {
        const result =
            this._fileterType === "template"
                ? await this._configApi.getRunableIdsByTemplateAsync(this._userState.selectedSpaceId, this._filterId)
                : await this._configApi.getRunableIdsByLabelAsync(this._userState.selectedSpaceId, this._userState.selectedLabelId);
        if (result.isOk) {
            if (result.value.ids.length > 0) {
                return result.value.ids;
            } else {
                this._toasterService.showError("No selected agents or templates.");
            }
        } else {
            this._toasterService.showNetworkError(result.err);
        }
        return undefined;
    }

    private runMany() {
        this.runManyRows(this._dataGrid.selectedRows);
    }
    private runManyRows(rows) {
        this._userState.selectedIds = rows.filter((p) => p.configType !== ConfigType.SharedFile && isReadyToRun(p.status)).map((m) => m.id);
        if (this._userState.selectedIds.length > 0) {
            Router.go(
                `/space/${this._userState.selectedSpaceId}/label/${this._userState.selectedLabelId}${this._fileterType === "template" ? `/template/${this._filterId}` : ""}/many-runs`
            );
        } else {
            this._toasterService.showError("No selected agents or templates.");
        }
    }
    private async runAllAsync() {
        if (this._fileterType === "shared-file" || this._userState.selectedSpaceId === 0) return;

        if (this._totalRecordCount > (this._data?.length ?? 0)) {
            this._userState.selectedIds = await this.getAllRunableIdsAsync();
            if (this._userState.selectedIds)
                Router.go(
                    `/space/${this._userState.selectedSpaceId}/label/${this._userState.selectedLabelId}${this._fileterType === "template" ? `/template/${this._filterId}` : ""}/many-runs`
                );
        } else if (this._data) {
            this.runManyRows(this._data);
        }
    }
    private async stopManyAsync() {
        await this.stopManyRowsAsync(this._dataGrid.selectedRows);
    }
    private async stopManyRowsAsync(rows) {
        const ids = rows
            .filter((p) => p.configType !== ConfigType.SharedFile && isRunningOnServerOrQueingOrWaiting(p.status))
            .map((m) => m.id);
        if (ids.length > 0) {
            const result = await this._modalService.openConfirmDialogAsync({
                title: "Stop All Agents and Templates",
                body: `Are you sure you want to stop ${ids.length} agents and templates?`,
                saveCaption: `Stop ${ids.length} Agents`,
            });
            if (result.isSave) {
                await this._runApi.stopManyConfigsAsync(ids);
                await this.loadDataAsync();
            }
        } else {
            this._toasterService.showError("No selected agents or templates.");
        }
    }
    private async stopAllAsync() {
        if (this._fileterType === "shared-file") return;

        if (this._totalRecordCount > (this._data?.length ?? 0)) {
            const ids = await this.getAllRunableIdsAsync();
            if (ids) {
                const result = await this._modalService.openConfirmDialogAsync({
                    title: "Stop All Agents and Templates",
                    body: `Are you sure you want to stop ${ids.length} agents and templates?`,
                    saveCaption: `Stop ${ids.length} Agents`,
                });
                if (result.isSave) {
                    await this._runApi.stopManyConfigsAsync(ids);
                    await this.loadDataAsync();
                }
            }
        } else if (this._data) {
            await this.stopManyRowsAsync(this._data);
        }
    }

    private runStatusOptions() {
        const selectedStatuses = this.filters["status"]?.split(",").map(Number) || [];
        const opts = Object.keys(RunStatus)
            .filter((key) => isNaN(Number(key)))
            .map((key) => ({
                id: RunStatus[key as keyof typeof RunStatus],
                name: key.charAt(0).toUpperCase() + key.slice(1),
                value: selectedStatuses.includes(RunStatus[key as keyof typeof RunStatus]),
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
        return opts;
    }

    private runLabelOptions() {
        if (!this._labels) return [];
        const selectedLabels = this.filters["label"]?.split(",").map(Number) || [];
        const opts = this._labels
            .map((key) => ({
                id: key.id,
                name: key.name,
                value: selectedLabels.includes(key.id),
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
        return opts;
    }

    private runTemplateOptions() {
        if (!this._templates) return [];
        const selectedTemplates = this.filters["template"]?.split(",").map(Number) || [];
        const opts = this._templates
            .map((key) => ({
                id: key.id,
                name: key.name,
                value: selectedTemplates.includes(key.id),
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
        return opts;
    }

    updateURLQueryParam(key: string, value: string) {
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        window.history.pushState(null, '', url.toString());
    }

    handleStatusChange(load = true) {
        const selectedOptions: number[] = this._multiSelectStatus.selectedValues;
        this.filters["status"] = selectedOptions.join(",");
        this.updateURLQueryParam("status", this.filters["status"]);
        if (load) this.loadDataAsync(false);
    }
    handleSelectAllStatus(load = true) {
        const selectedOptions: number[] = Object.keys(RunStatus)
            .filter((key) => isNaN(Number(key)))
            .map((key) => RunStatus[key as keyof typeof RunStatus]);
        this.filters["status"] = selectedOptions.join(",");
        this.updateURLQueryParam("status", this.filters["status"]);
        if (load) this.loadDataAsync(false);
    }
    handleClearAllStatus(load = true) {
        const selectedOptions: number[] = [];
        this.filters["status"] = selectedOptions.join(",");
        this.updateURLQueryParam("status", this.filters["status"]);
        if (load) this.loadDataAsync(false);
    }

    handleLabelChange(load = true) {
        this.handleClearAllStatus(false);
        const selectedOptions: number[] = this._multiSelectLabel.selectedValues;
        this.filters["label"] = "";
        this.filters["label"] = selectedOptions.join(",");
        this.updateURLQueryParam("label", this.filters["label"]);
        if (load) this.loadDataAsync(false);
    }
    handleSelectAllLabel(load = true) {
        const selectedOptions: number[] = this._labels?.map((e) => e.id) || [];
        this.filters["label"] = selectedOptions.join(",");
        this.updateURLQueryParam("label", this.filters["label"]);
        if (load) this.loadDataAsync(false);
    }
    handleClearAllLabel(load = true) {
        const selectedOptions: number[] = [];
        this.filters["label"] = selectedOptions.join(",");
        this.updateURLQueryParam("label", this.filters["label"]);
        if (load) this.loadDataAsync(false);
    }

    handleTemplateChange(load = true) {
        const selectedOptions: number[] = this._multiSelectTemplate.selectedValues;
        this.filters["template"] = "";
        this.filters["template"] = selectedOptions.join(",");
        this.updateURLQueryParam("template", this.filters["template"]);
        if (load) this.loadDataAsync(false);
    }
    handleSelectAllTemplate(load = true) {
        const selectedOptions: number[] = this._templates?.map((e) => e.id) || [];
        this.filters["template"] = selectedOptions.join(",");
        this.updateURLQueryParam("template", this.filters["template"]);
        if (load) this.loadDataAsync(false);
    }
    handleClearAllTemplate(load = true) {
        const selectedOptions: number[] = [];
        this.filters["template"] = selectedOptions.join(",");
        this.updateURLQueryParam("template", this.filters["template"]);
        if (load) this.loadDataAsync(false);
    }

    loadDoughnutChart() {
        const blueBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue("--color-status-blue").trim();
        const redBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue("--color-status-red").trim();
        const purpleBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue("--color-purple").trim();
        const grayBackgroundColor = getComputedStyle(document.documentElement).getPropertyValue("--color-gray-4").trim();

        const totalRuns = this._generalRunsStatus.reduce((acc, val) => acc + val, 0);
        const passCount = this._generalRunsStatus[0];
        let passPercentage = ((passCount / totalRuns) * 100).toFixed(2);
        if (passPercentage === "NaN") {
            passPercentage = "0";
        }
        const ctx = this._doughnutChartCanvas;
        if (this.doughnutChart) {
            this.doughnutChart.destroy();
        }
        let labels = ["Run Status"];
        let datasets = [
            {
                label: "Success",
                data: [this._generalRunsStatus[0]],
                backgroundColor: blueBackgroundColor,
                borderWidth: 0,
            },
            {
                label: "Failed",
                data: [this._generalRunsStatus[1]],
                backgroundColor: redBackgroundColor,
                borderWidth: 0,
            },
            {
                label: "In Progress",
                data: [this._generalRunsStatus[3]],
                backgroundColor: purpleBackgroundColor,
                borderWidth: 0,
            },
            {
                label: "Not Run",
                data: [this._generalRunsStatus[2]],
                backgroundColor: grayBackgroundColor,
                borderWidth: 0,
            },
        ];

        const nonZeroDatasets = datasets.filter((ds) => ds.data.some((value) => value !== 0));

        const datasetsData = nonZeroDatasets.map((dataset, index) => {
            let borderRadius;

            const isLast = index === nonZeroDatasets.length - 1;
            const isFirst = index === 0;

            if (isFirst) {
                borderRadius = {
                    topLeft: 3,
                    bottomLeft: 3,
                    topRight: 0,
                    bottomRight: 0,
                };
            } else if (isLast) {
                borderRadius = {
                    topLeft: 0,
                    bottomLeft: 0,
                    topRight: 3,
                    bottomRight: 3,
                };
            } else {
                borderRadius = 0;
            }

            return {
                ...dataset,
                borderRadius: borderRadius,
                barThickness: 25,
                borderSkipped: false,
            };
        });

        const allValuesZero = this._generalRunsStatus.every((value) => value === 0);
        if (allValuesZero) {
            this._generalRunsStatus = [0, 0, 1, 0];
            labels = [""];
            datasets = [
                {
                    label: "Not Run",
                    data: [1],
                    backgroundColor: grayBackgroundColor,
                    borderWidth: 0,
                },
            ];
        }

        this.doughnutChart = new Chart(ctx, {
            type: "bar",
            data: {
                labels: labels,
                datasets: datasetsData,
            },
            options: {
                animation: {
                    duration: this.animateChart ? 1000 : 0, // Set duration to 0 if already animated
                },

                indexAxis: "y",
                scales: {
                    x: {
                        stacked: true,
                        display: false,
                        beginAtZero: true,
                        max: totalRuns,
                    },
                    y: {
                        stacked: true,
                        display: false,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: !allValuesZero,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
            },
        });
    }

    loadTrendsChart() {
        const ctx = this._trendsChartCanvas;
        const blueColor = getComputedStyle(document.documentElement).getPropertyValue("--color-status-blue").trim();
        const redColor = getComputedStyle(document.documentElement).getPropertyValue("--color-status-red").trim();

        const labels = this._last2WeeksData.map((entry) => {
            const date = new Date(entry.date);
            return `${date.getMonth() + 1}/${date.getDate()}`;
        });

        const failCounts = this._last2WeeksData.map((entry) => entry.failCount);
        const passCounts = this._last2WeeksData.map((entry) => entry.passCount);
        //const stackedPassCounts = this._last2WeeksData.map((entry, index) => entry.passCount + failCounts[index]);

        const redBorderRadius = (index) => {
            return failCounts[index] === 0
                ? { topLeft: 3, topRight: 3, bottomLeft: 3, bottomRight: 3 }
                : { topLeft: 3, topRight: 3, bottomLeft: 0, bottomRight: 0 };
        };
        const blueBorderRadius = (index) => {
            return passCounts[index] === 0
                ? { topLeft: 3, topRight: 3, bottomLeft: 3, bottomRight: 3 }
                : { topLeft: 0, topRight: 0, bottomLeft: 3, bottomRight: 3 };
        };

        if (this.trendsChart) {
            this.trendsChart.destroy();
        }
        this.trendsChart = new Chart(ctx, {
            type: "bar",
            data: {
                labels: labels,
                datasets: [
                    {
                        label: "Success Count",
                        data: passCounts,
                        backgroundColor: blueColor,
                        borderColor: blueColor,
                        borderWidth: 0,
                        borderRadius: (context) => blueBorderRadius(context.dataIndex),
                        borderSkipped: false,
                    },
                    {
                        label: "Fail Count",
                        data: failCounts,
                        backgroundColor: redColor,
                        borderColor: redColor,
                        borderWidth: 0,
                        borderRadius: (context) => redBorderRadius(context.dataIndex),
                        borderSkipped: false,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                        ticks: {
                            callback: function (value, index) {
                                return index % 3 === 0 ? labels[index] : "";
                            },
                            color: "black",
                            padding: 10,
                            display: true,
                        },
                        grid: {
                            color: "white",
                            display: true,
                            lineWidth: 1,
                            drawOnChartArea: true,
                            z: 1,
                            drawTicks: true,
                            tickLength: 8,
                            tickWidth: 1,
                            tickColor: "black",
                        },
                        border: {
                            display: true,
                            color: "black",
                            z: 2,
                        },
                    },
                    y: {
                        stacked: true,
                        beginAtZero: true,
                        ticks: {
                            callback: function (value) {
                                return Number.isInteger(value) ? value : "";
                            },
                            color: "black",
                            padding: 10,
                            display: false,
                        },
                        title: {
                            display: false,
                        },
                        grid: {
                            color: "white",
                            display: false,
                            drawTicks: true,
                            tickLength: 8,
                            tickWidth: 1,
                            tickColor: "black",
                        },
                        border: {
                            display: false,
                            color: "black",
                            z: 2,
                        },
                        suggestedMax: Math.max(passCounts) + Math.max(failCounts) + 0,
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        mode: "index",
                        intersect: false,
                        itemSort: (a, b) => b.datasetIndex - a.datasetIndex,
                        callbacks: {
                            label: function (tooltipItem) {
                                if (tooltipItem.datasetIndex === 1) {
                                    return `Fail Count: ${failCounts[tooltipItem.dataIndex]}`;
                                } else {
                                    return `Success Count: ${passCounts[tooltipItem.dataIndex]}`;
                                }
                            },
                        },
                    },
                },
            },
        });
    }

    renderLegend() {
        if (!this._generalRunsStatus) {
            return "";
        }
        const success = this._generalRunsStatus[0];
        const failed = this._generalRunsStatus[1];
        const notRun = this._generalRunsStatus[2];
        const running = this._generalRunsStatus[3];
        const total = success + failed + notRun + running;

        const successPercentage = total > 0 ? Math.round((success / total) * 100) : 0;
        const failedPercentage = total > 0 ? Math.round((failed / total) * 100) : 0;
        const runningPercentage = total > 0 ? Math.round((running / total) * 100) : 0;
        const notRunPercentage = total > 0 ? Math.round((notRun / total) * 100) : 0;

        return html`
            <div class="legend">
                ${success > 0
                    ? html`
                          <div class="legend-item">
                              <div class="circle success"></div>
                              <span class="success">${successPercentage}% </span>&nbsp;Success
                          </div>
                      `
                    : ""}
                ${failed > 0
                    ? html`
                          <div class="legend-item">
                              <div class="circle failed"></div>
                              <span class="failed">${failedPercentage}%</span>&nbsp;Failed
                          </div>
                      `
                    : ""}
                ${running > 0
                    ? html`
                          <div class="legend-item">
                              <div class="circle running"></div>
                              <span class="running">${runningPercentage}%</span>&nbsp;Running
                          </div>
                      `
                    : ""}
                ${notRun > 0
                    ? html`
                          <div class="legend-item">
                              <div class="circle not-run"></div>
                              <span class="not-run">${notRunPercentage}%</span>&nbsp;Not Run
                          </div>
                      `
                    : ""}
            </div>
        `;
    }

    render() {
        const showStopAll =
            !this._hasSelectedRows &&
            this._fileterType !== "shared-file" &&
            !this._userState.isDraftSpace &&
            this._data &&
            this._data.some((m) => isRunningOnServerOrQueingOrWaiting((m as ConfigSummaryViewModel).status)); //It look strange to show stop all if not at least one agent is running in the current pagination page (although it's correct to show the button since there could be agents running on a different page)
        const showStopMany =
            this._hasSelectedRows &&
            !this._userState.isDraftSpace &&
            this._dataGrid.selectedRows.some((m) => isRunningOnServerOrQueingOrWaiting(m.status));
        const showRunMany =
            this._hasSelectedRows &&
            this._dataGrid.selectedRows.some((m) => m.configType !== ConfigType.SharedFile && isReadyToRun(m.status));
        const showRunAll =
            !this._hasSelectedRows &&
            this._userState.selectedSpaceId > 0 &&
            this._data &&
            this._data.some(
                (m) =>
                    (m as ConfigSummaryViewModel).configType !== ConfigType.SharedFile && isReadyToRun((m as ConfigSummaryViewModel).status)
            ); //It look strange to show run all if not at least one agent can be run in the current pagination page (although it's correct to show the button since there could be agents on a different page that could be run)
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="breadcrumbs">
                    <a href="/org/spaces">Spaces</a>
                    <span>/</span>
                    <a disabled href="/space/${this._userState.selectedSpaceId}"
                        >${this._userState.isDraftSpace ? "Drafts" : this._spaceName}</a
                    >
                </div>
                <div class="top-header">
                    <div class="title"><h1>${this._userState.isDraftSpace ? "Drafts" : this._spaceName}</h1></div>
                    <div class="right-header">
                        ${!this._userState.isDraftSpace
                            ? html` <se-dropdown-button
                                  id="newAgentButton"
                                  text="New Agent"
                                  .menu=${this.newMenu()}
                                  .action="${() =>
                                      this._editorService.openEditorAsync({
                                          configType: ConfigType.Agent,
                                          spaceId: this._userState.selectedSpaceId,
                                      })}"
                                  action-text="Opening..."
                                  action-delay="500"
                                  min-sizing="growth"
                              ></se-dropdown-button>`
                            : html``}
                    </div>
                </div>
                ${!this._userState.isDraftSpace
                    ? html` <div class="charts">
                          <se-card chart style="flex: 1;">
                              <div class="left-chart" style="justify-content: space-around;">
                                  <span style="display: flex;">
                                      <div class="count" style="flex: 1;">
                                          <span style="font-size: 2rem; font-weight: 600;">${this._agentCount}</span>
                                          <br />
                                          <span style="color: #000;font-weight: 600;">Total Agents</span>
                                      </div>
                                      ${this._fileCount > 0
                                          ? html`<div class="count" style="flex: 1;">
                                                <span style="font-size: 2rem; font-weight: 600;">${this._fileCount}</span>
                                                <br />
                                                <span style="color: #000;font-weight: 600;">Total Files</span>
                                            </div>`
                                          : html``}
                                      ${this._templatesCount > 0
                                          ? html`<div class="count" style="flex: 1;">
                                                <span style="font-size: 2rem; font-weight: 600;">${this._templatesCount}</span>
                                                <br />
                                                <span style="color: #000;font-weight: 600;">Total Templates</span>
                                            </div>`
                                          : html``}
                                  </span>
                                  <div style="height: calc(20vh - 50px);flex: 1;max-width: 100%;">
                                      <canvas
                                          style="min-height: 50px;max-height: 50px;height: 50px;max-width: 100%;"
                                          id="doughnutChartCanvas"
                                      ></canvas>
                                  </div>
                                  ${this.renderLegend()}
                              </div>
                          </se-card>

                          <se-card chart style="flex: 1;">
                              <div style="margin-bottom: 0;" class="h3">Run History</div>
                              <div class="right-chart" style="width: 100%;min-height: 120px;height: calc(20vh - 50px);padding-top: 15px;">
                                  <canvas style="" id="trendsChartCanvas"></canvas>
                              </div>
                          </se-card>
                      </div>`
                    : html``}

                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${!this._data || this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                                  ${!this._userState.isDraftSpace
                                      ? html`<se-secondary-button
                                                @mousedown=${(event) => this.openLabelsMenu(event)}
                                                ${htmlTitle("Apply labels")}
                                                .customStyle=${{ margin: 0 }}
                                                icon="far fa-tag"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openAgentTemplatesMenu(event)}
                                                ${htmlTitle("Apply templates")}
                                                .customStyle=${{ margin: 0 }}
                                                icon="far fa-cubes"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openSharedFilesMenu(event)}
                                                ${htmlTitle("Assign shared files")}
                                                .customStyle=${{ margin: 0 }}
                                                icon="far fa-file"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openMoveMenu(event)}
                                                ${htmlTitle("Move to...")}
                                                .customStyle=${{ margin: 0 }}
                                                icon="far fa-truck"
                                            ></se-secondary-button>
                                            <se-secondary-button
                                                @mousedown=${(event) => this.openCopyMenu(event)}
                                                ${htmlTitle("Copy to...")}
                                                .customStyle=${{ margin: 0 }}
                                                icon="far fa-copy"
                                            ></se-secondary-button>
                                            ${this._hasSelectedAgentsWithUnknownValidation
                                                ? html`<se-secondary-button
                                                      @mousedown=${(event) => this.validateAgents(event)}
                                                      ${htmlTitle("Validate selected agents.")}
                                                      .customStyle=${{ margin: 0 }}
                                                      icon="far fa-clipboard-check"
                                                  ></se-secondary-button>`
                                                : html``}
                                            ${showRunMany
                                                ? html` <se-secondary-button
                                                      @mousedown=${() => this.runMany()}
                                                      ${htmlTitle("Run all selected agents and templates")}
                                                      .customStyle=${{ margin: 0 }}
                                                      icon="far fa-running"
                                                  ></se-secondary-button>`
                                                : html``}
                                            ${showStopMany
                                                ? html` <se-secondary-button
                                                      @mousedown=${() => this.stopManyAsync()}
                                                      ${htmlTitle("Stop all selected running agents and templates")}
                                                      .customStyle=${{ margin: 0 }}
                                                      icon="far fa-circle-stop"
                                                  ></se-secondary-button>`
                                                : html``} `
                                      : html``}
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                                  ${showRunAll
                                      ? html` <se-secondary-button
                                            @click=${this.runAllAsync}
                                            ${htmlTitle("Run all listed agents and templates")}
                                            .customStyle=${{ margin: 0 }}
                                            icon="far fa-person-running-fast"
                                        ></se-secondary-button>`
                                      : html``}
                                  ${showStopAll
                                      ? html` <se-secondary-button
                                            @click=${this.stopAllAsync}
                                            ${htmlTitle("Stop all running agents and templates")}
                                            .customStyle=${{ margin: 0 }}
                                            icon="far fa-circle-stop"
                                        ></se-secondary-button>`
                                      : html``}
                                  ${!this._userState.isDraftSpace
                                      ? html`
                                            <se-multi-select-editor
                                                id="multiSelectStatus"
                                                @valueChanged=${this.handleStatusChange}
                                                @selectAll=${this.handleSelectAllStatus}
                                                @clearAll=${this.handleClearAllStatus}
                                                .isSelectAll=${true}
                                                .optionsName=${"Status"}
                                                .options=${this.runStatusOptions()}
                                            >
                                            </se-multi-select-editor>
                                        `
                                      : html``}
                                  ${this._labels && this._labels.length > 0
                                      ? html` <se-multi-select-editor
                                            id="multiSelectLabel"
                                            @valueChanged=${this.handleLabelChange}
                                            @selectAll=${this.handleSelectAllLabel}
                                            @clearAll=${this.handleClearAllLabel}
                                            .isSelectAll=${true}
                                            .optionsName=${"Label"}
                                            .options=${this.runLabelOptions()}
                                        >
                                        </se-multi-select-editor>`
                                      : html``}
                                  ${this._templates && this._templates.length > 0
                                      ? html` <se-multi-select-editor
                                            id="multiSelectTemplate"
                                            @valueChanged=${this.handleTemplateChange}
                                            @selectAll=${this.handleSelectAllTemplate}
                                            @clearAll=${this.handleClearAllTemplate}
                                            .isSelectAll=${true}
                                            .optionsName=${"Template"}
                                            .options=${this.runTemplateOptions()}
                                        >
                                        </se-multi-select-editor>`
                                      : html``}
                              `}
                    </div>
                    <div style="display:flex;">
                        <se-space-search-editor></se-space-search-editor>
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    .defaultSorted=${this._sortColumn}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    @filterbylabel=${this.filterByLabel}
                    @filterByAgentTemplate=${this.filterByAgentTemplate}
                    @filterBySharedFile=${this.filterBySharedFile}
                    @dragover=${this._userState.selectedSpaceId >= 0 ? this.onDragOver : null}
                    @dragleave=${this._userState.selectedSpaceId >= 0 ? this.onDragLeave : null}
                    @drop=${this._userState.selectedSpaceId >= 0 ? this.onDragDrop : null}
                    placeholder="No data available."
                    .isLoading=${this._isLoading}
                    .syncWithUrl=${true}
                ></se-data-grid>
                <se-pagination 
                .recordCount=${this._totalRecordCount}
                .recordsPerPage=${this._recordsPerPage}
                @pagechanged=${this.onPageChanged}
                .syncWithUrl=${true}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
            padding: 15px;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: var(--color-primary);
        }
        .top-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }
        .right-header {
            margin-bottom: 7px;
        }
        .header {
            margin-left: 15px;
            display: flex;
            justify-content: space-between;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        }

        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .charts {
            display: flex;
            gap: 15px;
            min-height: 190px;
        }
        .left-chart {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .right-chart {
            display: flex;
            justify-content: space-around;
            align-items: center;
        }
        .h3 {
            font-size: 1.5em;
            font-weight: 600;
        }
        .card-container {
            min-height: 0;
            overflow: hidden;
            padding: 10px 15px 20px 15px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        }

        .legend {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 8px;
        }

        .legend-item {
            display: flex;
            align-items: center;
            flex-grow: 1;
        }

        .legend-item .circle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 20px;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a {
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover {
            text-decoration: underline;
        }
        h1 {
            margin: 10px 0px 5px;
            font-weight: 600;
        }
        .circle.success {
            background-color: var(--color-status-blue);
        }

        .circle.failed {
            background-color: var(--color-status-red);
        }

        .circle.not-run {
            background-color: var(--color-gray-4);
        }

        .circle.running {
            background-color: var(--color-purple);
        }

        span.success {
            color: var(--color-status-blue);
        }

        span.failed {
            color: var(--color-status-red);
        }

        span.not-run {
            color: var(--color-gray-4);
        }

        span.running {
            color: var(--color-purple);
        }
        .status {
            margin: 5px 10px;
        }
    `;
}
