import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-secondary-button")
export class SeSecondaryButton extends LitElement {
    @property() icon: string;
    @property() iconColor: string;
    @property() title: string;
    @property() text: string;
    @property({ attribute: "action-delay", type: Number }) actionDelay = 0;
    @property({ attribute: "action-text" }) actionText: string;
    @property({ attribute: "action-title" }) actionTitle: string;
    @property({ attribute: "stop-action-text" }) stopActionText: string;
    @property({ type: Boolean, attribute: "can-stop" }) canStop = false;
    @property({ type: Boolean, attribute: "red-hover" }) redHover = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) active = false;
    @property({ type: Object }) customStyle: object;
    @property({ attribute: "size" }) size: "small" | "nomal";
    @property({ attribute: "position" }) position: "first" | "right" = "right";
    @property({ attribute: "min-sizing" }) minSizing: "auto" | "growth" = "auto";
    @property({ type: Object }) action?: (isStop?: boolean) => Promise<boolean>;
    @property({ attribute: "base-color" }) baseColor: "default" | "green" | "red" = "default";

    @property({ type: Object }) lockHtml: TemplateResult;

    @state() private _isAction = false;
    @state() private _isActionDisplay = false;
    @state() private _isStopAction = false;
    @state() private _isActionDisabled = false;

    @state() private _minWidth?: string;

    @query("button") private _buttonElement: HTMLButtonElement;

    connectedCallback() {
        super.connectedCallback();
        addEventListener("pageshow", () => this.reset());
    }

    disconnectedCallback() {
        this.removeEventListener("pageshow", () => this.reset());
        super.disconnectedCallback();
    }

    private reset() {
        this._isAction = false;
        this._isActionDisplay = false;
        this._isStopAction = false;
        this._isActionDisabled = false;
    }

    get isAction() {
        return this._isAction;
    }

    async onClick(ev: MouseEvent) {
        if (this.action) {
            ev.preventDefault();
            if (this._isAction) this._isStopAction = true;
            else {
                this._isAction = true;
                this._minWidth = this.minSizing === "auto" ? undefined : this._buttonElement.offsetWidth + "px";
            }
            this._isActionDisabled = !this.canStop || this.actionDelay > 0 || this._isStopAction;
            await this.updateComplete;
            const timeout =
                this.actionDelay > 0
                    ? setTimeout(() => {
                          this._isActionDisplay = true;
                          this._isActionDisabled = !this.canStop || this._isStopAction;
                      }, this.actionDelay)
                    : undefined;
            if (!(await this.action(this._isStopAction))) { //action returns true if the website has redirected to a new page or false if stayed on the current page.
                this._isAction = false;
                this._isStopAction = false;
                this._isActionDisabled = false;
                this._isActionDisplay = false;
            }   
            if (timeout) {
                clearTimeout(timeout);
            }
        } else {
            const event = new Event("buttonclick", { bubbles: true, composed: true });
            this.dispatchEvent(event);
        }
    }

    render() {
        if ((this.icon || this._isActionDisplay) && this.text)
            return html`
                <button
                    ?disabled=${this.disabled || this._isActionDisabled || this.lockHtml !== undefined}
                    @click="${this.onClick}"
                    class="button ${classMap({ 
                        red: this.redHover, 
                        active: this.active, 
                        right: this.position === "right",
                        'base-color-red': this.baseColor === "red",
                        'base-color-green': this.baseColor === "green"
                    })}"
                    type="button"
                    title="${this._isActionDisplay ? (this._isStopAction ? "Please wait..." : this.actionTitle ?? this.title) : this.title}"
                    style="display: flex; align-items: center;justify-content: center; gap: 5px;${styleMap({
                        ...this.customStyle,
                        font: this.size === "small" ? "var(--font-button-small)" : "var(--font-button)",
                        minWidth: this._minWidth,
                    })};"
                >
                    ${this.lockHtml
                        ? this.lockHtml
                        : html`<fa-icon
                              style="font-size:0.9em"
                              fa-class="${this._isActionDisplay ? "far fa-spinner fa-spin" : this.icon}"
                              single-color="${this._isActionDisplay ? "crimson" : this.iconColor}"
                              scale="0.9"
                          ></fa-icon>`}<span
                        >${this._isActionDisplay
                            ? this._isStopAction
                                ? this.stopActionText ?? "Stopping..."
                                : this.actionText ?? this.text ?? ""
                            : this.text ?? ""}</span
                    >
                </button>
            `;
        else if (this.icon || this._isActionDisplay)
            return html`
                <button
                    ?disabled=${this.disabled || this._isActionDisabled || this.lockHtml !== undefined}
                    @click="${this.onClick}"
                    class="button ${classMap({ 
                        red: this.redHover, 
                        active: this.active, 
                        right: this.position === "right",
                        'base-color-red': this.baseColor === "red",
                        'base-color-green': this.baseColor === "green"
                    })}"
                    type="button"
                    title="${this._isActionDisplay ? (this._isStopAction ? "Please wait..." : this.actionTitle ?? this.title) : this.title}"
                    style="${styleMap({
                        ...this.customStyle,
                        font: this.size === "small" ? "var(--font-button-small)" : "var(--font-button)",
                        minWidth: this._minWidth,
                    })}; gap: 5px;"
                >
                    ${this.lockHtml}<fa-icon
                        style="font-size:0.9em"
                        fa-class="${this._isActionDisplay ? (this.canStop ? "fad fa-stop" : "far fa-spinner fa-spin") : this.icon}"
                        single-color="${this._isActionDisplay ? "crimson" : this.iconColor}"
                        scale="0.9"
                    ></fa-icon>
                </button>
            `;
        else
            return html`
                <button
                    style=${styleMap({
                        ...this.customStyle,
                        font: this.size === "small" ? "var(--font-button-small)" : "var(--font-button)",
                        minWidth: this._minWidth,
                    })}
                    ?disabled=${this.disabled || this._isActionDisabled || this.lockHtml !== undefined}
                    @click="${this.onClick}"
                    class="button ${classMap({ 
                        red: this.redHover, 
                        active: this.active, 
                        right: this.position === "right",
                        'base-color-red': this.baseColor === "red",
                        'base-color-green': this.baseColor === "green"
                    })}"
                    type="button"
                    title="${this._isActionDisplay ? (this._isStopAction ? "Please wait..." : this.actionTitle ?? this.title) : this.title}"
                >
                    ${this.lockHtml}${this.text}
                </button>
            `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        .button {
            padding: 5px;
            border-radius: 3px 3px;
            transition: all 0.25s;
            background-color: white;
            border: 1px solid white;
            color: black;
            font: var(--font-button);
            user-select: none;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
        }
        .button:disabled {
            opacity: 1;
            background-color: var(--primary-color);
            border: 1px solid lightgray;
            color: gray;
            transition: none;
        }
        .button:hover {
            background-color: white;
            box-shadow: 2px 2px 2px lightgray;
        }
        .button.red:hover {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }
        .button.base-color-red {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }
        .button.base-color-green {
            background-color: #28a745;
            color: white;
            border: 1px solid #28a745;
        }
        .button.base-color-red:hover,
        .button.base-color-green:hover {
            filter: brightness(110%);
        }
        .button:hover:disabled {
            background-color: var(--primary-color);
            border: 1px solid lightgray;
            box-shadow: none;
        }
        .button:active {
            background-color: white;
            box-shadow: none;
        }
        .button.red:active {
            background-color: crimson;
            color: white;
            border: 1px solid crimson;
        }
        .button.active {
            background-color: white;
            box-shadow: none;
        }
        .right {
            margin-left: 5px;
        }
    `;
}
