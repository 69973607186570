import { css, html, LitElement } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { SubscriptionPlan } from "../../../models/subscription-plan-view-model";
import { FormInputEditorElement } from "../../editors/form/form-input-editor.element";
import { FormToggleEditorElement } from "../../editors/form/form-toggle-editor.element";
import "../cards/edit-form-card.element";
import "../cards/card-buttons.element";

@customElement("se-subscription-plan-editor-form")
export class SeSubscriptionPlanEditorFormElement extends LitElement {
    @property({ type: Boolean }) isNew = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Object }) subscriptionPlan: SubscriptionPlan;
    @property({ type: Function }) onSave: (
        name: string, 
        price: number, 
        runTime: number, 
        exportGb: number, 
        exportedRows: number,
        proxyData: number,
        isActive: boolean
    ) => Promise<void>;
    @property({ type: Function }) onCancel: () => void;

    @query("#name") private _nameEditor: FormInputEditorElement;
    @query("#price") private _priceEditor: FormInputEditorElement;
    @query("#runTime") private _runTimeEditor: FormInputEditorElement;
    @query("#exportGb") private _exportGbEditor: FormInputEditorElement;
    @query("#proxyData") private _proxyDataEditor: FormInputEditorElement;
    @query("#isActive") private _isActiveEditor: FormToggleEditorElement;

    private _handleSave = async (ev: Event) => {
        ev.preventDefault();

        // Reset any custom validation messages
        this._nameEditor.setCustomValidity('');
        this._priceEditor.setCustomValidity('');
        this._runTimeEditor.setCustomValidity('');
        this._exportGbEditor.setCustomValidity('');
        this._proxyDataEditor.setCustomValidity('');

        // Validate name - cannot be just a space
        const name = this._nameEditor.liveValue;
        if (!name || name.trim() === '') {
            this._nameEditor.setCustomValidity('Name cannot be empty or just whitespace');
            this.reportValidity();
            return;
        }

        // Validate name length - must be at least 4 characters
        if (name.length < 4) {
            this._nameEditor.setCustomValidity('Name must be at least 4 characters');
            this.reportValidity();
            return;
        }

        // Validate numeric fields
        const fieldsToCheck = [
            { editor: this._priceEditor, name: 'Price' },
            { editor: this._runTimeEditor, name: 'Run Usage' },
            { editor: this._exportGbEditor, name: 'Exported Data' },
            { editor: this._proxyDataEditor, name: 'Proxy Usage' }
        ];

        for (const field of fieldsToCheck) {
            const value = field.editor.liveValue;
            const numValue = Number(value);

            // Check for invalid number format (like '3..7')
            if (isNaN(numValue)) {
                field.editor.setCustomValidity(`${field.name} has an invalid format. Please enter a valid number.`);
                this.reportValidity();
                return;
            }

            // Check for infinity
            if (!isFinite(numValue)) {
                field.editor.setCustomValidity(`${field.name} has an invalid value. Please enter a valid number.`);
                this.reportValidity();
                return;
            }

            // Check for negative values
            if (numValue < 0) {
                field.editor.setCustomValidity(`${field.name} cannot be negative. Please enter a positive value.`);
                this.reportValidity();
                return;
            }

            // Check for values exceeding maximum
            if (numValue > 100000) {
                field.editor.setCustomValidity(`${field.name} value is too large. The maximum allowed value is 100000.`);
                this.reportValidity();
                return;
            }
        }

        if (this.reportValidity()) {
            const price = parseFloat(this._priceEditor.liveValue);
            const runTime = parseFloat(this._runTimeEditor.liveValue);
            const exportGb = parseFloat(this._exportGbEditor.liveValue);
            const proxyData = parseFloat(this._proxyDataEditor.liveValue);
            const isActive = this._isActiveEditor.liveValue;
            await this.onSave(name, price, runTime, exportGb, 0, proxyData, isActive);
        }
    };

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as any)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    render() {
        return html`
            <se-edit-form-card
                cardTitle="${this.isNew ? 'Add' : 'Edit'} Subscription Plan"
            >
                <se-form-input-editor
                    id="name"
                    name="name"
                    type="text"
                    label="Plan Name"
                    labelHelp="Name of the subscription plan"
                    size="50"
                    required
                    .disabled=${this.disabled}
                    .value=${this.subscriptionPlan?.name ?? ""}
                ></se-form-input-editor>
                
                <se-form-input-editor
                    id="price"
                    name="price"
                    type="number"
                    label="Monthly Price"
                    labelHelp="Monthly subscription price"
                    required
                    min="0"
                    nonNegative
                    step="0.01"
                    .disabled=${this.disabled}
                    .value=${this.subscriptionPlan?.price?.toString() ?? "0"}
                ></se-form-input-editor>

                <se-form-input-editor
                    id="runTime"
                    name="runTime"
                    type="number"
                    label="Server Time"
                    labelHelp="Price per Hour of Server Run Time"
                    required
                    min="0"
                    nonNegative
                    step="1"
                    .disabled=${this.disabled}
                    .value=${this.subscriptionPlan?.runUsage?.toString() ?? "0"}
                ></se-form-input-editor>

                <se-form-input-editor
                    id="exportGb"
                    name="exportGb"
                    type="number"
                    label="Export GB"
                    labelHelp="Price per GB of exported data"
                    required
                    min="0"
                    nonNegative
                    step="0.1"
                    .disabled=${this.disabled}
                    .value=${this.subscriptionPlan?.exportedData?.toString() ?? "0"}
                ></se-form-input-editor>

                <se-form-input-editor
                    id="proxyData"
                    name="proxyData"
                    type="number"
                    label="Proxy Data"
                    labelHelp="Price per GB of proxy data usage"
                    required
                    min="0"
                    nonNegative
                    step="0.1"
                    .disabled=${this.disabled}
                    .value=${this.subscriptionPlan?.proxyUsage?.toString() ?? "0"}
                ></se-form-input-editor>

                <se-form-toggle-editor
                    id="isActive"
                    name="isActive"
                    label="Active"
                    labelHelp="Whether this plan should be visible and available for purchase"
                    .disabled=${this.disabled}
                    .value=${this.subscriptionPlan?.isActive ?? true}
                ></se-form-toggle-editor>
            </se-edit-form-card>
            <se-card-buttons>
                <se-primary-button 
                    @click="${this._handleSave}" 
                    text="${this.isNew ? 'Add' : 'Save'} Plan"
                ></se-primary-button>
                <se-secondary-button 
                    @click="${this.onCancel}" 
                    text="Cancel"
                ></se-secondary-button>
            </se-card-buttons>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
} 