import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import sanitizeHtml from "sanitize-html";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ConfigType } from "../../enums/config-type";
import { ValidationStatus } from "../../enums/validation-status";
import { AgentDisplayInfo, AgentDisplayInfoTracking, SuccessCriteria } from "../../models/config-display-info";
import { ConfigStats } from "../../models/config-info-model";
import { DisplaySchema, DisplaySchemaTable } from "../../models/display-schema";
import { AuthService } from "../../services/auth.service";
import { ConfigService } from "../../services/config.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { RunService } from "../../services/run.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import "../components/secondary-button.element";
import { InputEditorElement } from "../editors/input-editor.element";
import { TextAreaEditorElement } from "../editors/textarea-editor.element";
import "./agent-details.element";

@customElement("se-agent-info")
export class SeAgentInfoElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _runService: RunService;
    private _userState: UserState;
    private _configService: ConfigService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];

    @property({ type: Number }) configId: number;
    @property({ attribute: false }) validationStatus: ValidationStatus;

    @state() private _name: string;
    @state() private _description?: string;
    @state() private _documentation?: string;
    @state() private _icon?: string;
    @state() private _imageAuth?: string;
    @state() private _startUrl?: string;
    @state() private _version?: number;
    @state() private _updated?: Date;
    @state() private _created?: Date;
    @state() private _trackingSize?: number;
    @state() private _configType?: ConfigType;
    private _configDisplay: AgentDisplayInfo;
    @state() private _schema?: DisplaySchema;
    private _schemaTables: DisplaySchemaTable[];
    @state() private _schemaTable: DisplaySchemaTable;
    @state() private _selectedSchemaTable: DisplaySchemaTable;
    @state() private _tracking?: AgentDisplayInfoTracking;
    @state() private _isEditDescription = false;
    @state() private _isEditDocumentation = false;
    @state() private _isEditIcon = false;
    private _editorDescriptionSaving = false;
    private _editorDocumentationSaving = false;
    private _editorIconSaving = false;
    private _stats?: ConfigStats;
    private _successCriteria?: SuccessCriteria;

    @query("#description") private _descriptionEditor: TextAreaEditorElement;
    @query("#documentation") private _documentationEditor: InputEditorElement;
    @query("#icon") private _iconEditor: InputEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._runService = container.resolve(RunService);
        this._menuService = container.resolve(MenuService);
        this._configService = container.resolve(ConfigService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadDataAsync();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isUser) {
            return commands.redirect("/login");
        }
        if (location.params.configId) {
            this.configId = parseInt(location.params.configId.valueOf() as string);
        } else {
            return commands.prevent();
        }
    }

    firstUpdated() {
        const options = {
            rootMargin: "0px",
            threshold: 1.0,
        };
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach((entry) => {
                if (entry.intersectionRatio > 0 && !this._name) {
                    this.onRefreshAsync();
                }
            });
        }, options);
        observer.observe(this);
    }

    private async onRefreshAsync() {
        const info = await this._configService.api.getConfigInfo(this.configId);
        if (info.isOk) {
            this._name = info.value.name;
            this._description = info.value.description == null ? null : sanitizeHtml(info.value.description.replace(/\n/g, "<br>"));
            this._documentation = info.value.documentation;
            this._icon = info.value.icon;
            this._imageAuth = info.value.imageAuth;
            this._startUrl = info.value.startUrl;
            this._configType = info.value.configType;
            this._version = info.value.version;
            this._updated = info.value.updated;
            this._created = info.value.created;
            this._trackingSize = info.value.trackingSize;
            const validationStatus = info.value.validationStatus ? info.value.validationStatus : ValidationStatus.Unknown;
            this._stats = info.value.stats;
            if (info.value.displayJson) {
                this._configDisplay = JSON.parse(info.value.displayJson);
                this._schema = this._configDisplay.schema;
                this._schemaTables = this.setSchemaTables();
                this._selectedSchemaTable = this._schemaTables[0];
                this._tracking = this._configDisplay.tracking as AgentDisplayInfoTracking;
                this._successCriteria = this._configDisplay.successCriteria;
            } else {
                this._configDisplay = undefined;
                this._schema = undefined;
                this._schemaTables = [];
            }
            if (this.validationStatus != validationStatus) {
                this.validationStatus = validationStatus;
                this.dispatchEvent(
                    new CustomEvent("agentValidationStatusChanged", {
                        bubbles: true,
                        composed: true,
                        detail: { validationStatus: this.validationStatus },
                    })
                );
            }
        } else {
            this._toasterService.showNetworkError(info.err);
        }
    }

    private async loadDataAsync() {
        this._columns = [
            {
                field: "name",
                title: "Name",
                template: (row?: any) =>
                    row.error
                        ? html`<span style="color: crimson;text-decoration: underline dotted;cursor:pointer;" ${htmlTitle(row.error, true)}
                              >${row["name"] ?? ""}</span
                          >`
                        : html`${row["name"] ?? ""}`,
            },
            {
                field: "contentType",
                title: "Content Type",
            },
            { field: "isAllowEmpty", title: "Allow Empty", align: "center", checkbox: true },
            { field: "formatStyle", title: "Format Style" },
            { field: "format", title: "Format" },
            { field: "timezone", title: "Time Zone" },
        ];
    }

    private editDescription() {
        this._isEditDescription = true;
    }

    private editDocumentation() {
        this._isEditDocumentation = true;
    }

    private editIcon() {
        this._isEditIcon = true;
    }

    private setSchemaTables(): DisplaySchemaTable[] {
        const validatedSchemaTables: DisplaySchemaTable[] = [];
        this.setSchemaTable(validatedSchemaTables, this._schema.table);
        return validatedSchemaTables;
    }
    private setSchemaTable(tables: DisplaySchemaTable[], table: DisplaySchemaTable) {
        tables.push(table);
        for (const childTable of table.tables) {
            this.setSchemaTable(tables, childTable);
        }
    }

    private async onEditSaveDescriptionAsync() {
        const result = await this._configService.api.updateDescriptionAsync(this.configId, this._descriptionEditor.liveValue);
        if (result.isOk) {
            this._description = sanitizeHtml(this._descriptionEditor.liveValue.replace(/\n/g, "<br>"));
            this._isEditDescription = false;
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    /**
     * Save the documentation changes
     *
     * @param event
     */
    private async onEditSaveDocumentationAsync() {
        const result = await this._configService.api.updateDocumentationAsync(this.configId, this._documentationEditor.liveValue);
        if (result.isOk) {
            this._documentation = sanitizeHtml(this._documentationEditor.liveValue.replace(/\n/g, "<br>"));
            this._isEditDocumentation = false;
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    /**
     * Save the icon changes
     *
     * @param event
     */
    private async onEditSaveIconAsync() {
        const result = await this._configService.api.updateIconAsync(this.configId, this._iconEditor.liveValue);
        if (result.isOk) {
            this._icon = sanitizeHtml(this._iconEditor.liveValue.replace(/\n/g, "<br>"));
            this._isEditIcon = false;
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    onEditCancel() {
        this._isEditDescription = false;
        this._isEditDocumentation = false;
        this._isEditIcon = false;
    }

    private selectDisplayTable(table: DisplaySchemaTable) {
        this._selectedSchemaTable = table;
    }

    private async resetTrackingAsync() {
        const result = await this._modalService.openConfirmDialogAsync({
            title: `Reset tracking for ${this._name}`,
            body: `Are you sure you want to reset tracking for ${this._name}? <br><br>This will permanently delete all tracking data and the next agent run will run as if the agent had never been run before.`,
            saveCaption: "Reset",
        });
        if (result.isSave) {
            const result = await this._configService.api.resetExportTrackingAsync(this.configId);
            if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            } else {
                this._toasterService.showSuccess("Tracking has been reset.");
                this._trackingSize = undefined;
            }
        }
    }
    private onDownloadTracking() {
        this._configService.api.downloadTracking(this.configId);
    }

    private async resetSuccessCriteriaAsync() {
        const result = await this._modalService.openConfirmDialogAsync({
            title: `Reset actual values for ${this._name}`,
            body: `Are you sure you want to reset actual values for ${this._name}? <br><br>This will cause all variation checks to succeed at the next run.`,
            saveCaption: "Reset",
        });
        if (result.isSave) {
            const result = await this._configService.api.resetSuccessCriterisAsync(this.configId);
            if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            } else {
                this._toasterService.showSuccess("Success criteria has been reset.");
                this.onRefreshAsync();
            }
        }
    }

    private addString(value: number, add: string) {
        return value ? value.toString() + add : value;
    }

    render() {
        const displayRows = this._selectedSchemaTable ? this._selectedSchemaTable.fields : [];
        const displayTables = this._schemaTables ? this._schemaTables : [];

        return html`
            <div class="body">
                <div style="display:flex; gap: 30px; min-width: 0;">
                    <div style="display:flex; flex-direction:column; min-width: 0; flex: 1;">
                        <h2 style="margin-top:0">${this._name}</h2>
                        ${this._startUrl
                            ? html`<div>
                                  <a
                                      href="${this._startUrl.indexOf("://") > 0 ? this._startUrl : "http://" + this._startUrl}"
                                      target="_blank"
                                      style="word-break:break-all;"
                                      >${this._startUrl}</a
                                  >
                              </div>`
                            : html``}
                        <div class="prop">
                            <span class="prop-name">Type:</span
                            ><span
                                >${choose(this._configType, [
                                    [ConfigType.Agent, () => html`<span>Agent</span>`],
                                    [ConfigType.Template, () => html`<span>Agent Template</span>`],
                                    [ConfigType.SharedFile, () => html`<span>Input Data</span>`],
                                ])}</span
                            >
                            <span class="prop-name">Version:</span><span>${this._version}</span> <span class="prop-name">Updated:</span
                            ><span>${new Date(this._updated).toLocaleDateString()}</span> <span class="prop-name">Created:</span
                            ><span>${new Date(this._created).toLocaleDateString()}</span>
                        </div>
                        <h3 style="margin-top:20px">Description</h3>
                        ${this._isEditDescription
                            ? html`
                                  <se-textarea-editor
                                      id="description"
                                      style="margin-top:0px;"
                                      .value=${this._description}
                                      rows="4"
                                      placeholder="Enter description"
                                  ></se-textarea-editor>
                                  <div class="editor-div">
                                      <button
                                          class="button"
                                          ${htmlTitle("Save")}
                                          ?disabled=${this._editorDescriptionSaving}
                                          @click=${() => this.onEditSaveDescriptionAsync()}
                                      >
                                          <fa-icon fa-class="far fa-check"></fa-icon>
                                      </button>
                                      <button
                                          class="button"
                                          ${htmlTitle("Cancel")}
                                          ?disabled=${this._editorDescriptionSaving}
                                          @click=${() => this.onEditCancel()}
                                      >
                                          <fa-icon fa-class="far fa-times"></fa-icon>
                                      </button>
                                  </div>
                              `
                            : this._description
                              ? html` <div style="position:relative;margin-top:0px">
                                    <div class="edit-div">
                                        ${unsafeHTML(this._description)}
                                        <button
                                            ?disabled=${this._editorDescriptionSaving}
                                            class="button edit-icon"
                                            @mousedown=${this.editDescription}
                                            ${htmlTitle("Edit Description")}
                                        >
                                            <fa-icon fa-class="far fa-pencil"></fa-icon>
                                        </button>
                                    </div>
                                </div>`
                              : html` <div style="height:40px;padding-top:20px;border: 1px dashed Gainsboro;text-align: center;">
                                    <a style="font-size:0.75em;color:gray" href="javascript:;" @click=${this.editDescription}
                                        >Add description</a
                                    >
                                </div>`}
                        ${this._authService.isSE4Admin
                            ? html` <h3 style="margin-top:20px">Documentation</h3>
                                  <div style="margin-bottom:10px">
                                      A link to a confluence article that gives instructions on how to run or configure the Agent
                                  </div>
                                  ${this._isEditDocumentation
                                      ? html`
                                            <se-input-editor
                                                id="documentation"
                                                style="margin-top:0px;"
                                                .value=${this._documentation}
                                                placeholder="Enter documentation link"
                                                width="100%"
                                            ></se-input-editor>
                                            <div class="editor-div">
                                                <button
                                                    class="button"
                                                    ${htmlTitle("Save")}
                                                    ?disabled=${this._editorDocumentationSaving}
                                                    @click=${() => this.onEditSaveDocumentationAsync()}
                                                >
                                                    <fa-icon fa-class="far fa-check"></fa-icon>
                                                </button>
                                                <button
                                                    class="button"
                                                    ${htmlTitle("Cancel")}
                                                    ?disabled=${this._editorDocumentationSaving}
                                                    @click=${() => this.onEditCancel()}
                                                >
                                                    <fa-icon fa-class="far fa-times"></fa-icon>
                                                </button>
                                            </div>
                                        `
                                      : this._documentation
                                        ? html` <div style="position:relative;margin-top:0px">
                                              <div class="edit-div">
                                                  ${unsafeHTML(this._documentation)}
                                                  <button
                                                      ?disabled=${this._editorDocumentationSaving}
                                                      class="button edit-icon"
                                                      @mousedown=${this.editDocumentation}
                                                      ${htmlTitle("Edit Documentation Link")}
                                                  >
                                                      <fa-icon fa-class="far fa-pencil"></fa-icon>
                                                  </button>
                                              </div>
                                          </div>`
                                        : html` <div style="height:40px; padding-top:20px;border: 1px dashed Gainsboro;text-align: center;">
                                              <a style="font-size:0.75em;color:gray" href="javascript:;" @click=${this.editDocumentation}
                                                  >Add documentation link</a
                                              >
                                          </div>`}
                                  <h3 style="margin-top:20px">Icon</h3>
                                  ${this._isEditIcon
                                      ? html`
                                            <se-input-editor
                                                id="icon"
                                                style="margin-top:0px;"
                                                .value=${this._icon}
                                                placeholder="Enter icon Data URL"
                                                width="100%"
                                            ></se-input-editor>
                                            <div class="editor-div">
                                                <button
                                                    class="button"
                                                    ${htmlTitle("Save")}
                                                    ?disabled=${this._editorIconSaving}
                                                    @click=${() => this.onEditSaveIconAsync()}
                                                >
                                                    <fa-icon fa-class="far fa-check"></fa-icon>
                                                </button>
                                                <button
                                                    class="button"
                                                    ${htmlTitle("Cancel")}
                                                    ?disabled=${this._editorIconSaving}
                                                    @click=${() => this.onEditCancel()}
                                                >
                                                    <fa-icon fa-class="far fa-times"></fa-icon>
                                                </button>
                                            </div>
                                        `
                                      : this._icon
                                        ? html` <div style="position:relative;margin-top:0px">
                                              <div class="edit-div">
                                                  <img src=${this._icon} style="width:100px;height:100px" />
                                                  <button
                                                      ?disabled=${this._editorIconSaving}
                                                      class="button edit-icon"
                                                      @mousedown=${this.editIcon}
                                                      ${htmlTitle("Edit Icon Data URL")}
                                                  >
                                                      <fa-icon fa-class="far fa-pencil"></fa-icon>
                                                  </button>
                                              </div>
                                          </div>`
                                        : html` <div style="height:40px; padding-top:20px;border: 1px dashed Gainsboro;text-align: center;">
                                              <a style="font-size:0.75em;color:gray" href="javascript:;" @click=${this.editIcon}
                                                  >Add icon data url</a
                                              >
                                          </div>`}`
                            : html``}
                        ${this._successCriteria
                            ? html`<h3 style="margin-top:20px">Success Criteria</h3>
            <table style="border-collapse:collapse;" >
                <tr class="sc-row"><td></th><td class="sc-right">Threshold</th><td class="sc-right">Allowed Variation</th><td class="sc-right">Actual</th></tr>
                <tr class="sc-row"><td class="sc-name">Min. page loads</td><td class="sc-right">${
                    this._successCriteria.minPageLoads
                }</td><td class="sc-right">${this.addString(
                    this._successCriteria.allowedPageLoadVariation,
                    "%"
                )}</td><td class="sc-right">${this._stats?.pageCount}</td></tr>
                             <tr class="sc-row"><td class="sc-name">Min. data count</td><td class="sc-right">${
                                 this._successCriteria.minDataCount
                             }</td><td class="sc-right">${this.addString(
                                 this._successCriteria.allowedDataCountVariation,
                                 "%"
                             )}</td><td class="sc-right">${this._stats?.dataCount}</td></tr>
                <tr class="sc-row"><td class="sc-name">Min. export count</td><td class="sc-right">${
                    this._successCriteria.minExportCount
                }</td><td class="sc-right">${this.addString(
                    this._successCriteria.allowedExportCountVariation,
                    "%"
                )}</td><td class="sc-right">${this._stats?.exportCount}</td></tr>
                <tr class="sc-row"><td class="sc-name">Max. error count</td><td class="sc-right">${
                    this._successCriteria.maxErrors
                }</td><td class="sc-right">${this.addString(this._successCriteria.allowedErrorVariation, "%")}</td><td class="sc-right">${
                    this._stats?.errorCount
                }</td></tr>
            </table>`
                            : html``}
                        ${this._stats
                            ? html`<div style="display:flex;flex-direction:row-reverse; margin-top:5px">
                                  <se-secondary-button
                                      .customStyle=${{ marginLeft: 0 }}
                                      text="Reset Actual Values"
                                      @click=${this.resetSuccessCriteriaAsync}
                                  ></se-secondary-button>
                              </div>`
                            : html``}
                        ${this._schema
                            ? html`
                                
                <div style="display: flex; flex-direction:column; gap: 5px; margin-top:20px; margin-bottom:10px; min-width: 0; width: 100%;">
                    <h3 style="margin-top:0px">${this._schema.isLocked ? "Locked Schema" : "Schema"}</h3>
                    ${
                        displayTables.length > 1
                            ? html` <div style="margin:10px 0; overflow-x: auto; white-space: nowrap; width: 100%;">
                                  <div style="border-bottom: 1px solid gainsboro; min-width: min-content; display: inline-block;">
                                  ${displayTables.map((table) => {
                                      return table !== this._selectedSchemaTable
                                          ? html`<span
                                                @click=${() => this.selectDisplayTable(table)}
                                                style="padding:5px; cursor: pointer; display: inline-block;"
                                                class=${classMap({ tableError: table.isError })}
                                                ${table.isError ? htmlTitle(table.tableError ?? "Table is invalid") : undefined}
                                                >${table.name ?? "Main"}</span
                                            >`
                                          : html`<span
                                                style="border-bottom: 3px solid black;padding:5px; cursor: pointer; display: inline-block;"
                                                class=${classMap({ tableError: table.isError })}
                                                ${table.isError ? htmlTitle(table.tableError ?? "Table is invalid", true) : undefined}
                                                >${table.name ?? "Main"}</span
                                            >`;
                                  })}
                                  </div>
                              </div>`
                            : html``
                    }

                    <div style="display:flex;gap:5px">
                        <div style="display: flex;border: 1px solid Gainsboro;border-right:none;">
                            <div class="staticCol smoke ${classMap({ error: this._selectedSchemaTable?.nameError })}" ${
                                this._selectedSchemaTable?.nameError ? htmlTitle(this._selectedSchemaTable.nameError, true) : undefined
                            }">Table name</div>
                            <div class="staticCol">${this._selectedSchemaTable?.name ?? ""}</div> 
                        </div>
                        <div style="display: flex;border: 1px solid Gainsboro;border-right:none;">                            
                            <div class="staticCol smoke">Empty rows</div><div class="staticCol">${
                                this._selectedSchemaTable?.emptyRows
                            }</div>
                        </div>
                        <div style="display: flex;border: 1px solid Gainsboro;border-right:none;">
                            <div class="staticCol smoke">Empty table</div><div class="staticCol">${
                                this._selectedSchemaTable?.emptyTable
                            }</div>                        
                        </div>
                    </div>
                </div>                                
                <se-data-grid .fullHeight=${false} placeholder="No fields exported." layout="compact" .columns=${
                    this._columns
                } .rows=${displayRows}></se-data-grid>
                `
                            : html``}
                        ${this._tracking !== AgentDisplayInfoTracking.None
                            ? html`
                                  <div style="font-weight: bold;margin-top:20px">Export Tracking</div>
                                  <p>
                                      ${this._tracking === AgentDisplayInfoTracking.ChangeTracking
                                          ? " Change tracking enabled."
                                          : this._tracking === AgentDisplayInfoTracking.DuplicateTracking
                                            ? "Duplicate tracking enabled."
                                            : "Tracking status unknown."}
                                  </p>
                                  ${this._trackingSize
                                      ? html`<div><a href="javascript:;" @click=${() =>
                                            this.onDownloadTracking()}>Download tracking database</a> (${
                                            this._trackingSize
                                        }KB)</div><br><se-secondary-button .customStyle=${{ marginLeft: 0 }} text="Reset Tracking" @click=${
                                            this.resetTrackingAsync
                                        }></se-secondary-button></div>`
                                      : html`Tracking database is empty.`}
                              `
                            : html``}
                    </div>
                    <div style="max-width: 40%; min-height:0; min-width:0">
                        ${this._imageAuth
                            ? html`<img
                                  src="/api/config/${this.configId}/info/image/${this._imageAuth}"
                                  style="width:100%; border: 1px solid gray; box-shadow: 2px 2px 2px lightGray"
                              />`
                            : html``}
                    </div>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .prop {
            display: flex;
            gap: 5px;
            font-size: 0.85em;
            margin-top: 5px;
        }
        .prop-name {
            font-weight: bold;
        }
        .body {
            box-sizing: border-box;
            padding: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            background-color: white;
            border-radius: 3px 3px;
            box-shadow: 2px 2px 2px lightGray;
            border-radius: 5px;
            width: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
        }
        .button {
            border: 1px solid grey;
            border-radius: 3px 3px;
            background-color: white;
            transition:
                all 0.25s,
                opacity 0.5s;
            padding: 2px 4px;
            margin: 0;
        }
        .button:disabled {
            background-color: white;
            border: 1px solid lightgray;
            color: gray;
            transition: none;
        }
        .button:hover {
            box-shadow: 2px 2px 2px lightgray;
            border: 1px solid black;
        }
        .button:hover:disabled {
            border: 1px solid lightgray;
            box-shadow: none;
        }
        .button:active {
            box-shadow: none;
        }
        .editor-div {
            display: flex;
            flex-direction: row;
            justify-content: end;
            gap: 5px;
            width: 100%;
            margin-top: 2px;
        }
        div.edit-div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            min-height: 1.4em;
        }
        div.edit-div:hover .edit-icon {
            opacity: 1;
        }
        .edit-icon {
            opacity: 0;
            position: absolute;
            right: 0px;
        }
        .propertyColumn {
            display: flex;
            flex-direction: column;
            gap: 5px;
            justify-content: space-between;
        }
        .staticCol {
            padding: 2px 5px;
            border-right: 1px solid Gainsboro;
        }
        .smoke {
            background-color: whitesmoke;
        }
        .error {
            color: crimson;
            text-decoration: underline dotted;
            cursor: pointer;
        }
        .tableError {
            color: crimson;
            cursor: pointer;
        }

        .sc-name {
            background-color: whitesmoke;
        }
        .sc-row {
            border-bottom: 1px solid gainsboro;
        }
        .sc-right {
            text-align: right;
        }
    `;
}
