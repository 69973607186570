import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { FormInputEditorElement } from "../../editors/form/form-input-editor.element";
import { FormSelectEditorElement } from "../../editors/form/form-select-editor.element";
import { FormDateTimeEditorElement } from "../../editors/form/form-datetime-editor.element";
import { SubscriptionPlan } from "../../../models/subscription-plan-view-model";
import "../cards/edit-form-card.element";
import "../cards/card-buttons.element";

@customElement("se-organization-subscription-form")
export class SeOrganizationSubscriptionFormElement extends LitElement {
    @property({ type: Boolean }) isNew = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Array }) subscriptionPlans: SubscriptionPlan[] = [];
    @property({ type: Number }) _selectedPlanId: number = 0;
    @property({ type: Object }) subscription: {
        name: string;
        price: number;
        runUsage: number;
        exportedData: number;
        exportedRows: number;
        proxyUsage: number;
        isCanceled?: boolean;
        startDate?: Date;
        endDate?: Date;
    };
    @property({ type: Function }) onSave: (
        name: string, 
        price: number, 
        runTime: number, 
        exportGb: number, 
        proxyData: number
    ) => Promise<void>;
    @property({ type: Function }) onCancel: () => void;
    @property({ type: Function }) onPlanChange: (planId: number) => void;

    get selectedPlanId(): number {
        return this._selectedPlanId;
    }
    set selectedPlanId(value: number) {
        const oldValue = this._selectedPlanId;
        this._selectedPlanId = value;
        this._isCustom = value === 0;
        this.requestUpdate('selectedPlanId', oldValue);
    }

    @state() private _isCustom: boolean = true;
    @state() private _hasChanges = false;
    private _initialPlanId: number = null;

    @query("#planSelect") private _planSelectEditor: FormSelectEditorElement;
    @query("#name") private _nameEditor: FormInputEditorElement;
    @query("#price") private _priceEditor: FormInputEditorElement;
    @query("#runTime") private _runTimeEditor: FormInputEditorElement;
    @query("#exportGb") private _exportGbEditor: FormInputEditorElement;
    @query("#proxyData") private _proxyDataEditor: FormInputEditorElement;

    connectedCallback() {
        super.connectedCallback();
        this._isCustom = this._selectedPlanId === 0;
    }

    private _checkForChanges() {
        if (this.isNew) {
            // check if required fields have values
            this._hasChanges = !!(this._nameEditor?.liveValue?.trim());
        } else {
            // compare with original values
            const hasChanges = 
                this._selectedPlanId !== this._initialPlanId ||
                this._nameEditor?.liveValue !== this.subscription?.name ||
                this._priceEditor?.liveValue !== this.subscription?.price?.toString() ||
                this._runTimeEditor?.liveValue !== this.subscription?.runUsage?.toString() ||
                this._exportGbEditor?.liveValue !== this.subscription?.exportedData?.toString() ||
                this._proxyDataEditor?.liveValue !== this.subscription?.proxyUsage?.toString();
            
            this._hasChanges = hasChanges;
        }
        this.requestUpdate();
    }

    private _handlePlanChange(event: CustomEvent) {
        if (this._initialPlanId === null) {
            this._initialPlanId = this._selectedPlanId;
        }
        const planId = parseInt(event.detail.value);
        this._isCustom = planId === 0;
        this.onPlanChange(planId);
        // Force hasChanges to true when plan changes
        this._hasChanges = this._initialPlanId !== planId;
        this.requestUpdate();
    }

    private _handleFieldChange() {
        this._checkForChanges();
    }

    private _handleSave = async (ev: Event) => {
        ev.preventDefault();

        // Don't proceed if there are no changes
        if (!this._hasChanges) {
            return;
        }

        // Reset any custom validation messages
        this._nameEditor.setCustomValidity('');
        this._priceEditor.setCustomValidity('');

        // Validate name - cannot be just a space
        const name = this._nameEditor.liveValue;
        if (!name || name.trim() === '') {
            this._nameEditor.setCustomValidity('Name cannot be empty or just whitespace');
            this.reportValidity();
            return;
        }

        // Validate price - cannot exceed 100,000
        const price = parseFloat(this._priceEditor.liveValue);
        if (price > 100000) {
            this._priceEditor.setCustomValidity('Price cannot exceed 100000');
            this.reportValidity();
            return;
        }

        if (this.reportValidity()) {
            const runTime = parseFloat(this._runTimeEditor.liveValue);
            const exportGb = parseFloat(this._exportGbEditor.liveValue);
            const proxyData = parseFloat(this._proxyDataEditor.liveValue);
            await this.onSave(name, price, runTime, exportGb, proxyData);
        }
    };

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as any)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    render() {
        return html`
            <se-edit-form-card
                cardTitle="${this.isNew ? 'Add' : 'Edit'} Subscription"
            >
                <se-form-select-editor
                    id="planSelect"
                    name="planSelect"
                    label="Subscription Plan"
                    labelHelp="Select a predefined plan or create a custom one"
                    .value=${this.selectedPlanId?.toString()}
                    .options=${[
                        { id: 0, name: "Custom Plan" },
                        ...this.subscriptionPlans.map(plan => ({
                            id: plan.id,
                            name: `${plan.name} - $${plan.price}/mo`
                        }))
                    ]}
                    @valueChanged=${this._handlePlanChange}
                ></se-form-select-editor>

                <se-form-input-editor
                    id="name"
                    name="name"
                    type="text"
                    label="Subscription Name"
                    labelHelp="Name of the organization subscription"
                    size="50"
                    required
                    .disabled=${!this._isCustom}
                    .value=${this.subscription?.name ?? ""}
                    @valueChanged=${this._handleFieldChange}
                    @input=${this._handleFieldChange}
                ></se-form-input-editor>
                
                <se-form-input-editor
                    id="price"
                    name="price"
                    type="number"
                    label="Monthly Price"
                    labelHelp="Monthly subscription price"
                    required
                    min="0"
                    nonNegative
                    step="0.01"
                    .disabled=${!this._isCustom}
                    .value=${this.subscription?.price?.toString() ?? "0"}
                    @valueChanged=${this._handleFieldChange}
                    @input=${this._handleFieldChange}
                ></se-form-input-editor>

                <se-form-input-editor
                    id="runTime"
                    name="runTime"
                    type="number"
                    label="Server Time"
                    labelHelp="Hours of Server Run Time"
                    required
                    min="0"
                    nonNegative
                    step="1"
                    .disabled=${!this._isCustom}
                    .value=${this.subscription?.runUsage?.toString() ?? "0"}
                    @valueChanged=${this._handleFieldChange}
                    @input=${this._handleFieldChange}
                ></se-form-input-editor>

                <se-form-input-editor
                    id="exportGb"
                    name="exportGb"
                    type="number"
                    label="Export GB"
                    labelHelp="GB of exported data"
                    required
                    min="0"
                    nonNegative
                    step="0.1"
                    .disabled=${!this._isCustom}
                    .value=${this.subscription?.exportedData?.toString() ?? "0"}
                    @valueChanged=${this._handleFieldChange}
                    @input=${this._handleFieldChange}
                ></se-form-input-editor>

                <se-form-input-editor
                    id="proxyData"
                    name="proxyData"
                    type="number"
                    label="Proxy Data"
                    labelHelp="GB of proxy data usage"
                    required
                    min="0"
                    nonNegative
                    step="0.1"
                    .disabled=${!this._isCustom}
                    .value=${this.subscription?.proxyUsage?.toString() ?? "0"}
                    @valueChanged=${this._handleFieldChange}
                    @input=${this._handleFieldChange}
                ></se-form-input-editor>

            </se-edit-form-card>
            <se-card-buttons>
                <se-primary-button 
                    @click="${this._handleSave}" 
                    text="${this.isNew ? 'Add' : 'Update'}"
                    ?disabled=${!this._hasChanges}
                ></se-primary-button>
                <se-secondary-button 
                    @click="${this.onCancel}" 
                    text="Cancel"
                ></se-secondary-button>
            </se-card-buttons>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
        }
        .form-row {
            margin-bottom: 10px;
        }
    `;
} 