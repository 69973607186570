import { container, singleton } from "tsyringe";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";
import { SubscriptionPlan } from "../models/subscription-plan-view-model";
import { SubscriptionPlansViewModel } from "../models/subscription-plans-view-model";
import { OrganizationSubscription } from "../models/organization-subscription";

@singleton()
export class SubscriptionPlanApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    /**
     * Create a new subscription plan
     * @param plan The subscription plan to create
     */
    async createSubscriptionPlanAsync(plan: SubscriptionPlan) {
        return this.postObjectAsync('/api/subscription/plan/add', plan, this._authService.token);
    }

    /**
     * Get all subscription plans with pagination and sorting
     */
    async getSubscriptionPlansAsync(params: { pageIndex: number; recordsPerPage: number; sortColumn: string; sortOrder: number }) {
        const queryParams = new URLSearchParams({
            pageIndex: params.pageIndex.toString(),
            recordsPerPage: params.recordsPerPage.toString(),
            sortColumn: params.sortColumn,
            sortOrder: params.sortOrder.toString()
        });

        return this.getObjectAsync<SubscriptionPlansViewModel>(
            `/api/subscription/plans?${queryParams}`,
            this._authService.token
        );
    }

    /**
     * Get all subscription plans with optional sorting only (no pagination)
     */
    async getAllSubscriptionPlansAsync(sortColumn: string = "name", sortOrder: number = -1) {
        const queryParams = new URLSearchParams({
            pageIndex: "1",
            recordsPerPage: "1000", // Large number to get all records
            sortColumn,
            sortOrder: sortOrder.toString()
        });

        return this.getObjectAsync<SubscriptionPlansViewModel>(
            `/api/subscription/plans?${queryParams}`,
            this._authService.token
        );
    }

    /**
     * Update an existing subscription plan
     */
    async updateSubscriptionPlanAsync(subscriptionPlanId: number, plan: SubscriptionPlan) {
        return this.putObjectAsync(
            `/api/subscription/plan/${subscriptionPlanId}/update`,
            plan,
            this._authService.token
        );
    }

    /**
     * Delete a subscription plan
     */
    async deleteSubscriptionPlanAsync(subscriptionPlanId: number) {
        return this.deleteObjectAsync(
            `/api/subscription/plan/${subscriptionPlanId}`,
            this._authService.token
        );
    }

    /**
     * Delete multiple subscription plans
     */
    async deleteAllSubscriptionPlansAsync(planIds: number[]) {
        return this.deleteAllObjectsAsync(
            '/api/subscription/plan/all',
            { ids: planIds },
            this._authService.token
        );
    }

    /**
     * Get a single subscription plan by ID
     */
    async getSubscriptionPlanAsync(subscriptionPlanId: number) {
        return this.getObjectAsync<SubscriptionPlan>(
            `/api/subscription/plan/${subscriptionPlanId}`,
            this._authService.token
        );
    }

    /**
     * Get organization's subscription
     */
    async getOrganizationSubscriptionAsync(organizationId: number) {
        return this.getObjectAsync<OrganizationSubscription>(
            `/api/subscription/organization/${organizationId}`,
            this._authService.token
        );
    }

    /**
     * Create a new organization subscription
     */
    async createOrganizationSubscriptionAsync(subscription: OrganizationSubscription) {
        return this.postObjectAsync(
            `/api/subscription/organization/create`,
            subscription,
            this._authService.token
        );
    }

    /**
     * Update an existing organization subscription
     */
    async updateOrganizationSubscriptionAsync(subscription: OrganizationSubscription) {
        return this.putObjectAsync(
            `/api/subscription/organization/${subscription.id}/update`,
            subscription,
            this._authService.token
        );
    }

    /**
     * Cancel an organization subscription
     * @param organizationId The ID of the organization whose subscription to cancel
     */
    async cancelOrganizationSubscriptionAsync(organizationId: number) {
        return this.postObjectAsync(
            `/api/subscription/organization/${organizationId}/cancel`,
            null,
            this._authService.token
        );
    }

    /**
     * Resume an organization subscription
     * @param organizationId The ID of the organization whose subscription to resume
     */
    async resumeOrganizationSubscriptionAsync(organizationId: number) {
        return this.postObjectAsync(
            `/api/subscription/organization/${organizationId}/resume`,
            null,
            this._authService.token
        );
    }

    /**
     * Delete an organization subscription
     * @param organizationId The ID of the organization whose subscription to delete
     */
    async deleteOrganizationSubscriptionAsync(organizationId: number) {
        return this.deleteObjectAsync(
            `/api/subscription/organization/${organizationId}/delete`,
            this._authService.token
        );
    }
} 