import { Router } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { LabelFilter } from "../enums/label-filter";
import { idName } from "../models/id-name";
import { UserLabel } from "../models/user-label";
import { UserSpace } from "../models/user-space";
import { AppConfigService } from "../services/app-config.service";
import { AuthService } from "../services/auth.service";
import { LabelBookmarkApi } from "../services/label-bookmark.api";
import { LabelService } from "../services/label.service";
import { ModalDialogService } from "../services/modal-editor.service";
import { OrganizationService } from "../services/organization.service";
import { SpaceBookmarkApi } from "../services/space-bookmark.api";
import { UserApi } from "../services/user-api";
import { UserState } from "../services/user.state";
import "./components/fa-icon.element";
import "./editors/fancy-select.element";
import logo from "../../../assets/logo.png";
import noTextLogo from "../../../assets/seq-32.svg";
import { SelectEditorElement } from "./editors/select-editor.element";
import "./settings/organization-settings.element";
import "./settings/admin-settings.element";
import "./settings/user-settings.element";
import "./billing/subscription-plans.element";

@customElement("se-left-menu")
export class SeLeftMenuElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _labelService: LabelService;
    private _spaceBookmarkApi: SpaceBookmarkApi;
    private _labelBookmarkApi: LabelBookmarkApi;
    private _userApi: UserApi;
    private _toasterService: ToasterService;
    private _appConfigService: AppConfigService;
    private _organizationService: OrganizationService;

    private _organizations: idName[] = [];

    @state() private _draftCount = 0;
    @state() private _selectedSpace: string;
    @state() _selectedLabel: string;

    private _spaces: UserSpace[];
    @state() private _moreSpaces: UserSpace[] = [];
    @state() private _spaceBookmarks: UserSpace[] = [];
    @state() private _showMoreSpaces = false;

    private _labels: UserLabel[];
    @state() private _moreLabels: UserLabel[] = [];
    @state() private _labelBookmarks: UserLabel[] = [];
    @state() private _showMoreLabels = false;

    @query("#label") private _labelEditor: SelectEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
        this._userApi = container.resolve(UserApi);
        this._spaceBookmarkApi = container.resolve(SpaceBookmarkApi);
        this._labelService = container.resolve(LabelService);
        this._labelBookmarkApi = container.resolve(LabelBookmarkApi);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._appConfigService = container.resolve(AppConfigService);
        this._organizationService = container.resolve(OrganizationService);
    }

    async loadSpaces() {
        if (this._userState.selectedSpaceId != -2 && this._userState.router.location.route.component === "se-dashboard") {
            this._selectedSpace = "-2";
            this._userState.selectedSpaceId = -2;
        }
        if (this._userState.selectedSpaceId == -2 && this._userState.router.location.route.component === "se-space") {
            Router.go(`/dashboard`);
        }
        this._selectedSpace = this._userState.selectedSpaceId.toString();
        const spaces = await this._userState.getUserSpacesAsync();
        if (spaces.isOk) {
            this._spaces = [...spaces.value];
            this.filterSpaces();
            await this.loadLabels();
        } else {
            this._toasterService.showUnexpectedError(spaces.err.message);
        }
    }
    async loadLabels() {
        this._selectedLabel = this._userState.selectedLabelId.toString();
        if (this._userState.selectedSpaceId >= 0) {
            const labels = await this._userState.getUserLabelsAsync();
            if (labels.isOk) {
                this._labels = [...labels.value];
                this.filterLabels();
            } else {
                this._toasterService.showUnexpectedError(labels.err.message);
            }
        } else {
            this._labels = [];
        }
    }

    filterSpaces() {
        // Always separate spaces into bookmarks and non-bookmarks
        this._spaceBookmarks = this._spaces
            .filter((p) => p.isBookmark)
            .sort((a, b) => a.name.localeCompare(b.name));

        this._moreSpaces = this._spaces
            .filter((p) => !p.isBookmark)
            .sort((a, b) => a.name.localeCompare(b.name));
    }

    filterLabels() {
        if (this._labels.length > 3) {
            this._moreLabels = this._labels.filter((p) => !p.isBookmark).sort((a, b) => a.name.localeCompare(b.name));
            this._labelBookmarks = this._labels.filter((p) => p.isBookmark).sort((a, b) => a.name.localeCompare(b.name));
        } else {
            this._moreLabels = [];
            this._labelBookmarks = [...this._labels.sort((a, b) => a.name.localeCompare(b.name))];
        }

        this._labelBookmarks.splice(0, 0, {
            id: 0,
            name: "All Configs",
            isBookmark: true,
            labelFilter: LabelFilter.Config,
            isAdminLabel: true,
        });
    }

    async connectedCallback() {
        super.connectedCallback();
        await this.loadSpaces();
        this._draftCount = this._userState.draftCount;

        document.addEventListener('space-selected', ((e: CustomEvent) => {
            this.selectSpaceBookmark(e, e.detail.spaceId);
        }));

        this._userState.userSpacesChanged.bind(this, this.loadSpaces);
        this._userState.userLabelsChanged.bind(this, this.loadLabels);
        this._userState.draftCountChanged.bind(this, this.onDraftCountChanged);
        this._userState.selectedSpaceOrLabelChanged.bindAsync(this, this.spaceOrLabelChanged);
        window.addEventListener("changeOrganization", (ev: CustomEvent) => this.handleChangeOrganization(ev));

    }
    disconnectedCallback() {
        document.removeEventListener('space-selected', ((e: CustomEvent) => {
            this.selectSpaceBookmark(e, e.detail.spaceId);
        }));

        this._userState.userSpacesChanged.unbind(this, this.loadSpaces);
        this._userState.userLabelsChanged.unbind(this, this.loadLabels);
        this._userState.draftCountChanged.unbind(this, this.onDraftCountChanged);
        this._userState.selectedSpaceOrLabelChanged.unbindAsync(this, this.spaceOrLabelChanged);
        window.removeEventListener("changeOrganization", (ev: CustomEvent) => this.handleChangeOrganization(ev));


        super.disconnectedCallback();
    }
    //catch change org from se4 admins prompt
    private async handleChangeOrganization(ev: CustomEvent) {
        const orgId = ev.detail.orgId
        await this.getOrganizationsAsync()
        const org = this._organizations?.filter((p) => p.id === orgId);
        if (org.length > 0) {
            const result = await this._userApi.changeOrganizationAsync(org[0].id);
            if (result.isOk) {
                this._authService.changeOrganization(org[0].id, org[0].name);
                window.location.reload()
            }
        }
    }
    private async spaceOrLabelChanged() {
        const lastSpace = this._selectedSpace;
        this._selectedSpace = this._userState.selectedSpaceId.toString();
        this._selectedLabel = this._userState.selectedLabelId.toString();
        if (lastSpace !== this._selectedSpace) {
            await this.loadLabels();
        }
        this.select();
    }

    private onDraftCountChanged(count: number) {
        this._draftCount = count;
    }

    private async addSpaceBookmark(evt: Event, spaceId: number) {
        evt.stopPropagation();
        evt.preventDefault();
        //const spaceId = parseInt(this._spaceEditor.liveValue);
        const res = await this._spaceBookmarkApi.createAsync(spaceId);
        if (res.isOk) {
            this._spaces.find((p) => p.id === spaceId).isBookmark = true;
            this.filterSpaces();
        } else {
            this._toasterService.showUnexpectedError(res.err.message);
        }
    }
    private async removeSpaceBookmark(evt: Event, spaceId: number) {
        evt.stopPropagation();
        evt.preventDefault();
        const res = await this._spaceBookmarkApi.removeAsync(spaceId);
        if (res.isOk) {
            this._spaces.find((p) => p.id === spaceId).isBookmark = false;
            this.filterSpaces();
        } else {
            this._toasterService.showUnexpectedError(res.err.message);
        }
    }
    private async selectSpaceBookmark(evt: Event, spaceId: number) {
        evt.stopPropagation();
        evt.preventDefault();
        this._selectedSpace = spaceId.toString();
        this._userState.selectedSpaceId = spaceId;
        this._selectedLabel = "0";
        this._userState.selectedLabelId = 0;
        this.loadLabels();
        this.select();
    }

    private async addLabelBookmark(evt: Event, labelId: number) {
        evt.stopPropagation();
        evt.preventDefault();
        //const labelId = parseInt(this._labelEditor.liveValue);
        const res = await this._labelBookmarkApi.createAsync(labelId);
        if (res.isOk) {
            this._labels.find((p) => p.id === labelId).isBookmark = true;
            this.filterLabels();
        } else {
            this._toasterService.showUnexpectedError(res.err.message);
        }
    }
    private async removeLabelBookmark(evt: Event, labelId: number) {
        evt.stopPropagation();
        evt.preventDefault();
        const res = await this._labelBookmarkApi.removeAsync(labelId);
        if (res.isOk) {
            this._labels.find((p) => p.id === labelId).isBookmark = false;
            this.filterLabels();
        } else {
            this._toasterService.showUnexpectedError(res.err.message);
        }
    }
    private async deleteLabel(evt: Event, labelName: string, labelId: number, isAdmin: boolean) {
        const modalResult = await this._modalService.openConfirmDialogAsync({
            title: "Delete Label",
            body: `Are you sure you want to delete the label ${labelName}${isAdmin ? " from all spaces" : ""}?`,
            saveCaption: "Delete Label",
        });
        if (modalResult.isSave) {
            const res = await this._labelService.api.deleteAsync(labelId);
            if (res.isOk) {
                this._userState.removeUserLabelsAsync([labelId]);
                this.loadLabels();
                this.selectLabelBookmark(evt, 0);
            } else {
                this._toasterService.showUnexpectedError(res.err.message);
            }
        }
    }
    private async selectLabelBookmark(evt: Event, labelId: number) {
        evt.stopPropagation();
        evt.preventDefault();
        this._selectedLabel = labelId.toString();
        this._userState.selectedLabelId = labelId;
        this.select();
    }
    private async selectLabel(evt: Event) {
        evt.stopPropagation();
        evt.preventDefault();
        this._selectedLabel = this._labelEditor.liveValue;
        this._userState.selectedLabelId = parseInt(this._selectedLabel);
        this.select();
    }

    private select() {
        this.dispatchEvent(new CustomEvent('leftMenuChanged', {
            bubbles: true,
            composed: true
        }));

        if (this._userState.selectedLabelId === -1 || this._userState.selectedSpaceId === -2) {
            return;
        }

        const newUrl = `/space/${this._selectedSpace}/label/${this._selectedLabel}?label=&status=&template=`;
        
        // Only update URL if it's different from current and not handling a popstate
        if (window.location.pathname + window.location.search !== newUrl) {
            if (this._userState.router.location.route.component === "se-space") {
                window.history.pushState(
                    { 
                        type: 'space-navigation',
                        spaceId: this._selectedSpace, 
                        labelId: this._selectedLabel 
                    }, 
                    "Sequentum Cloud", 
                    newUrl
                );
            } else {
                Router.go(newUrl);
            }
        }
        
        this._userState.gridDataChanged.triggerVoid();
    }

    private async switchOrganizationAsync(orgName: string) {
        const org = this._organizations?.filter((p) => p.name === orgName);
        if ((org?.length ?? 0 > 0) && this._authService.user.organizationId !== org[0].id) {
            const result = await this._userApi.changeOrganizationAsync(org[0].id);
            if (result.isOk) {
                this._authService.changeOrganization(org[0].id, orgName);
                await this._userState.refreshUserSpacesAsync();
                this._selectedSpace = "-2";
                this._userState.selectedSpaceId = -2;
                this._selectedLabel = "0";
                this._userState.selectedLabelId = 0;
                this.select();
                Router.go(`/dashboard`);
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
        //this.endSwitchOrganization();
    }
    private async getOrganizationsAsync() {
        const result = await this._organizationService.api.getAllIdAndNamesAsync();
        if (result.isOk) {
            this._organizations = result.value;

            // sort by name asc
            this._organizations.sort((a, b) => a.name.localeCompare(b.name));
            return this._organizations.map((p) => p.name);
        } else {
            this._toasterService.showNetworkError(result.err);
        }
    }

    private selectSpaceHelper(evt: Event, spaceId: number) {
        // Don't change space selection when navigating between admin/org settings sections
        if (spaceId === -4 || spaceId === -5 || spaceId === -7) { // -4 is user settings, -5 is org settings, -7 is admin settings
            this._selectedSpace = spaceId.toString();
            // Prevent further navigation handling
            evt.preventDefault();
            evt.stopPropagation();
            return;
        }
        
        if (spaceId >= -1) {
            this.selectSpaceBookmark(evt, spaceId);
        } else {
            this._selectedSpace = spaceId.toString();
        }
    }

    private async handleLogout() {
        this._authService.logout();
        window.location.href = '/login';
    }

    render() {
        const spaceId = parseInt(this._selectedSpace);
        const labelId = parseInt(this._selectedLabel);
        return html`
            <div class="body">
                ${this._authService.isSE4Admin ? html`
                <div class="logo-panel">
                    <img src=${noTextLogo} class="no-text-logo" />
                    <se-fancy-select
                                  .value=${this._authService.user.organizationName}
                                  .getTextOptions=${this.getOrganizationsAsync.bind(this)}
                                  min-suggestion-width="200px"
                                  @valueChanged=${(evt) => this.switchOrganizationAsync(evt.detail.value)}
                                  hover-background-color="var(--color-primary-tint)"
                                  right-icon="fas fa-caret-down"
                                  html-title="Change organization"
                              ></se-fancy-select>
                </div>
                `:html`
                <img src=${logo} class="logo" />
                `}
                <div class="buckets">
                    <a href="/dashboard" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-2" })}">
                        <span class="menu-text" @click=${(evt) => { this.selectSpaceBookmark(evt, -2); Router.go(`/dashboard`); }}
                            ><fa-icon fa-class="fas fa-home-alt"></fa-icon>&nbsp;&nbsp;Home</span
                        >
                    </a>
                    ${this._authService.user.organizationId == this._authService.user.ownerOrganizationId ? html`
                    <a href="/space/0/label/0" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "0" })}">
                        <span class="menu-text" @click=${(evt) => this.selectSpaceBookmark(evt, 0)}
                            ><fa-icon fa-class="fas fa-user"></fa-icon>&nbsp;&nbsp;Personal</span
                        >
                    </a>
                    <a href="/space/-1/label/0" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-1" })}">
                        <span class="menu-text" @click=${(evt) => this.selectSpaceBookmark(evt, -1)}
                            ><fa-icon fa-class="fas fa-edit"></fa-icon>&nbsp;&nbsp;Drafts</span
                        >
                        ${this._draftCount > 0 ? html`<span class="count">${this._draftCount}</span>` : html``}
                    </a>` : ''
                    }
                    ${this._authService.isOrgAdmin
                        ? html`
                        <a href="/org-runs" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-3" })}" @click=${(evt) => this.selectSpaceHelper(evt, -3)}>
                            <span class="menu-text" @click=${() => Router.go('/org-runs')}>
                                <fa-icon fa-class="fas fa-list-ul"></fa-icon>&nbsp;&nbsp;All Runs</span
                            >
                        </a>
                        <a href="/org-schedules" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-8" })}" @click=${(evt) => this.selectSpaceHelper(evt, -8)}>
                            <span class="menu-text" @click=${() => Router.go('/org-schedules')}>
                                <fa-icon fa-class="fas fa-clock"></fa-icon>&nbsp;&nbsp;Scheduled</span
                            >
                        </a>`: html``
                    }
                    ${this._spaceBookmarks.length > 0 ? html`
    <div class="spacer"></div>
    <div class="menu-title">Favorites</div>
` : html``}
${this._spaceBookmarks.map(
    (key) => html`
        <div class="menu-item ${classMap({ menuItemSelected: key.id === spaceId })}">
            <span class="menu-text" @click=${(evt) => this.selectSpaceBookmark(evt, key.id)}>${key.name}</span>
            <span
                ${htmlTitle("Remove from Favorites")}
                class="menu"
                @click=${(evt) => this.removeSpaceBookmark(evt, key.id)}
                ><fa-icon fa-class="fas fa-times-circle"></fa-icon
            ></span>
        </div>
    `
)}

                    <div class="spacer"></div>
                    
                </div>
<!-- Spaces section -->
${(this._moreSpaces?.length ?? 0) > 0
                ? html`
        <div class="menu-title">Spaces</div>
        <!-- Display the first 5 non-bookmarked spaces -->
        ${this._moreSpaces.slice(0, 5).map(
                    (key) => html`
                <div class="menu-item ${classMap({ menuItemSelected: key.id === spaceId })}">
                    <span class="menu-text" @click=${(evt) => this.selectSpaceBookmark(evt, key.id)}
                        >${key.name}</span
                    >
                    <span
                        ${htmlTitle("Add to Favorites")}
                        class="menu"
                        @click=${(evt) => this.addSpaceBookmark(evt, key.id)}
                        ><fa-icon fa-class="fas fa-plus-circle"></fa-icon
                    ></span>
                </div>
            `
                )}

        <!-- Show "More" button if there are more than 5 non-bookmarked spaces -->
        ${this._moreSpaces.length > 5
                        ? html`
                ${this._showMoreSpaces
                                ? html`
                        <!-- "Less" button to collapse the additional spaces -->
                        <div class="menu-item">
                            <span class="menu-text" @click=${() => (this._showMoreSpaces = false)}
                                ><fa-icon fa-class="fas fa-caret-up"></fa-icon>&nbsp;&nbsp;Less</span
                            >
                        </div>
                        <!-- Display the remaining non-bookmarked spaces when "More" is clicked -->
                        ${this._moreSpaces.slice(5).map(
                                    (key) => html`
                                <div class="menu-item ${classMap({ menuItemSelected: key.id === spaceId })}">
                                    <span class="menu-text" @click=${(evt) => this.selectSpaceBookmark(evt, key.id)}
                                        >${key.name}</span
                                    >
                                    <span
                                        ${htmlTitle("Add to Favorites")}
                                        class="menu"
                                        @click=${(evt) => this.addSpaceBookmark(evt, key.id)}
                                        ><fa-icon fa-class="fas fa-plus-circle"></fa-icon
                                    ></span>
                                </div>
                            `
                                )}
                    `
                                : html`
                        <!-- "More" button to expand and show additional spaces -->
                        <div class="menu-item">
                            <span class="menu-text ${classMap({ menuItemSelected: this._moreSpaces.slice(5).some(p => p.id === spaceId) })}" @click=${() => (this._showMoreSpaces = true)}
                                ><fa-icon fa-class="fas fa-caret-down"></fa-icon>&nbsp;&nbsp;More</span
                            >
                        </div>
                    `}
              `
                        : html``}
      `
                : html``}

  <!-- Display "Add Space" and "Manage" links if user is an Org Admin -->
      ${this._authService.isOrgAdmin
                ? html`
            <div class="add-menu-item">
              <a href="/edit/space">Add Space</a><span>|</span
              ><a ${htmlTitle("Manage spaces")} href="/org/spaces">Manage</a>
            </div>
          `
                : html``}
<!-- showmorespaces content end -->

                ${this._selectedSpace >= "0"
                    ? html`
                          <div class="labels">
                              ${this._labelBookmarks.map(
                                  (key) => html`
                                      <div class="menu-item ${classMap({ menuItemSelected: key.id === labelId })}">
                                          <span
                                              class="menu-text"
                                              href="javascript:;"
                                              @click=${(evt) => this.selectLabelBookmark(evt, key.id)}
                                              >${key.name}</span
                                          >
                                          ${key.id > 0 && (this._authService.isOrgAdmin || !key.isAdminLabel)
                                              ? html`<span
                                                    ${htmlTitle("Delete label")}
                                                    class="menu"
                                                    @click=${(evt) => this.deleteLabel(evt, key.name, key.id, key.isAdminLabel)}
                                                    ><fa-icon fa-class="fas fa-trash"></fa-icon
                                                ></span>`
                                              : html``}
                                          ${key.id > 0 && this._labels.length > 3
                                              ? html`<span
                                                    ${htmlTitle("Hide below by default")}
                                                    class="menu"
                                                    @click=${(evt) => this.removeLabelBookmark(evt, key.id)}
                                                    ><fa-icon fa-class="fas fa-times-circle"></fa-icon
                                                ></span>`
                                              : html``}
                                      </div>
                                  `
                              )}
                          </div>
                          ${this._moreLabels.length > 0
                              ? html`
                                    ${this._showMoreLabels
                                        ? html`
                                              <div class="menu-item">
                                                  <span class="menu-text" @click=${() => (this._showMoreLabels = false)}
                                                      ><fa-icon fa-class="fas fa-caret-up"></fa-icon>&nbsp;&nbsp;Less</span
                                                  >
                                              </div>
                                              ${this._moreLabels.map(
                                                  (key) => html`
                                                      <div class="menu-item ${classMap({ menuItemSelected: key.id === labelId })}">
                                                          <span
                                                              class="menu-text"
                                                              href="javascript:;"
                                                              @click=${(evt) => this.selectLabelBookmark(evt, key.id)}
                                                              >${key.name}</span
                                                          >
                                                          ${key.id > 0 && (this._authService.isOrgAdmin || !key.isAdminLabel)
                                                              ? html`<span
                                                                    ${htmlTitle("Delete label")}
                                                                    class="menu"
                                                                    @click=${(evt) =>
                                                                        this.deleteLabel(evt, key.name, key.id, key.isAdminLabel)}
                                                                    ><fa-icon fa-class="fas fa-trash"></fa-icon
                                                                ></span>`
                                                              : html``}
                                                          ${key.id > 0
                                                              ? html`<span
                                                                    ${htmlTitle("Always show at top")}
                                                                    class="menu"
                                                                    @click=${(evt) => this.addLabelBookmark(evt, key.id)}
                                                                    ><fa-icon fa-class="fas fa-plus-circle"></fa-icon
                                                                ></span>`
                                                              : html``}
                                                      </div>
                                                  `
                                              )}
                                              <div class="add-menu-item">
                                                  <a ${htmlTitle("Add label")} href="/edit/label/space/${this._selectedSpace}">Add label</a
                                                  ><span>|</span
                                                  ><a ${htmlTitle("Manage labels")} href="/manage/labels/space/${this._selectedSpace}"
                                                      >Manage</a
                                                  >
                                              </div>
                                          `
                                        : html`<div
                                              class="menu-item ${classMap({
                                                  menuItemSelected: this._moreLabels.some((p) => p.id === labelId),
                                              })}"
                                          >
                                              <span class="menu-text" @click=${() => (this._showMoreLabels = true)}>
                                                  <fa-icon fa-class="fas fa-caret-down"></fa-icon>&nbsp;&nbsp;More</span
                                              >
                                          </div>`}
                                `
                              : html`
                                    <div class="add-menu-item" style="margin-top:5px">
                                        <div><a href="/edit/label/space/${this._selectedSpace}">Add label</a></div>
                                        <span>|</span
                                        ><a ${htmlTitle("Manage labels")} href="/manage/labels/space/${this._selectedSpace}">Manage</a>
                                    </div>
                                    <div style="min-height: 20px" class="spacer"></div>
                                `}
                      `
                    : html``}
            </div>
            <div class="footer-body">
                <div class="menu-title">Your Account</div>
                <a href="/user" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-4" })}">
                    <span class="menu-text">
                        <fa-icon fa-class="fas fa-user-circle"></fa-icon>&nbsp;&nbsp;Account
                    </span>
                </a>
                ${this._authService.isOrgAdmin
                ? html`
                    <a href="/org" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-5" })}" @click=${(evt) => this.selectSpaceHelper(evt, -5)}>
                            <span class="menu-text" @click=${() => Router.go('/org')}>
                            <fa-icon fa-class="fas fa-building"></fa-icon>&nbsp;&nbsp;Organization</span>
                    </a>
                    <a href="/usage" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-6" })}" @click=${(evt) => this.selectSpaceHelper(evt, -6)}>
                            <span class="menu-text" @click=${() => Router.go('/usage')}>
                            <fa-icon fa-class="fas fa-chart-simple"></fa-icon>&nbsp;&nbsp;Usage</span>
                    </a>
                ${this._authService.isSE4Admin
                    ? html`
                    <a href="/manage/admin" class="menu-item ${classMap({ menuItemSelected: this._selectedSpace === "-7" })}" @click=${(evt) => this.selectSpaceHelper(evt, -7)}>
                    <span class="menu-text" @click=${() => Router.go(`/manage/admin`)}>
                    <fa-icon fa-class="fas fa-shield"></fa-icon>&nbsp;&nbsp;Admin</span>
                </a>`
                    : html``}
                
                            `
                    : html``}
                
                <a href="http://cloudsupport.sequentum.com/" target="_blank" class="menu-item">
                        <span class="menu-text">
                        <fa-icon fa-class="fas fa-circle-question"></fa-icon>&nbsp;&nbsp;Help</span>
                </a>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            color: white;
            display: flex;
            flex-direction: column;
            font: var(--font);
        }

        .body {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: hidden;
            padding: 15px 5px 0 0;
            scrollbar-width: thin;
            scrollbar-color: var(--color-gray-4) var(--color-primary);
            scrollbar-gutter: stable;
        }

        .footer-body {
            display: flex;
            flex-direction: column;
            overflow-y: hidden;
            padding: 10px 6px 10px 0px;
            scrollbar-width: thin;
            scrollbar-color: var(--color-primary-tint) var(--color-primary);
            scrollbar-gutter: stable;
            border-top: solid 1px var(--color-gray-2);
        }

        .body:hover {
            overflow-y: auto;
        }
        .logo {
            max-width: 170px;
            padding: 0px 15px;
        }
        .editor {
            font: var(--font);
            margin-top: 10px;
            margin-right: 10px;
            flex: 1;
        }
        .operations {
            display: flex;
            gap: 5px;
            font: var(--font-small);
            color: white;
        }
        .select-panel {
            display: flex;
            color: white;
            align-items: end;
            justify-content: space-between;
            padding-left: 15px;
        }
        a {
            color: white;
        }
        .buckets {
            padding: 20px 0px 0px 0px;
            display: flex;
            flex-direction: column;
        }
        .types {
            display: flex;
            flex-direction: column;
        }
        .menu-item {
            display: flex;
            justify-content: space-between;
            margin: 0px 0px 1px 0px;
            padding-left: 15px;
            cursor: pointer;
            text-decoration-line: none;
        }
        .menu-item:hover {
            background-color: var(--color-dark-hover);
            border-radius: 0px 10px 10px 0px;
        }
        .menuItemSelected {
            background-color: var(--color-dark-selected);
            border-radius: 0px 10px 10px 0px;
        }
        .spacer {
            height: 20px;
        }
        .menu-title {
            padding: 0px 0px 0px 15px;
            color: var(--color-gray-2);
            font: var(--font-small);
        }
        .menu-item:hover .menu {
            visibility: visible;
        }
        .menu:hover {
            color: white;
        }
        .count {
            padding: 4px 4px 4px 7px;
        }
        .menu {
            color: silver;
            padding: 4px 5px 4px 0px;
        }
        .menu-item .menu {
            visibility: hidden;
        }
        .menu-item a {
            text-decoration: none;
        }
        .menu-text {
            flex: 1;
            display: block;
            padding: 4px 10px 4px 0px;
            font-weight: 500;
        }
        .labels {
            display: flex;
            flex-direction: column;
            border-top: solid 1px silver;
            margin-top: 15px;
            padding-top: 15px;
        }
        .add-menu-item {
            display: flex;
            font: var(--font-small);
            margin-top: 3px;
            padding-left: 15px;
            gap: 3px;
        }
        .footer {
            font-size: 0.8em;
            padding: 5px 10px 0px 10px;
        }
        .version {
            font-size: 0.8em;
            padding: 5px 10px 0px 10px;
        }
        fa-icon {
            width: 20px;
        }
        .no-text-logo {
            max-width: 25px;
        }
        .logo-panel {
            display: flex;
            padding-left: 15px;
            gap: 5px;
        }
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 20px;
            border: solid 1px gray;
            background-color: var(--color-primary-tint);
        }
        ::-webkit-scrollbar-thumb:hover {
            background-color: var(--color-primary);
        }
    `;
}
