import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement, state, query } from "lit/decorators.js";
import { container } from "tsyringe";
import { Router, RouterLocation, PreventAndRedirectCommands } from "@vaadin/router";
import { UserService } from "../../services/user.service";
import { ToasterService } from "se-shared/services/toaster.service";
import backgroundMaze from "../../../../assets/backgroundMaze.png";
import logo from "../../../../assets/logo-tagline.svg";
import instagram from "../../../../assets/instagram.svg";
import linkedin from "../../../../assets/linkedin.svg";
import twitter from "../../../../assets/twitter.svg";
import { InputEditorElement } from "../editors/input-editor.element";
import { SePrimaryButton } from "../components/primary-button.element";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";

@customElement("se-email-verification")
export class SeEmailVerificationElement extends LitElement {
    private _userService = container.resolve(UserService);
    private _toasterService = container.resolve(ToasterService);
    private _token: string;

    @state() private _finish = false;
    @state() private _submitted = false;
    @state() private _hasChanged = false;
    @state() private _onupdating = false;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#companyName") private _companyNameEditor: InputEditorElement;
    @query("#email") private _emailEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#confirmPassword") private _comparePasswordEditor: InputEditorElement;

    @query("#saveButton") private _saveButton: SePrimaryButton;

    private _eulaAccepted = false;

    constructor() {
        super();
        this.submitLogin = this.submitLogin.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (location.params.token) {
            this._token = location.params.token.valueOf() as string;
        } else {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener("keydown", this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        //this.verifyEmail();
    }

    disconnectedCallback() {
        document.removeEventListener("keydown", this.submitLogin);
        this.removeEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }

    submitLogin(event) {
        if (event.key === "Enter" && !this._finish) {
            this.onSave(event);
        } 
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async onSave(ev) {
        ev.preventDefault();
        this._toasterService.clear();

        //make sure that EULA has been accepted
        if (this._eulaAccepted !== true) {
            this._toasterService.showError("Please accept the EULA");
            return;
        }

        if (this.reportValidity()) {
            this._saveButton.loading = true;
            await this._userService.api.verifyEmailAsync(this._token,
                this._nameEditor.liveValue,
                this._companyNameEditor.liveValue,
                this._passwordEditor.liveValue
            )
                .then(() => {
                    this._finish = true
                })
                .catch((err) => {
                    this.error(err);
                    this._saveButton.loading = false;
                })
        }

        /*if (this.reportValidity()) {
            this._saveButton.loading = true;
            this._hasChanged = false;
            await this._appConfigService
                .registerUserAsync(
                    this._nameEditor.liveValue,
                    this._companyNameEditor.liveValue,
                    this._emailEditor.liveValue,
                    this._passwordEditor.liveValue
                )
                .then(() => this.success())
                .catch((err) => this.error(err))
                .finally(() => this._saveButton.loading = false);
        }*/
    }

    private error(err: Error) {
        this._toasterService.showError(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        } else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    private goToLogin(ev: Event) {
        ev.preventDefault();
        Router.go('/login');
    }

    /**
     * The eula checkbox was clicked
     * @param evt
     */
    private onEulaChanged(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._eulaAccepted = true;
            } else {
                this._eulaAccepted = false;
            }
        }
    }

    private async showEULA(ev) {
        ev.preventDefault();
        this.openEULADialog();
        //Router.go("/login");
    }

    private openEULADialog() {
        fetch("./eula.txt")
            .then((response) => response.text())
            .then((eulaContent) => {
                // Open a new window
                const eulaWin = window.open("", "EULA");

                // Write the content of the EULA into the new window
                eulaWin.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Sequentum EULA</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                margin: 20px;
                                overflow-y: scroll;
                                max-height: 90vh;
                            }
                        </style>
                    </head>
                    <body>
                        <pre>${eulaContent}</pre>
                    </body>
                    </html>
                `);

                // Close the document after writing
                eulaWin.document.close();
            })
            .catch((error) => console.error("Error fetching EULA:", error));
    }

    render() {
        return html`
            <form id="verificationForm" class="editor">
                <div class="scroll-container">
                    <div class="content">
                        <div style="text-align: center;margin-bottom: 10px;">
                            <img src=${logo} class="header-logo" />
                        </div>
                        ${!this._finish ? html`
                        <div class="message">
                            Please fill in the details below to complete the registration.
                        </div>
                       
                        <se-input-editor
                            size="normal"
                            enableAutocomplete
                            class="inputEditor"
                            id="name"
                            name="name"
                            type="text"
                            label="Name"
                            placeholder="John Doe"
                            labelPosition="top"
                            required
                            size="30"
                        ></se-input-editor>

                        <se-input-editor
                            size="normal"
                            class="inputEditor"
                            id="companyName"
                            name="companyName"
                            type="text"
                            label="Organization"
                            placeholder="Company Name"
                            labelPosition="top"
                            required
                            size="30"
                        ></se-input-editor>

                        <se-input-editor
                            size="normal"
                            enableAutocomplete
                            class="inputEditor"
                            @editorChanged=${this.passwordChanged}
                            id="password"
                            name="password"
                            type="text"
                            label="Password"
                            labelPosition="top"
                            input-type="password"
                            required
                            size="30"
                        ></se-input-editor>

                        <se-input-editor
                            size="normal"
                            enableAutocomplete
                            class="inputEditor"
                            @editorChanged=${this.passwordChanged}
                            id="confirmPassword"
                            name="confirmPassword"
                            type="text"
                            label="Confirm password"
                            labelPosition="top"
                            input-type="password"
                            required
                            size="30"
                        ></se-input-editor>

                        <div class="inputEditor" style="flex:1">
                            <div class="linkLabel">
                                <se-checkbox-editor
                                    id="eula"
                                    name="eula"
                                    .value=${this._eulaAccepted}
                                    @valueChanged=${this.onEulaChanged}
                                    tristate="auto"
                                ></se-checkbox-editor>

                                <a href="EULA" class="link" @click="${this.showEULA}" style="margin-left: 10px">I agree to EULA </a>
                            </div>
                        </div>

                        <div class="loginButton inputEditor">
                            <se-primary-button
                            size="normal"
                            position="first"
                            id="saveButton"
                            class="saveButton"
                            @click="${this.onSave}"
                            ?disabled="${!this._hasChanged}"
                            text="Finish"
                            .loading=${this._onupdating}
                            ></se-primary-button>
                        </div>

                        

                        `
                            : ``
                        }

                        ${this._finish ? html`
                        <div class="h2">
                            Congratulations!
                        </div>
                        <div class="message">
                            You are successfully registered with Sequentum Cloud.
                        </div>    
                        <se-primary-button
                                size="normal"
                                position="first"
                                @click="${this.goToLogin}"
                                text="Go to Login"
                                class="inputEditor loginButton"
                            ></se-primary-button>
                        ` : ''}
                    </div>
                </div>
            </form>
        `;
    }
    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        :host * {
            box-sizing: border-box;
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .loginButton {
            margin-top: 10px;
        }
        .inputEditor {
            width: 100%;
        }
        .h2 {
            font: var(--font-h2);
            color: var(--color-primary);
            font-weight: 600;
            padding: 0px 0px 10px;
        }
        .message {
            margin: 20px 0;
        }
        .linkLabel {
            display: flex;
            flex: 2;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }
    `;

}