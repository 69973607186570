import { PreventAndRedirectCommands, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { OrganizationEmailSettingsModel } from "../../models/organization-emails-model";
import { AuthService } from "../../services/auth.service";
import { OrganizationService } from "../../services/organization.service";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { NumberEditorElement } from "../editors/inline/number-editor.element";
import "../components/loading-panel.element";
import { OrganizationViewModel } from "../../models/organization-view-model";
import { Router } from "@vaadin/router";

@customElement("se-organization-email-editor")
export class SeOrganizationEmailEditorElement extends LitElement {
    private _authService: AuthService;
    private _organizationService: OrganizationService;
    private _toasterService: ToasterService;

    @property() organization?: OrganizationViewModel;

    @state() private _isLoading = true;

    @state() private _organizationEmailSetting?: OrganizationEmailSettingsModel;

    @query("#dailyQuota") private _dailyQuotaEditor: NumberEditorElement;
    @query("#dailyCounter") private _dailyCounterEditor: NumberEditorElement;
    @query("#isAllowExternalRecipients") private _isAllowExternalRecipientsEditor: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._organizationService = container.resolve(OrganizationService);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        
        const orgId = Number(location.params.orgId);
        
        this.organization = new OrganizationViewModel();
        this.organization.id = orgId;
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadOrganizationEmailSettings();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async loadOrganizationEmailSettings() {
        if (!this.organization?.id) {
            this._isLoading = false;
            return;
        }
        
        const res = await this._organizationService.api.getOrganizationEmailSettingsAsync(this.organization.id);
        if (res.isOk) {
            this._organizationEmailSetting = res.value;
        } else {
            this._toasterService.showNetworkError(res.err);
        }
        
        this._isLoading = false;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            this._organizationEmailSetting.dailyQuota = this._dailyQuotaEditor.liveValue;
            this._organizationEmailSetting.dailyCounter = this._dailyCounterEditor.liveValue;
            this._organizationEmailSetting.isAllowExternalRecipients = this._isAllowExternalRecipientsEditor.liveValue;

            const res = await this._organizationService.api.updateOrganizationEmailSettingsAsync(this._organizationEmailSetting);
            if (res.isOk) {
                this._toasterService.showSuccess("Email settings saved successfully.");
                this.close(true);
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    close(isSave: boolean) {
        Router.go("/manage/admin/organizations");
        this.dispatchEvent(new CustomEvent("close", { bubbles: true, composed: true, detail: { isSave: isSave } }));
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this.organization.name} Email Settings</div>
                <se-loading-panel
                    id="loadingPanel"
                    .loadingStyle=${{
                        boxShadow: "2px 2px 2px lightGray",
                        border: "1px solid gray",
                        borderRadius: "5px 5px",
                        backgroundColor: "white",
                        minHeight: "50px",
                        minWidth: "125px",
                    }}
                    .isLoading=${this._isLoading}
                >
                    <div class="scroll-container">
                        <se-number-editor
                            id="dailyQuota"
                            label="Daily quota"
                            labelPosition="top"
                            required
                            .value=${this._organizationEmailSetting?.dailyQuota}
                        ></se-number-editor>
                        <se-number-editor
                            id="dailyCounter"
                            label="Daily counter"
                            labelPosition="top"
                            required
                            .value=${this._organizationEmailSetting?.dailyCounter}
                        ></se-number-editor>
                        <se-checkbox-editor
                            id="isAllowExternalRecipients"
                            .value="${this._organizationEmailSetting?.isAllowExternalRecipients ?? false}"
                            label="Allow recipients outside of the organization"
                            labelPosition="right"
                        ></se-checkbox-editor>
                    </div>
                    <div class="savePanel">
                        <se-primary-button
                            .action="${() => this.saveAsync()}"
                            action-delay="500"
                            text="Save Email Settings"
                        ></se-primary-button>
                        <se-secondary-button @click="${() => this.close(false)}" text="Cancel"></se-secondary-button>
                    </div>
                </se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 7px;
        }
        .loading-container {
            min-width: 400px;
            min-height: 100px;
            background-color: white;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            align-items: center;
        }
    `;
}
