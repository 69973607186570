import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { BaseEditor } from "../editors/base-editor";
import { InputEditorElement } from "../editors/input-editor.element";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ConfigEmailAlertModel } from "../../models/config-email-alert-model";
import { ConfigApi } from "../../services/config.api";
import { OrganizationApi } from "../../services/organization.api";
import { NumberEditorElement } from "../editors/number-editor.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";

@customElement("se-agent-alerts-editor")
export class SeAgentAlertsEditorElement extends LitElement {
    @property({ type: Number }) configId: number;

    private _toasterService: ToasterService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _configApi: ConfigApi;
    private _orgApi: OrganizationApi;

    private _userEmails: string[];
    private _alertModel: ConfigEmailAlertModel;

    @state() private _isLoading = true;
    @state() private _isEnabled = false;

    @state() private _hasChanged = false;

    @query("#isEnabled") private _isEnabledEditor: CheckboxEditorElement;
    @query("#toAddresses") private _toAddressesEditor: InputEditorElement;
    @query("#ccAddresses") private _ccAddressesEditor: InputEditorElement;
    @query("#bccAddresses") private _bccAddressesEditor: InputEditorElement;
    @query("#isAlertOnSchedulesOnly") private _isAlertOnSchedulesOnlyEditor: CheckboxEditorElement;
    @query("#isAlertOnError") private _isAlertOnErrorEditor: CheckboxEditorElement;
    @query("#isAlertOnFirstErrorAfterSuccess") private _isAlertOnFirstErrorAfterSuccessEditor: CheckboxEditorElement;
    @query("#isAlertOnSuccess") private _isAlertOnSuccessEditor: CheckboxEditorElement;
    @query("#isAlertOnFirstSuccessAfterError") private _isAlertOnFirstSuccessAfterErrorEditor: CheckboxEditorElement;
    @query("#isAlertOnStart") private _isAlertOnStartEditor: CheckboxEditorElement;
    @query("#alertWhenRuntimeExceedsMinutes") private _alertWhenRuntimeExceedsMinutesEditor: NumberEditorElement;
    @query("#alertWhenErrorsExceeds") private _alertWhenErrorsExceedsEditor: NumberEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
        this._alertModel = container.resolve(ConfigEmailAlertModel);
        this._configApi = container.resolve(ConfigApi);
        this._orgApi = container.resolve(OrganizationApi);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadEmailAlertAsync();

        this.addEventListener("editorChanged", () => this.editorChanged());
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.removeEventListener("editorChanged", () => this.editorChanged());
    }

    private editorChanged() {
        this._hasChanged = true;
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async loadUsersAsync() {
        if (!this._userEmails) {
            const result = await this._orgApi.getAllOrganizationUserEmailsAsync();
            if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            } else {
                this._userEmails = result.value.map((m) => `${m.name} <${m.email}>`);
            }
        }
        return this._userEmails;
    }

    private async loadEmailAlertAsync() {
        const result = await this._configApi.getConfigEmailAlertAsync(this.configId);
        if (result.isErr) {
            this._toasterService.showNetworkError(result.err);
        } else {
            this._alertModel = result.value;
            this._isEnabled = this._alertModel.isEnabled;
        }
        this._isLoading = false;
    }

    private async saveAsync() {
        if (this._isEnabledEditor.liveValue && !this._toAddressesEditor.liveValue.trim()) {
            this._toAddressesEditor.setCustomValidity("Please specify at least one email address.");
            return;
        } else {
            this._toAddressesEditor.setCustomValidity("");
        }
        if (this._isEnabledEditor.liveValue && this._alertWhenErrorsExceedsEditor.liveValue < 1) {
            this._alertWhenErrorsExceedsEditor.reportValidity();
            return;
        }
        if (this._isEnabledEditor.liveValue && this._alertWhenRuntimeExceedsMinutesEditor.liveValue < 1) {
            this._alertWhenRuntimeExceedsMinutesEditor.reportValidity();
            return;
        }

        this._alertModel.toAddresses = this._toAddressesEditor.liveValue.trim() ?? undefined;
        this._alertModel.ccAddresses = this._ccAddressesEditor.liveValue.trim() ?? undefined;
        this._alertModel.bccAddresses = this._bccAddressesEditor.liveValue.trim() ?? undefined;

        this._alertModel.alertWhenErrorsExceeds = this._alertWhenErrorsExceedsEditor.liveValue;
        this._alertModel.alertWhenRuntimeExceedsMinutes = this._alertWhenRuntimeExceedsMinutesEditor.liveValue;
        this._alertModel.isAlertOnError = this._isAlertOnErrorEditor.liveValue;
        this._alertModel.isAlertOnFirstErrorAfterSuccess = this._isAlertOnFirstErrorAfterSuccessEditor.liveValue;
        this._alertModel.isAlertOnFirstSuccessAfterError = this._isAlertOnFirstSuccessAfterErrorEditor.liveValue;
        this._alertModel.isAlertOnSchedulesOnly = this._isAlertOnSchedulesOnlyEditor.liveValue;
        this._alertModel.isAlertOnStart = this._isAlertOnStartEditor.liveValue;
        this._alertModel.isAlertOnSuccess = this._isAlertOnSuccessEditor.liveValue;
        this._alertModel.isEnabled = this._isEnabledEditor.liveValue;

        const result = await this._configApi.updateConfigEmailAlertAsync(this._alertModel);
        if (result.isErr) {
            this._toasterService.showNetworkError(result.err);
        } else {
            this._toasterService.showSuccess("Email alert settings saved successfully.");
            this._hasChanged = false;
        }
    }

    private isEnabledChange() {
        this._isEnabled = this._isEnabledEditor.liveValue;
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">Email Alert Settings</div>
                <se-loading-panel
                    style="min-height:0"
                    id="loadingPanel"
                    .loadingStyle=${{
                        boxShadow: "2px 2px 2px lightGray",
                        border: "1px solid gray",
                        borderRadius: "5px 5px",
                        backgroundColor: "white",
                        minHeight: "50px",
                        minWidth: "125px",
                    }}
                    .isLoading=${this._isLoading}
                >
                    <div style="height:100%; min-height:0; display:flex; flex-direction: column">
                        <div class="scroll-container">
                            <se-checkbox-editor
                                id="isEnabled"
                                label="Enabled"
                                labelPosition="right"
                                @editorChanged=${this.isEnabledChange}
                                .value=${this._alertModel.isEnabled}
                            ></se-checkbox-editor>

                            <se-typeahead-editor
                                id="toAddresses"
                                label="Send alerts to these email addresses (separate with semicolon)"
                                labelPosition="top"
                                .value=${this._alertModel.toAddresses}
                                .getTextOptions=${() => this.loadUsersAsync()}
                                ?disabled=${!this._isEnabled}
                                ?required=${this._isEnabled}
                                input-width="100%"
                            ></se-typeahead-editor>
                            <se-typeahead-editor
                                id="ccAddresses"
                                label="CC these email addresses (optional)"
                                labelPosition="top"
                                .value=${this._alertModel.ccAddresses}
                                .getTextOptions=${() => this.loadUsersAsync()}
                                ?disabled=${!this._isEnabled}
                                input-width="100%"
                            ></se-typeahead-editor>
                            <se-typeahead-editor
                                id="bccAddresses"
                                label="BCC these email addresses (optional)"
                                labelPosition="top"
                                .value=${this._alertModel.bccAddresses}
                                .getTextOptions=${() => this.loadUsersAsync()}
                                ?disabled=${!this._isEnabled}
                                input-width="100%"
                            ></se-typeahead-editor>

                            <se-checkbox-editor
                                id="isAlertOnSchedulesOnly"
                                label="Only send alerts when the agent is run by the scheduler"
                                labelPosition="right"
                                .value=${this._alertModel.isAlertOnSchedulesOnly}
                                ?disabled=${!this._isEnabled}
                            ></se-checkbox-editor>
                            <se-checkbox-editor
                                id="isAlertOnError"
                                label="Alert when agent runs fail"
                                labelPosition="right"
                                .value=${this._alertModel.isAlertOnError}
                                ?disabled=${!this._isEnabled}
                            ></se-checkbox-editor>
                            <se-checkbox-editor
                                id="isAlertOnFirstErrorAfterSuccess"
                                label="Alert when the first agent run fails after successful runs"
                                labelPosition="right"
                                .value=${this._alertModel.isAlertOnFirstErrorAfterSuccess}
                                ?disabled=${!this._isEnabled}
                            ></se-checkbox-editor>
                            <se-checkbox-editor
                                id="isAlertOnSuccess"
                                label="Alert when agent runs are successful"
                                labelPosition="right"
                                .value=${this._alertModel.isAlertOnSuccess}
                                ?disabled=${!this._isEnabled}
                            ></se-checkbox-editor>
                            <se-checkbox-editor
                                id="isAlertOnFirstSuccessAfterError"
                                label="Alert when the first agent run is successful after failed runs"
                                labelPosition="right"
                                .value=${this._alertModel.isAlertOnFirstSuccessAfterError}
                                ?disabled=${!this._isEnabled}
                            ></se-checkbox-editor>
                            <se-checkbox-editor
                                id="isAlertOnStart"
                                label="Alert when agent runs start"
                                labelPosition="right"
                                .value=${this._alertModel.isAlertOnStart}
                                ?disabled=${!this._isEnabled}
                            ></se-checkbox-editor>
                            <se-number-editor
                                id="alertWhenRuntimeExceedsMinutes"
                                label="Alert when agent runs have run for the specified number of minutes"
                                labelPosition="top"
                                .value=${this._alertModel.alertWhenRuntimeExceedsMinutes}
                                ?disabled=${!this._isEnabled}
                                unit="minutes"
                                input-width="75px"
                                min="1"
                            ></se-number-editor>
                            <se-number-editor
                                id="alertWhenErrorsExceeds"
                                label="Alert when agent runs have encountered the specified number of errors"
                                labelPosition="top"
                                .value=${this._alertModel.alertWhenErrorsExceeds}
                                ?disabled=${!this._isEnabled}
                                unit="errors"
                                input-width="75px"
                                min="1"
                            ></se-number-editor>
                        </div>
                        <div class="savePanel">
                            <se-primary-button
                                .action="${() => this.saveAsync()}"
                                action-delay="500"
                                text="Save Alert Settings"
                                ?disabled=${!this._hasChanged}
                            ></se-primary-button>
                        </div>
                        <br />
                    </div>
                </se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            min-height: 0;
            min-width: 300px;
            overflow: auto;
            padding: 25px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 7px;
        }
    `;
}
