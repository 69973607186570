import { container, inject, singleton } from 'tsyringe';
import { SubscriptionPlanApi } from './subscription-plan-api';


@singleton()
export class SubscriptionPlanService {

    private _subscriptionPlanApi: SubscriptionPlanApi;

    get api() { return this._subscriptionPlanApi };
    
    constructor() {        
        this._subscriptionPlanApi = container.resolve(SubscriptionPlanApi);
    }    


}