import { Router } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../services/auth.service";
import { MenuService } from "../services/menu.service";
import { MenuItem } from "./components/context-menu.element";
import "./destinations/destinations-editor.element";
import "./destinations/destinations.element";
import "./destinations/oAuthResponse.element";
import "./editor-servers/editor-server-editor.element";
import "./proxies/proxies-editor.element";
import "./proxies/proxies.element";
import "./server-groups/server-group-editor.element";
import "./server-groups/server-groups.element";
import "./server-keys/server-key-editor.element";
import "./server-keys/server-keys.element";
import "./servers/organization-servers.element";
import "./servers/server-editor.element";
import "./servers/servers.element";
import "./users-groups/users-group-editor.element";
import "./users-groups/users-groups.element";
import "./organizations/organizations.element";
import "./rate-limits/rate-limits.element";
import "./user/user-api-keys.element";
import "./users/api-keys.element";
import "./user/user-logout.element";
import "./spaces/dashboard.element";
import "./audit-log/audit-log.element";
import "./app-config/app-settings.element";
import "./app-config/app-settings.element";
import "./users/invite-user.element";
import "./users/complete-verification.element";
import "./users/email-verification.element";
import "./users/pending-verification.element";
import "./organizations/organization-personal-agents.element";

//import './users/organization-users.elements';

@customElement("se-top-menu")
export class SeTopMenuElement extends LitElement {
    private _authService: AuthService;
    private _menuService: MenuService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._menuService = container.resolve(MenuService);
    }

    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private adminMenu() {
        return [
            {
                text: "Servers",
                action: () => {
                    Router.go(`/admin/servers`);
                },
            },
            { text: "-" },
            {
                text: "All Runs",
                action: () => {
                    Router.go(`/admin/all-runs`);
                },
            },
            { text: "-" },
            {
                text: "Organizations",
                action: () => {
                    Router.go(`/admin/organizations`);
                },
            },
            {
                text: "Subscriptions",
                action: () => {
                    Router.go(`/admin/subscriptions`);
                },
            },
            { text: "-" },
            {
                text: "Rate Limits",
                action: () => {
                    Router.go(`/admin/rate-limits`);
                },
            },
            { text: "-" },
            {
                text: "Settings",
                action: () => {
                    Router.go(`/admin/app-settings`);
                },
            },
            {
                text: "Server Keys",
                action: () => {
                    Router.go(`/admin/server-keys`);
                },
            },
        ];
    }
    private orgMenu() {
        return [
            {
                text: "Users",
                action: () => {
                    Router.go(`/manage/org-users`);
                },
            },
            { text: "-" },
            {
                text: "All Runs",
                action: () => {
                    Router.go(`/org-runs`);
                },
            },
            { text: "-" },
            {
                text: "Proxy Pools",
                action: () => {
                    Router.go(`/proxies`);
                },
            },
            { text: "-" },
            {
                text: "Destinations",
                action: () => {
                    Router.go(`/destinations`);
                },
            },
            { text: "-" },
            {
                text: "Spaces",
                action: () => {
                    Router.go(`/org/spaces`);
                },
            },
            { text: "-", hidden: !this._authService.isOrgAdmin || !this._authService.orgSettings.isAuditLog},
            {
                text: "Audit Log",
                hidden: !this._authService.isOrgAdmin || !this._authService.orgSettings.isAuditLog,
                action: () => {
                    Router.go(`/audit-log`);
                },
            },
            { text: "-" },
            {
                text: "Billing",
                action: () => {
                    Router.go(`/billing`);
                },
            },

            /*{ text: '-' },
            { text: 'Servers', action: () => { Router.go(`/manage/org-servers`); } },
            { text: 'Server Groups', action: () => { Router.go(`/manage/server-groups`); } },
            { text: '-' },
            { text: 'Billing' },
            { text: 'Subscription' },
            { text: '-' },
            { text: 'Edit' }*/
        ];
    }
    private settingMenu() {
        return [{ text: "General" }, { text: "Database" }, { text: "Email" }, { text: "Authentication" }];
    }
    private helpMenu() {
        return [
            { text: "Support", action: () => this.openLink("https://sequentum.atlassian.net/servicedesk/customer/portal/7") },
            { text: "-" },
            { text: "About" }
        ];
    }
    private accountMenu() {
        return [
            { text: "Logout", action: this.onLogout.bind(this) },
            { text: "Change Password", action: this.onChangePassword.bind(this) },
            { text: "-" },
            { text: "Edit" },
        ];
    }
    private openLink(link) {
        window.open(link, '_blank');
    }
    private onLogout() {
        this._authService.logout();
        document.location = "/login";
    }

    private onResetPssword() {
        //this._authService.logout();
        Router.go("/reset-password");
    }
    private onChangePassword() {
        //this._authService.logout();
        Router.go("/change-password");
    }

    private onProxyPools() {
        alert("Proxy Pools");
    }

    private openAccountMenu(event: MouseEvent) {
        this.openMenu(event, this.accountMenu());
    }
    private openHelpMenu(event: MouseEvent) {
        this.openMenu(event, this.helpMenu());
    }
    private openSettingMenu(event: MouseEvent) {
        this.openMenu(event, this.settingMenu());
    }
    private openOrgMenu(event: MouseEvent) {
        this.openMenu(event, this.orgMenu());
    }
    private openAdminMenu(event: MouseEvent) {
        this.openMenu(event, this.adminMenu());
    }
    private openMenu(event: MouseEvent, menu: MenuItem[]) {
        event.stopPropagation();
        this._menuService.openContextMenu({
            isMouseEnter: event.type === "mouseenter",
            button: event.target as HTMLElement,
            activeClass: "menu-active",
            menu: menu,
            group: "top",
        });
    }

    render() {
        return html`
            ${this._authService.isOrgAdmin
                ? html`<div class="menu-item" @mouseenter=${this.openOrgMenu} @mousedown=${this.openOrgMenu}>Organization</div>`
                : html``}
            <div class="menu-item" @mouseenter=${this.openHelpMenu} @mousedown=${this.openHelpMenu}>Help</div>
            ${this._authService.isSE4Admin
                ? html` <div class="menu-item" @mouseenter=${this.openAdminMenu} @mousedown=${this.openAdminMenu}>Admin</div>`
                : html``}
            <div class="account" @mouseenter=${this.openAccountMenu} @mousedown=${this.openAccountMenu}>
                <fa-icon scale="1.4" fa-class="fas fa-user-circle"></fa-icon>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            gap: 10px;
            align-items: center;
            font: var(--font-menu);
        }
        .menu-item {
            cursor: default;
            padding: 2px 5px 2px 5px;
        }
        .menu-item:hover {
            background-color: var(--color-primary-tint);
            border-radius: 5px;
        }
        .menu-active {
            background-color: var(--color-primary-tint);
            border-radius: 5px;
        }
    `;
}
