import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ConfigType } from "../../enums/config-type";
import { isReadyToRun, RunStatus } from "../../enums/run-status";
import { ValidationStatus } from "../../enums/validation-status";
import { ConfigSummaryViewModel } from "../../models/config-summary-view-model";
import { AuthService } from "../../services/auth.service";
import { ConfigApi } from "../../services/config.api";
import { ConfigService } from "../../services/config.service";
import { MenuService } from "../../services/menu.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import "../components/data-grid.element";
import { SeDataGrid } from "../components/data-grid.element";
import "../components/pagination.element";
import { SePaginationElement } from "../components/pagination.element";
import { MultiSelectEditor } from "../editors/multi-select-editor.element";

@customElement("se-personal-agents")
export class SePersonalAgentsElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _configService: ConfigService;
    private _configApi: ConfigApi;
    private _menuService: MenuService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 20;
    private _totalRecordCount: number;
    private _sortColumn = "created";
    private _sortOrder = 1;

    @state() private _data: ConfigSummaryViewModel[];
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("se-pagination") private _dataPagination: SePaginationElement;
    @query("#multiSelectStatus") private _multiSelectStatus: MultiSelectEditor;

    private filters: {
        status?: string;
    } = {};

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._configApi = container.resolve(ConfigApi);
        this._configService = container.resolve(ConfigService);
        this._menuService = container.resolve(MenuService);
    }

    async connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -5;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        await this.loadDataAsync();
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
        return undefined;
    }

    private async loadDataAsync(isForceRefresh = true) {
        this._columns = [
            {
                field: "configType",
                cellStyle: { width: "0px", "text-align": "center" },
                title: "",
                template: (row) => this.getIconTemplate(row.validationStatus),
            },
            {
                field: "name",
                title: "Agent Name",
                sortable: true,
                action: (row) => this.openDetails(row),
                template: (row, col) =>
                    html`<a href="javascript:;" @click=${() => col.action(row, col)}
                            ><span
                                style="${row.name?.length > 50
                                    ? "text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 20vw;display: inline-block;"
                                    : ""}"
                                title="${row.name}"
                                >${row.name}</span
                            ></a>`,
            },
            {
                field: "configUserName",
                title: "Owner",
                sortable: true,
            },
            {
                field: "created",
                title: "Created",
                sortable: true,
                align: "center",
                template: (row) => html`${new Date(row.created).toLocaleDateString()} ${new Date(row.created).toLocaleTimeString()}`,
            },
            {
                field: "lastActivity",
                title: "Last Activity",
                sortable: true,
                align: "center",
                template: (row) => html`${row.lastActivity ? new Date(row.lastActivity).toLocaleDateString() + " " + new Date(row.lastActivity).toLocaleTimeString() : 'Never'}`,
            },
            {
                field: "status",
                title: "Status",
                align: "center",
                sortable: true,
                template: (row) => this.getStatusTemplate(row),
            }
        ];

        if (isForceRefresh) this._isLoading = true;

        try {
            const result = await this._configApi.getPersonalAgentsAsync(
                this._pageIndex,
                this._recordsPerPage,
                this._sortColumn,
                this._sortOrder,
                JSON.stringify(this.filters)
            );

            if (result.isOk) {
                this._data = result.value.configSummaries;
                this._totalRecordCount = result.value.totalRecordCount;
                this.resetPagination();
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }

    private getIconTemplate(validationStatus: ValidationStatus) {
        return html`<span
            ${validationStatus !== ValidationStatus.Valid
                ? htmlTitle(validationStatus === ValidationStatus.Invalid ? "Agent is invalid" : "Agent has not been validated")
                : undefined}
            ><fa-icon
                class="icon"
                single-color=${validationStatus === ValidationStatus.Valid
                    ? "gray"
                    : validationStatus === ValidationStatus.Invalid
                      ? "salmon"
                      : "goldenrod"}
                fa-class="far fa-robot"
            ></fa-icon
        ></span>`;
    }

    private getStatusTemplate(row: ConfigSummaryViewModel) {
        return html`<a href="javascript:;" style="width: 100%; max-width: 80px; text-decoration: none;"
            >${this.getStatusHtml(row.status)}</a>`;
    }

    private getStatusHtml(status: RunStatus) {
        switch (status) {
            case undefined:
            case null:
                return html`<se-status status-message="No Activity" status-color="--color-gray-4"></se-status>`;
            case RunStatus.success:
                return html`<se-status status-message="Success" status-color="--color-status-blue"></se-status>`;
            case RunStatus.failure:
            case RunStatus.failed:
                return html`<se-status status-message="Failed" status-color="--color-status-red"></se-status>`;
            case RunStatus.completed:
                return html`<se-status status-message="Completed" status-color="--color-status-blue"></se-status>`;
            case RunStatus.running:
                return html`<se-status status-message="Running" status-color="--color-purple"></se-status>`;
            case RunStatus.exporting:
                return html`<se-status status-message="Exporting" status-color="--color-purple"></se-status>`;
            case RunStatus.starting:
                return html`<se-status status-message="Starting" status-color="--color-purple"></se-status>`;
            case RunStatus.stopped:
                return html`<se-status status-message="Stopped" status-color="--color-gray-4"></se-status>`;
            case RunStatus.stopping:
                return html`<se-status status-message="Stopping" status-color="--color-purple"></se-status>`;
            case RunStatus.queuing:
                return html`<span style="color:var(--color-gray-4);text-decoration: underline;">Queuing</span>`;
            case RunStatus.waiting:
                return html`<span style="color:teal;text-decoration: underline;">Waiting</span>`;
            default:
                return html`${status}`;
        }
    }

    private openDetails(row: ConfigSummaryViewModel) {
        this._userState.selectedSpaceId = 0;
        Router.go(`/space/0/config/${row.id}/details/runs`);
    }

    private openActiveRuns(row: ConfigSummaryViewModel) {
        this._userState.selectedSpaceId = 0;
        Router.go(`/space/0/config/${row.id}/details/runs`);
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        this._sortColumn = evt.detail.sortColumn;
        this._sortOrder = evt.detail.sortOrder;
        this.loadDataAsync();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadDataAsync();
    }

    private resetPagination() {
        this._dataPagination.pageIndex = this._pageIndex;
    }

    private refresh() {
        this.loadDataAsync();
    }

    private runStatusOptions() {
        const selectedStatuses = this.filters["status"]?.split(',').map(Number) || [];
        const opts = Object.keys(RunStatus)
            .filter(key => isNaN(Number(key)))
            .map(key => ({
                id: RunStatus[key as keyof typeof RunStatus],
                name: key.charAt(0).toUpperCase() + key.slice(1),
                value: selectedStatuses.includes(RunStatus[key as keyof typeof RunStatus])
            }))
            .sort((a, b) => a.name.localeCompare(b.name))
        return opts;
    }

    private handleStatusChange(load = true) {
        const selectedOptions: number[] = this._multiSelectStatus.selectedValues;
        this.filters["status"] = selectedOptions.join(',');
        this.updateURLQueryParam("status", this.filters["status"]);
        if (load) this.loadDataAsync(false)
    }

    private handleSelectAllStatus(load = true) {
        const selectedOptions: number[] = Object.keys(RunStatus)
            .filter(key => isNaN(Number(key)))
            .map(key => RunStatus[key as keyof typeof RunStatus]);
        this.filters["status"] = selectedOptions.join(',');
        this.updateURLQueryParam("status", this.filters["status"]);
        if (load) this.loadDataAsync(false)
    }

    private handleClearAllStatus(load = true) {
        const selectedOptions: number[] = [];
        this.filters["status"] = selectedOptions.join(',');
        this.updateURLQueryParam("status", this.filters["status"]);
        if (load) this.loadDataAsync(false)
    }

    private updateURLQueryParam(key: string, value: string) {
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        window.history.pushState(null, '', url.toString());
    }

    render() {
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-secondary-button
                            @click=${this.refresh}
                            ${htmlTitle("Refresh")}
                            .customStyle=${{margin: 0 }}
                            icon="far fa-redo"
                        ></se-secondary-button>
                        <se-multi-select-editor 
                            id="multiSelectStatus"
                            @valueChanged=${this.handleStatusChange}
                            @selectAll=${this.handleSelectAllStatus}
                            @clearAll=${this.handleClearAllStatus}
                            .isSelectAll=${true}
                            .optionsName=${"Status"}
                            .options=${this.runStatusOptions()}>
                        </se-multi-select-editor>
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    .defaultSorted=${this._sortColumn}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No personal agents available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
                <se-pagination .recordCount=${this._totalRecordCount} .recordsPerPage=${this._recordsPerPage} @pagechanged=${this.onPageChanged}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: var(--color-primary);
        }
        .header {
            display: flex;
            justify-content: space-between;
            padding: 5px 0;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
            box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.10);
        }
    `;
} 
