import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css, TemplateResult } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { when } from "lit/directives/when.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { isRunningOnServer, RunStatus } from "../../enums/run-status";
import { RunFileViewModel } from "../../models/run-file-model";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { RunDetailsViewModel, RunViewModel } from "../../models/run-view-model";
import "../components/secondary-button-link.element";
import prettyBytes from "pretty-bytes";
import { RunHistoryService } from "../../services/run-history.service";
import downloadIcon from "../../../../assets/download-icon.svg";
import { ParallelExport } from "../../enums/parallel-export";
import { SeDataGrid } from "../components/data-grid.element";
import { formatSize } from "se-shared/utils/utils";
import { RunService } from "../../services/run.service";

@customElement("se-run-history-details")
export class RunDetailsElement extends LitElement {
    private _runHistoryService: RunHistoryService;
    private _runService: RunService;
    private _userState: UserState;
    private _toasterService: ToasterService;

    private _columns: DataGridColumn[] = [];
    private _fileColumns: DataGridColumn[] = [];
    @state() private _data: RunDetailsViewModel;
    @state() private _files: RunFileViewModel[] = [];

    @property({ type: Number }) configId: number;
    @property({ type: Number }) runId: number;

    @query("#dataGrid") private _dataGrid: SeDataGrid;

    @state() private _isLoading = true;

    constructor() {
        super();
        this._runHistoryService = container.resolve(RunHistoryService);
        this._runService = container.resolve(RunService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {}

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadDataAsync();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private getStatus(color: string, text: string, row: any): TemplateResult {
        if (row.message && !isRunningOnServer(row.status)) {
            return html`<se-status
                ${htmlTitle(row.message, true)}
                status-message="${text}" 
                status-color="${color}" 
                style="width: 100%; max-width: 110px"
                dotted
                ></se-status>`;
        } else {
            return html`<se-status style="width: 100%; max-width: 110px" status-message="${text}" status-color="${color}"></se-status>`;
        }
    }

    private getRate(row) {
        /*const date = new Date(row.startTime);
        if (row.endTime) {
            const now = new Date(row.endTime);
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        } else {
            const now = new Date();
            return (row.pageCount / ((now.getTime() - date.getTime()) / 1000)).toFixed(2);
        }*/

        const seconds = row.runTimeSec ?? 0;
        return seconds ? (row.pageCount / seconds).toFixed(2) : "n/a";
    }

    private getStats(row: any): TemplateResult {
        if (!row.actionCount && !row.dataCount && !row.errorCount) {
            if (isRunningOnServer(row.status)) {
                return html`<fa-icon single-color="silver" fa-class="far fa-spinner fa-spin"></fa-icon>`;
            } else {
                return html`n/a`;
            }
        } else {
            const rate = this.getRate(row);
            const totalTime = this.formatTotalTime(row);
            return html`<span
                ${htmlTitle(
                    html` <style>
                            .stats-table td:nth-child(2) {
                                text-align: right;
                            }
                            .stats-table td:nth-child(1) {
                                padding-right: 5px;
                            }
                        </style>
                        <table class="stats-table">
                            <tr>
                                <td>Actions:</td>
                                <td>${row.actionCount}</td>
                            </tr>
                            <tr>
                                <td>Data:</td>
                                <td>${row.dataCount}</td>
                            </tr>
                            <tr>
                                <td>Errors:</td>
                                <td>${row.errorCount}</td>
                            </tr>
                        </table>
                        <hr />
                        <table class="stats-table">
                            <tr>
                                <td>Total Pages:</td>
                                <td>${row.pageCount}</td>
                            </tr>
                            <tr>
                                <td>Dynamic pages:</td>
                                <td>${row.dynamicPageCount}</td>
                            </tr>
                            <tr>
                                <td>Rate (pages/sec):</td>
                                <td>${rate}</td>
                            </tr>
                            <tr>
                                <td>Requests:</td>
                                <td>${row.requestCount}</td>
                            </tr>
                            ${row.inputCount
                                ? html`<tr>
                                      <td>Inputs:</td>
                                      <td>${row.inputCount}</td>
                                  </tr>`
                                : html``}
                            ${row.exportCount || row.exportCount === 0
                                ? html`<tr>
                                      <td>Exported data:</td>
                                      <td>${row.exportCount}</td>
                                  </tr>`
                                : html``}
                            <tr>
                                <td>Traffic:</td>
                                <td>${formatSize(row.traffic)}</td>
                            </tr>
                            ${row.parallelism > 1
                                ? html`<tr>
                                      <td>Parallel export:</td>
                                      <td>${row.parallelExport ?? "Combined"}</td>
                                  </tr>`
                                : html``}
                            <tr>
                                <td>${row.parallelism > 1 && row.parallelSet === 0 ? "Active" : "Run time"}:</td>
                                <td style="white-space: nowrap;">${totalTime}</td>
                            </tr>
                        </table>`,
                    true
                )}
                style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
                >a:${row.actionCount}&nbsp;d:${row.dataCount}&nbsp;e:${row.errorCount}</span
            >`;
        }
    }

    private getParallelism(row: RunViewModel): TemplateResult {
        return html`<span
            ${htmlTitle(
                html`<table>
                    <tr>
                        <td>Parallel sets:</td>
                        <td>${row.parallelism}</td>
                    </tr>
                    <tr>
                        <td>Concurrency:</td>
                        <td>${row.parallelMaxConcurrency ?? "Unlimited"}</td>
                    </tr>
                    <tr>
                        <td>Export:</td>
                        <td>${row.parallelExport ?? ParallelExport.Combined}</td>
                    </tr>
                </table>`,
                true
            )}
            style="border-bottom: 1px dotted;cursor:pointer;user-select: none;"
            >${row.parallelism}</span
        >`;
    }

    private formatTotalTime(row) {
        let seconds = 0;
        if (row.parallelism > 1 && row.parallelSet === 0 && row.startTime) {
            const endTime = row.endTime ? new Date(row.endTime) : new Date();
            const startTime = new Date(row.startTime);
            seconds = (endTime.getTime() - startTime.getTime()) / 1000;
        } else {
            seconds = row.runTimeSec ?? row.toalTime ?? 0;
        }

        if (!seconds) {
            return "n/a";
        }
        if (seconds < 60) {
            return Math.round(seconds) + " sec";
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = Math.round(seconds % 60);
            if (remainingSeconds === 0) {
                return minutes + " min";
            } else {
                return minutes + " min" + " " + remainingSeconds + " sec";
            }
        } else {
            const hours = Math.floor(seconds / 3600);
            const remainingMinutes = Math.floor((seconds % 3600) / 60);
            const remainingSeconds = Math.round(seconds % 60);
            let result = "";
            if (hours > 1) {
                result += hours + " hours";
            } else {
                result += hours + " hour";
            }
            if (remainingMinutes > 0) {
                result += " " + remainingMinutes + " min";
            }
            if (remainingSeconds > 0) {
                result += " " + remainingSeconds + " sec";
            }
            return result;
        }
    }

    //called when a user clicks the download button for a row
    private onDownloadFile(row: RunFileViewModel) {
        if (row === null) {
            return;
        }
        this._runService.api.downloadFile(row.id, false);
    }
    private onOpenFile(row: RunFileViewModel) {
        if (row === null) {
            return;
        }

        this._runService.api.downloadFile(row.id, true);
    }

    private async loadDataAsync() {
        //Load the columns for the run details
        this._columns = [
            { field: "sequence", title: "Sequence", align: "center" },
            { field: "serverHost", title: "Server" },
            { field: "proxyPoolName", title: "Proxy Pool" },
            {
                field: "parallelism",
                title: "Parallelism",
                align: "center",
                hidden: true,
                template: (row) =>
                    row.parallelSet
                        ? html`${row.parallelSet}/${row.parallelism}`
                        : (row.parallelism ?? 1) > 1
                          ? this.getParallelism(row)
                          : html``,
            },
            {
                field: "status",
                title: "Status",
                align: "center",
                template: (row, col) =>
                    html`${choose(
                        row.status,
                        [
                            [undefined, () => html``],
                            [null, () => html``],
                            [RunStatus.waiting, () => this.getStatus("teal", "Waiting", row)],
                            [RunStatus.queuing, () => this.getStatus("--color-gray-4", "Queuing", row)],
                            [RunStatus.starting, () => this.getStatus("--color-purple", "Starting", row)],
                            [RunStatus.success, () => this.getStatus("--color-status-blue", "Success", row)],
                            [RunStatus.stopping, () => this.getStatus("--color-purple", "Stopping", row)],
                            [RunStatus.stopped, () => this.getStatus("--color-gray-4", "Stopped", row)],
                            [RunStatus.failure, () => this.getStatus("--color-status-red", "Failure", row)],
                            [RunStatus.failed, () => this.getStatus("--color-status-red", "Failed", row)],
                            [RunStatus.completed, () => this.getStatus("--color-status-blue", "Completed", row)],
                            [RunStatus.running, () => this.getStatus("--color-purple", "Running", row)],
                            [RunStatus.exporting, () => this.getStatus("--color-purple", "Exporting", row)],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "startTime",
                title: "Started",
                align: "center",
                template: (row, col) => {
                    const date = new Date(row.startTime);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
            {
                field: "endTime",
                title: "Completed",
                align: "center",
                hidden: false,
                template: (row, col) => {
                    const date = row.endTime ? new Date(row.endTime) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },
            { field: "message", title: "Progress", hidden: true },
            { title: "Stats", align: "center", template: (row, col) => this.getStats(row) },
        ];

        //load the columns for the files
        this._fileColumns = [
            //{ field: 'fileName', title: 'File', align: 'left' },
            {
                cellStyle: { textAlign: "center", width: "20px" },
                action: (row) => this.onDownloadFile(row),
                template: () => {
                    return html`<img
                        style="width: 20px"
                        title="Download"
                        src=${downloadIcon}
                    ></img>`;
                },
            },
            {
                title: "File",
                template: (row, col) => {
                    return html`<a href="javascript:;" @click=${(event) => this.onOpenFile(row)}>${row.name}</a>`;
                },
            },
            {
                title: "Size",
                align: "right",
                template: (row, col) => {
                    return html`<a>${prettyBytes(row.fileSize)}</a>`;
                },
            },
            {
                field: "created",
                title: "Uploaded",
                align: "left",
                template: (row, col) => {
                    const date = new Date(row.created);
                    return html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                },
            },
        ];

        this._isLoading = true;
        try {
            const result = await this._runHistoryService.api.getAsync(this.runId);
            if (result.isOk) {
                this._data = result.value.run;
                this._files = result.value.files;
                this._columns.find((p) => p.field === "parallelism").hidden = !this._data?.parallelism || this._data.parallelism <= 1;
                this._columns.find((p) => p.field === "endTime").hidden = !this._data.endTime;
                this._columns.find((p) => p.field === "message").hidden = !isRunningOnServer(this._data.status);
                this._columns.find((p) => p.field === "proxyPoolName").hidden = !this._data.proxyPoolName;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
    }

    firstUpdated() {}

    render() {
        const rows = this._data ? [this._data] : this._data;

        return html`
            <div class="body">
                <se-data-grid
                    id="dataGrid"
                    class="grid"
                    .style="flex-shrink: 0;"
                    .rows=${rows}
                    .columns=${this._columns}
                    placeholder="No run details available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
                ${when(
                    this._files && this._files.length > 0,
                    () => html` <se-data-grid class="grid" .rows=${this._files} .columns=${this._fileColumns}></se-data-grid> `
                )}
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: block;
            height: 100%;
            font: var(--font);
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
            flex-shrink: 0;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .right-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex-shrink: 0;
        }
        .card {
            font: var(--font-small);
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            min-width: 0;
            min-height: 0;
            border: 1px solid darkgray;
            box-shadow: 2px 2px 2px lightGray;
            background-color: white;
            border-radius: 5px 5px 5px 5px;
        }
        .browser-container {
            width: 100%;
            height: 100%;
            min-width: 0;
            min-height: 0;
            display: grid;
            grid-auto-rows: 1fr;
            gap: 10px;
        }
    `;
}
