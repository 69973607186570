import { css, html, LitElement } from "lit";
import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { AgentTasksViewModel } from "../../models/agent-tasks-view-model";
import { AuthService } from "../../services/auth.service";
import { RunService } from "../../services/run.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { UserState } from "../../services/user.state";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { SePaginationElement } from "../components/pagination.element";
import { SortOrder } from "se-shared/enums/sort-order";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";

@customElement("se-all-schedules")
export class SeAllSchedulesElement extends LitElement {
    private _authService: AuthService;
    private _runService: RunService;
    private _toasterService: ToasterService;
    private _userState: UserState;
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _columns: DataGridColumn[] = [];
    
    private _pageIndex = 1;
    private _recordsPerPage = 15;
    private _totalRecordCount: number;
    private _sortColumn = "nextRunTime";
    private _sortOrder = 0;
    
    private _paginationInitialized = false;
    private _sortInitialized = false;
    
    @state() private _data?: AgentTasksViewModel[];
    @state() private _isLoading = true;
    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("se-pagination") private _pagination: SePaginationElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._runService = container.resolve(RunService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._menuService = container.resolve(MenuService);
        this._modalService = container.resolve(ModalDialogService);
    }

    public onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceId = -7;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        
        // Listen for URL initialization events from pagination and grid
        this.addEventListener('url-initialized', this._handlePaginationInitialized);
        this.addEventListener('sort-initialized', this._handleSortInitialized);
    }
    
    disconnectedCallback() {
        // Remove URL initialization event listeners
        this.removeEventListener('url-initialized', this._handlePaginationInitialized);
        this.removeEventListener('sort-initialized', this._handleSortInitialized);
        
        super.disconnectedCallback();
    }
    
    private _handlePaginationInitialized = (event: CustomEvent) => {
        this._paginationInitialized = true;
        
        // Update page index from the message
        if (event.detail && event.detail.pageIndex) {
            this._pageIndex = event.detail.pageIndex;
        }
        
        this._tryLoadData();
    }
    
    private _handleSortInitialized = (event: CustomEvent) => {
        this._sortInitialized = true;
        
        // Update local sort variables from the message
        if (event.detail) {
            const { sortColumn, sortOrder } = event.detail;
            if (sortColumn) {
                this._sortColumn = sortColumn;
                this._sortOrder = sortOrder === SortOrder.Ascending ? 1 : 0;
            }
        }
        
        this._tryLoadData();
    }
    
    private _tryLoadData() {
        // Load data when both components have initialized
        if (this._paginationInitialized && this._sortInitialized) {
            this.loadDataAsync();
        }
    }

    private formatDateTime(date?: Date): string {
        if (!date) return "N/A";
        const d = new Date(date);
        return `${d.toLocaleDateString()} ${d.toLocaleTimeString()}`;
    }

    private async loadDataAsync() {
        this._isLoading = true;
        this._columns = [
            { 
                field: "configName", 
                title: "Agent",
                sortable: true,
                template: (row) => html`<a href="javascript:;" @click=${() => this.openAgent(row)}>${row.configName}</a>`
            },
            { 
                field: "name", 
                title: "Schedule",
                sortable: true,
                template: (row) => html`<a href="javascript:;" @click=${() => this.openSchedule(row)}>${row.name}</a>`
            },
            {
                field: "organizationName",
                title: "Organization",
                sortable: true
            },
            {
                field: "spaceName",
                title: "Space",
                sortable: true,
                template: (row) => html`<a href="javascript:;" @click=${() => this.openSpace(row)}>${row.spaceName}</a>`
            },
            {
                field: "nextRunTime",
                title: "Next Run",
                sortable: true,
                template: (row) => html`${this.formatDateTime(row.nextRunTime)}`
            },
            {
                field: "startTime",
                title: "Last Run",
                sortable: true,
                template: (row) => {
                    if (!row.startTime) return html`N/A`;
                    return html`<a href="javascript:;" @click=${() => this.openRunDetails(row)}>
                        ${this.formatDateTime(row.startTime)}
                    </a>`;
                }
            },
            {
                name: "menu",
                cellStyle: { textAlign: "center", width: "20px" },
                menu: (row) => this.menu(row)
            }
        ];

        const body = {
            pageIndex: this._pageIndex,
            recordsPerPage: this._recordsPerPage,
            sortColumn: this._sortColumn,
            sortOrder: this._sortOrder,
        };

        try {
            const response = await this._runService.api.getAllSchedulesAsync(body);
            if (response.isOk) {
                this._data = response.value.schedules || [];
                this._totalRecordCount = response.value.totalRecordCount;
                if (this._pagination) {
                    this._pagination.recordCount = this._totalRecordCount;
                }
            } else {
                this._toasterService.showError("Failed to load schedules");
            }
        } catch (e) {
            this._toasterService.showError("Failed to load schedules: " + e);
        } finally {
            this._isLoading = false;
        }
        if (this._selectAll) this._selectAll.value = false;
    }

    private openSpace(row: AgentTasksViewModel) {
        Router.go(`/space/${row.spaceId}`);
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }

    private openAgent(row: AgentTasksViewModel) {
        Router.go(`/space/${row.spaceId}/config/${row.configId}/details/info`);
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }

    private openSchedule(row: AgentTasksViewModel) {
        Router.go(`/space/${row.spaceId}/config/${row.configId}/task/${row.id}`);
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }

    private openRunDetails(row: AgentTasksViewModel) {
        if (row.lastRunId) {
            //if negative, it's a run history id
            if (row.lastRunId < 0) {
                Router.go(`/space/${row.spaceId}/config/${row.configId}/details/run-history-details/run/${-row.lastRunId}`);
            } else {
                Router.go(`/space/${row.spaceId}/config/${row.configId}/details/run-details/run/${row.lastRunId}`);
            }
            this._userState.selectedSpaceId = row.spaceId;
            this._userState.selectedLabelId = -1;
            this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        }
    }

    private refresh() {
        this.loadDataAsync();
    }

    private menu(row: AgentTasksViewModel): MenuItem[] {
        return [
            {
                text: "Edit",
                action: this.editScheduleAsync.bind(this, row)
            },
            {
                text: "Delete",
                action: this.deleteScheduleAsync.bind(this, row)
            }
        ];
    }

    private editScheduleAsync(row: AgentTasksViewModel) {
        Router.go(`/space/${row.spaceId}/config/${row.configId}/task/${row.id}`);
        this._userState.selectedSpaceId = row.spaceId;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }

    private async deleteScheduleAsync(row: AgentTasksViewModel) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete",
            body: `Are you sure you want to delete schedule "${row.name}"?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._runService.api.deleteTaskAsync(row.configId, row.id);
            if (result.isOk) {
                const index = this._data.indexOf(row);
                this._data.splice(index, 1);
                this._dataGrid.requestUpdate();
            } else {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async onDeleteManyAsync(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Schedules",
            body: `Are you sure you want to delete ${count === 1 ? `1 schedule` : `${count} schedules`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const schedulesByConfigId = {};
            this._dataGrid.selectedRows.forEach((p) => {
                if (schedulesByConfigId[p.configId] === undefined) {
                    schedulesByConfigId[p.configId] = [];
                }
                schedulesByConfigId[p.configId].push(p.id);
            });

            for (const configId in schedulesByConfigId) {
                const taskIds = schedulesByConfigId[configId];
                const result = await this._runService.api.deleteTaskManyAsync(parseInt(configId), taskIds);
                if (result.isOk) {
                    this._selectAll.value = false;
                    this._hasSelectedRows = false;
                    this._data = this._data.filter((p) => !taskIds.includes(p.id));
                } else {
                    this._toasterService.showNetworkError(result.err);
                }
            }
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const newSortColumn = evt.detail.sortColumn || this._sortColumn;
        const newSortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : 0;
        
        if (newSortColumn !== this._sortColumn || newSortOrder !== this._sortOrder) {
            this._sortColumn = newSortColumn;
            this._sortOrder = newSortOrder;
            this.loadDataAsync();
        }
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadDataAsync();
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${(this._data?.length ?? 0) === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteManyAsync(event)}
                                      ${htmlTitle("Delete selected schedules")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                </div>

                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No scheduled runs available."
                    .isLoading=${this._isLoading}
                    .pageIndex=${this._pageIndex}
                    .sortColumn=${this._sortColumn}
                    .sortOrder=${this._sortOrder === 1 ? SortOrder.Ascending : SortOrder.Descending}
                    .syncWithUrl=${true}
                ></se-data-grid>
                <se-pagination
                    .recordCount=${this._totalRecordCount}
                    .recordsPerPage=${this._recordsPerPage}
                    @pagechanged=${this.onPageChanged}
                    .pageIndex=${this._pageIndex}
                    .syncWithUrl=${true}
                ></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
    `;
} 