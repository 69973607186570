import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { UserService } from "../../services/user.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { InputEditorElement } from "../../elements/editors/input-editor.element";
import { SelectEditorElement } from "../../elements/editors/select-editor.element";
import { AuthService } from "../../services/auth.service";
import "../components/cards/edit-form-card.element";
import "../components/cards/card-buttons.element";
import { FormInputEditorElement } from "../editors/form/form-input-editor.element";
import { FormSelectEditorElement } from "../editors/form/form-select-editor.element";
import { OrganizationService } from "../../services/organization.service";
import { OrganizationViewModel } from "../../models/organization-view-model";

@customElement("se-invite-user")
export class SeInviteUserElement extends LitElement {
    private _userService: UserService;
    private _toasterService: ToasterService;
    private _authService: AuthService;
    private _organizationService: OrganizationService;
    
    @state() private _isLoading = true;
    @state() private _organization?: OrganizationViewModel;
    @state() private _selectedAuthenticationService: string;

    @query("#email") private _emailEditor: FormInputEditorElement;
    @query("#accessLevel") private _accessLevelEditor: FormSelectEditorElement;

    private _accessOptions = [];
    private _authenticationTypes = [];

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userService = container.resolve(UserService);
        this._toasterService = container.resolve(ToasterService);
        this._organizationService = container.resolve(OrganizationService);
        this._selectedAuthenticationService = this._authService.loginType !== undefined 
            ? this._authService.loginType.toString() 
            : "1";
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }

    private async loadData() {
        try {
            const res = await this._organizationService.api.getCurrentOrganizationAsync();
            if (res.isOk) {
                this._organization = res.value;
                
                // Only show Okta option if SsoId is configured
                this._authenticationTypes = [
                    { id: 1, name: "Local" },
                    { id: 2, name: "Google" },
                    { id: 3, name: "Microsoft" }
                ];
                
                if (this._organization.ssoId) {
                    this._authenticationTypes.push({ id: 4, name: "Okta" });
                }
            } else {
                this._toasterService.showError(res.err.message || "Failed to load organization details");
            }
        } catch (err) {
            this._toasterService.showNetworkError(err);
        } finally {
            this._isLoading = false;
            this.initializeAccessOptions();
        }
    }

    private initializeAccessOptions() {
        if (!this._authService.isOrgAdmin) {
            document.location = "/login";
            return;
        }

        this._accessOptions = [
            { id: 300, name: "User" },
            { id: 200, name: "Administrator" },
        ];

        if (this._authService.isSE4Admin) {
            this._accessOptions.push({ id: 100, name: "SE4 Administrator" });
        }
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            document.location = "/login";
            return commands.prevent();
        }

        this.initializeAccessOptions();
    }

    private async sendInvite() {
        if (!this.reportValidity()) return;

        try {
            const result = await this._userService.api.inviteUserAsync({
                email: this._emailEditor.liveValue,
                accessLevel: Number(this._accessLevelEditor.liveValue),
                authenticationService: Number(this._selectedAuthenticationService)
            });

            if (result.isOk) {
                this._toasterService.showSuccess("Invitation sent successfully");
                Router.go('/org/users');
            } else {
                this._toasterService.showError(result.err.message || "Failed to send invitation");
            }
        } catch (err) {
            this._toasterService.showNetworkError(err);
        }
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as any)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private _onAuthenticationServiceChange(e: CustomEvent) {
        this._selectedAuthenticationService = e.detail.value;
        this.requestUpdate();
    }

    render() {
        return html`
            <se-loading-panel
                .loadingStyle=${{
                    boxShadow: "2px 2px 2px lightGray",
                    border: "1px solid gray",
                    borderRadius: "5px 5px",
                    backgroundColor: "white",
                    minHeight: "50px",
                    minWidth: "125px",
                }}
                .isLoading=${this._isLoading}
            >
                <se-edit-form-card cardTitle="Invite User">
                    <se-form-input-editor
                        id="email"
                        name="email"
                        type="text"
                        label="Email"
                        labelHelp="Enter the email address of the user you want to invite"
                        input-type="email"
                        required
                        size="30"
                    ></se-form-input-editor>

                    <se-form-select-editor
                        id="accessLevel"
                        name="accessLevel"
                        required
                        label="Group"
                        labelHelp="Select the user's access level and permissions group"
                        .value="300"
                        .options=${this._accessOptions}
                        width="100%"
                    ></se-form-select-editor>
                    
                    <se-form-select-editor
                        id="authenticationService"
                        name="authenticationService"
                        required
                        label="Authentication"
                        labelHelp="Password or Single Sign-On authentication method"
                        .value=${this._selectedAuthenticationService}
                        .options=${this._authenticationTypes}
                        width="100%"
                        @valueChanged=${(e: CustomEvent) => this._onAuthenticationServiceChange(e)}
                    ></se-form-select-editor>

                    ${this._selectedAuthenticationService === "4" ? html`
                        <se-form-input-editor
                            id="ssoId"
                            name="ssoId"
                            type="text"
                            label="SSO ID"
                            labelHelp="Organization's SSO identifier"
                            input-type="text"
                            disabled
                            .value=${this._organization?.ssoId ?? ""}
                            size="30"
                        ></se-form-input-editor>
                    ` : ""}
                </se-edit-form-card>

                <se-card-buttons>
                    <se-primary-button
                        .action="${() => this.sendInvite()}"
                        action-delay="500"
                        text="Send Invitation"
                    ></se-primary-button>
                    <se-secondary-button 
                        @click="${() => Router.go('/org/users')}" 
                        text="Cancel"
                    ></se-secondary-button>
                </se-card-buttons>
            </se-loading-panel>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
} 
