import { LitElement, html, css, unsafeCSS} from "lit";
import { customElement, query } from "lit/decorators.js";
import { container } from "tsyringe";
import { Router, RouterLocation } from "@vaadin/router";
import { UserService } from "../../services/user.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { InputEditorElement } from "../../elements/editors/input-editor.element";
import backgroundMaze from "../../../../assets/backgroundMaze.png";
import logo from "../../../../assets/logo-tagline.svg";
import { AppConfigService } from "../../services/app-config.service";
import okta from "../../../../assets/okta.jpg";
import mslogo from "../../../../assets/ms-logo.png";
import googlelogo from "../../../../assets/google_logo.png";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { SePrimaryButton } from "../components/primary-button.element";

@customElement("se-complete-verification")
export class SeCompleteVerificationElement extends LitElement {
    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        :host * {
            box-sizing: border-box;
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .loginButton {
            margin-top: 10px;
        }
        .inputEditor {
            width: 100%;
        }
        .loginbuttons{
            margin-bottom:10px; 
            margin-top: 10px; 
            display: flex; 
            border: 2px solid #1c74ec; 
            width: 80%; 
            height:36px; 
            border-radius: 5px; 
            background-color:#1c74ec; 
            align-items:center";   
        }
        .loginbuttons:hover{
            background-color: #5194ee;
            cursor: pointer
        }
        .linkLabel {
            display: flex;
            font: var(--font-small);
            align-items: right;
            text-decoration: none;
            padding-top: 15px;
            flex-direction: row;
            align-items: center;
        }
    `;
    @query("#name") private _nameEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#confirmPassword") private _confirmPasswordEditor: InputEditorElement;
    @query("#completeBtn") private _completeVerificationBtn: SePrimaryButton;

    private _appConfigService = container.resolve(AppConfigService);
    private _userService = container.resolve(UserService);
    private _toasterService = container.resolve(ToasterService);
    private _token: string;
    private _loginType: number = 0;
    private _eulaAccepted = false;

    async onBeforeEnter(location: RouterLocation) {
        const params = new URLSearchParams(location.search);
        this._token = params.get('token');

        if (!this._token) {
            Router.go('/login');
        }

        await this._userService.getAuthServiceType(this._token)
            .then((res) => {
                this._loginType = res.value;
            })
            .catch((err) => this._toasterService.showError(err));
    }

    connectedCallback() {
        super.connectedCallback();
    }

    buttonHtml() {
        if (this._loginType == 1) {
            return html`<se-primary-button
                            @click="${this.completeVerification}"
                            action-delay="500"
                            text="Complete Registration"
                            class="inputEditor loginButton"
                            size="normal"
                            id="completeBtn"
                        ></se-primary-button>
                        `;
        }
        else if (this._loginType == 2) {
            return html`
            <div style="display:flex; justify-content:center">
                ${this._appConfigService.isGoogleAuthenticationSupport ?
                    html`<div class="loginbuttons" @click="${this.completeVerification}">
                    <div style="background-color:white; border-top-left-radius: 3px; border-bottom-left-radius: 3px">
                        <img src=${googlelogo} style="padding: 2px;height: 32px; width:32px;"></img>
                    </div>
                    <div style="width:100%; display:flex;align-items:center;justify-content:center;">
                        <label style="color:white; font-size:16px; cursor: pointer;">Sign Up with Google</label>
                    </div>
                </div>` : ``}
            </div>
            `;
        }
        else if (this._loginType == 3) {
            return html`
            <div style="display:flex; justify-content:center">
                ${this._appConfigService.isMicrosoftAuthenticationSupport ?
                    html`<div class="loginbuttons" @click="${this.completeVerification}">
                    <div style="background-color:white; border-top-left-radius: 3px; border-bottom-left-radius: 3px">
                        <img src=${mslogo} style="padding: 2px;height: 32px; width:32px;"></img>
                    </div>
                    <div style="width:100%; display:flex;align-items:center;justify-content:center;">
                        <label style="color:white; font-size:16px; cursor: pointer;">Sign Up with Microsoft</label>
                    </div>
                </div>` : ``}
            </div>
            `;
        }
        else if (this._loginType == 4) {
            return html`
            <div style="display:flex; justify-content:center">
                ${this._appConfigService.isOktaAuthenticationSupport ?
                    html`<div class="loginbuttons" @click="${this.completeVerification}">
                    <div style="background-color:white; border-top-left-radius: 3px; border-bottom-left-radius: 3px">
                        <img src=${okta} style="padding: 2px;height: 32px; width:32px;"></img>
                    </div>
                    <div style="width:100%; display:flex;align-items:center;justify-content:center;">
                        <label style="color:white; font-size:16px; cursor: pointer;">Sign Up with Okta</label>
                    </div>
                </div>` : ``}
            </div>
            `;
        }
        else {
            return html``;
        }
    }

    private onEulaChanged(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._eulaAccepted = true;
            } else {
                this._eulaAccepted = false;
            }
        }
    }

    private async showEULA(ev) {
        ev.preventDefault();
        this.openEULADialog();
        //Router.go("/login");
    }

    private openEULADialog() {
        fetch("./eula.txt")
            .then((response) => response.text())
            .then((eulaContent) => {
                // Open a new window
                const eulaWin = window.open("", "EULA");

                // Write the content of the EULA into the new window
                eulaWin.document.write(`
                    <!DOCTYPE html>
                    <html lang="en">
                    <head>
                        <meta charset="UTF-8">
                        <meta name="viewport" content="width=device-width, initial-scale=1.0">
                        <title>Sequentum EULA</title>
                        <style>
                            body {
                                font-family: Arial, sans-serif;
                                margin: 20px;
                                overflow-y: scroll;
                                max-height: 90vh;
                            }
                        </style>
                    </head>
                    <body>
                        <pre>${eulaContent}</pre>
                    </body>
                    </html>
                `);

                // Close the document after writing
                eulaWin.document.close();
            })
            .catch((error) => console.error("Error fetching EULA:", error));
    }

    render() {
        return html`
            <form id="verificationForm" class="editor">
                <div class="scroll-container">
                    <div class="content">
                        <div style="text-align: center;margin-bottom: 10px;">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <se-input-editor
                            id="name"
                            name="name"
                            type="text"
                            label="Full Name"
                            labelPosition="top"
                            required
                            class="inputEditor"
                            size="normal"
                        ></se-input-editor>
                        <div ?hidden=${this._loginType != 1}>
                            <se-input-editor
                                id="password"
                                name="password"
                                type="password"
                                input-type="password"
                                label="Password"
                                labelPosition="top"
                                required
                                class="inputEditor"
                                size="normal"
                            ></se-input-editor>

                            <se-input-editor
                                id="confirmPassword"
                                name="confirmPassword"
                                type="password"
                                input-type="password"
                                label="Confirm Password"
                                labelPosition="top"
                                required
                                class="inputEditor"
                                size="normal"
                            ></se-input-editor>
                        </div>
                        <div class="inputEditor" style="flex:1">
                            <div class="linkLabel">
                                <se-checkbox-editor
                                    id="eula"
                                    name="eula"
                                    .value=${this._eulaAccepted}
                                    @valueChanged=${this.onEulaChanged}
                                    tristate="auto"
                                ></se-checkbox-editor>

                                <a href="EULA" class="link" @click="${this.showEULA}" style="margin-left: 10px">I agree to EULA </a>
                            </div>
                        </div>
                        ${this._loginType != 1 ? html`
                            <div>
                                Your administrator has requested you to sign up with ${this._loginType == 2 ? html`Google.` : this._loginType == 3 ? html`Microsoft.`: html`Okta.`}
                            </div>
                        ` : ``
                        }
                        ${this.buttonHtml()}
                    </div>
                </div>
            </form>
        `;
    }

    private async completeVerification() {

        if (this._passwordEditor == null || this._confirmPasswordEditor == null || this._nameEditor == null) {
            return;
        }

        if (!this.reportValidity()) return;

        if (this._passwordEditor.liveValue !== this._confirmPasswordEditor.liveValue) {
            this._toasterService.showError("Passwords do not match");
            return;
        }

        //make sure that EULA has been accepted
        if (this._eulaAccepted !== true) {
            this._toasterService.showError("Please accept the EULA");
            return;
        }

        try {
            if (this._loginType == 1) {
                this._completeVerificationBtn.loading = true;
            }
            await this._userService.api.completeVerificationAsync({
                token: this._token,
                name: this._nameEditor.liveValue,
                password: this._passwordEditor.liveValue
            })
                .then((res) => {
                    if (this._loginType != 1) {
                        window.open(res.value, "_parent")
                    }
                    else {
                        this._toasterService.showSuccess("Registration completed successfully");
                        Router.go('/login');
                    }
                })
                .catch((err) => {
                    this._toasterService.showError(err || "Failed to complete registration");
                })
                .finally(() => {
                    if (this._loginType == 1) {
                        this._completeVerificationBtn.loading = false;
                    }
                });
                           
        } catch (err) {
            this._toasterService.showNetworkError(err);
        }
    }

    private reportValidity(): boolean {
        if (this._loginType != 1) {
            return this._nameEditor.reportValidity();
        }
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as any)?.reportValidity?.() === false) return false;
        }
        return true;
    }
} 