import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import instagram from "../../../../assets/instagram.svg";
import linkedin from "../../../../assets/linkedin.svg";
import logo from "../../../../assets/logo.png";
import twitter from "../../../../assets/twitter.svg";
import { AppConfigService } from "../../services/app-config.service";
import "../components/primary-button.element";
import "../components/secondary-button.element";
import { BaseEditor } from "../editors/base-editor";
import "../editors/input-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-create-first-user")
export class CreateFirstUserElement extends LitElement {
    private _appConfigService: AppConfigService;

    @query("#editorForm") private _editorForm: HTMLFormElement;
    @query("#saveButton") private _saveButton: HTMLButtonElement;
    @query("#cancelButton") private _cancelButton: HTMLButtonElement;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#companyName") private _companyNameEditor: InputEditorElement;
    @query("#email") private _emailEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#confirmPassword") private _comparePasswordEditor: InputEditorElement;

    @state() private _hasChanged = false;

    constructor() {
        super();
        this.submitLogin = this.submitLogin.bind(this);
        this.save = this.save.bind(this);

        this._appConfigService = container.resolve(AppConfigService);
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._appConfigService.isNew) {
            return commands.redirect("/login");
        } else {
            return undefined;
        }
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener('keydown', this.submitLogin);
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
    }
    disconnectedCallback() {
        this.addEventListener("editorChanged", (ev: CustomEvent) => this.onEditorChanged(ev));
        super.disconnectedCallback();
    }
    submitLogin(event) {
        if (event.key === 'Enter') {
            this.save(event);
        }
    }

    private onEditorChanged(ev) {
        this._hasChanged = true;
    }

    updateChangedValues() {}

    show() {
        this.hidden = false;
    }
    hide() {
        this.hidden = true;
    }

    firstUpdated() {}

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    private async save(ev) {
        ev.preventDefault();
        if (this.reportValidity()) {
            // validate password
            const passwordValidation = this._appConfigService.validatePassword(this._passwordEditor.liveValue);
            if (!passwordValidation.isValid) {
                alert(passwordValidation.message);
                return;
            }

            this._hasChanged = false;
            await this._appConfigService
                .createFirstUserAsync(
                    this._nameEditor.liveValue,
                    this._companyNameEditor.liveValue,
                    this._emailEditor.liveValue,
                    this._passwordEditor.liveValue
                )
                .then(() => this.success())
                .catch((err) => this.error(err));
        }
    }

    private success() {
        Router.go("/");
    }

    private error(err: Error) {
        alert(err.message);
        this._hasChanged = true;
    }

    passwordChanged() {
        // validate password strength
        const passwordValidation = this._appConfigService.validatePassword(this._passwordEditor.liveValue);
        if (!passwordValidation.isValid) {
            this._passwordEditor.setCustomValidity(passwordValidation.message);
            this._passwordEditor.reportValidity();
            return;
        } else {
            this._passwordEditor.setCustomValidity("");
            this._passwordEditor.reportValidity();
        }

        // check if passwords match
        if (this._passwordEditor.liveValue !== this._comparePasswordEditor.liveValue) {
            this._comparePasswordEditor.setCustomValidity("Passwords don't match.");
        } else {
            this._comparePasswordEditor.setCustomValidity("");
            this._comparePasswordEditor.reportValidity();
        }
    }

    render() {
        return html`
            <div class="header"><img src=${logo} class="header-logo" /></div>
            <form id="editorForm" class="editor">
                <p class="h3">Create Super Admin User</p>
                <div class="scroll-container">
                    <se-input-editor
                        class="inputEditor"
                        id="name"
                        name="name"
                        type="text"
                        label="Full name"
                        labelPosition="top"
                        required
                        size="30"
                    ></se-input-editor>
                    <se-input-editor
                        class="inputEditor"
                        id="companyName"
                        name="companyName"
                        type="text"
                        label="Company name"
                        labelPosition="top"
                        required
                        size="30"
                    ></se-input-editor>
                    <se-input-editor
                        class="inputEditor"
                        id="email"
                        name="email"
                        type="text"
                        label="Email"
                        labelPosition="top"
                        input-type="email"
                        required
                        size="30"
                    ></se-input-editor>
                    <se-input-editor
                        class="inputEditor"
                        @editorChanged=${this.passwordChanged}
                        id="password"
                        name="password"
                        type="text"
                        label="Password"
                        labelPosition="top"
                        input-type="password"
                        required
                        size="30"
                    ></se-input-editor>
                    <se-input-editor
                        class="inputEditor"
                        @editorChanged=${this.passwordChanged}
                        id="confirmPassword"
                        name="confirmPassword"
                        type="text"
                        label="Confirm password"
                        labelPosition="top"
                        input-type="password"
                        required
                        size="30"
                    ></se-input-editor>
                </div>
                <div class="savePanel">
                    <se-primary-button @click="${this.save}" ?disabled="${!this._hasChanged}" text="Create User"></se-primary-button>
                </div>
            </form>
            <div class="footer">
                <div>
                    © 2022 Sequentum • <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> •
                    <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
                </div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .header {
            box-sizing: border-box;
            background-color: var(--color-primary);
            color: white;
            padding-left: 15px;
            padding-top: 15px;
            display: flex;
            gap: 15px;
            font: var(--font-h3);
        }
        .h3 {
            font: var(--font-h3);
        }
        .header-logo {
            width: 175px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
            padding: 0px 30px 20px 30px;
            padding-top: 0px;
            border-radius: 5px 5px;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: hidden;
            padding: 10px 10px 20px 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .inputEditor {
            padding: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 0px 20px 10px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
            text-decoration: none;
        }
    `;
}
