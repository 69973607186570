
export class SubscriptionPlan {
        
    constructor() {        
    }
    id?: number;
    name: string;
    price?: number;
    runUsage?: number;
    exportedData?: number;
    exportedRows?: number;
    proxyUsage?: number;
    isActive?: boolean;
    created?: Date;
    updated?: Date;
}