import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ServerService } from "../../services/server.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { SeDataGrid } from "../components/data-grid.element";
import { DataGridColumn } from "../components/data-grid-template";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { choose } from "lit/directives/choose.js";
import { ServerStatus } from "../../enums/server-status";
import { ServerType } from "../../enums/server-type";
import { SelectEditorElement } from "../editors/select-editor.element";
import { format, sub, startOfDay, endOfDay, startOfMonth, endOfMonth, startOfYear, endOfYear } from "date-fns";
import { Chart, registerables } from "chart.js";
import { CloudProvider } from "../../enums/cloud-provider";
import { ServerAdminViewModel } from "../../models/server-admin-view-model";
Chart.register(...registerables);

@customElement("se-servers")
export class SeServesElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _serverService: ServerService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _menuService: MenuService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn: string;
    private _sortOrder = -1;
    private _type = -1;
    private _timer;
    @state() private _data: ServerAdminViewModel[] = [];

    @state() private _hasSelectedRows = false;
    @state() private _usageDateRangeValue = "1";
    @state() private _usageTimeUnit = "day";

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;
    @query("#usageDateRange") private _usageDateRange: SelectEditorElement;
    @query("#chartCanvas") private _chartCanvas: HTMLCanvasElement;

    @property({ type: Date }) startDate = startOfMonth(new Date()); //fetch current month daily usage data.
    @property({ type: Date }) endDate = endOfDay(new Date());
    @property({ type: Array }) data: any = [];
    @property({ type: Array }) labels: string[] = [];

    private chart?: Chart;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._serverService = container.resolve(ServerService);
        this._menuService = container.resolve(MenuService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [
            {
                text: row.status === ServerStatus.Disconnected ? "Connect" : "Reconnect",
                action: this.connectServerAsync.bind(this, row, col),
                hidden: row.status === ServerStatus.Stopped,
            },
            { text: "-" },
            {
                text: "Stop",
                action: this.deleteServerAsync.bind(this, row, col),
                hidden: row.status === ServerStatus.Stopped,
            },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceId = -7;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
        this.loadServersUsageData();

        // set a background timer to refresh the data every 10 seconds
        this._timer = setInterval(() => this.loadData(), 10000);
    }

    disconnectedCallback() {
        //clear the timer
        clearInterval(this._timer);

        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    private editServer(row: any, col: DataGridColumn) {
        Router.go(`/edit/server/${row.id}`);
    }

    private async deleteServerAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Server",
            body: `Are you sure you want to delete ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._serverService.api.deleteAsync(row.id);
            if (result.isOk) {
                const index = this._data.indexOf(row);
                this._data.splice(index, 1);
                if (row.selected) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async connectServerAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Connect Server",
            body: `Are you sure you want to connect ${row.name}?`,
            saveCaption: "Connect",
        });
        if (result.isSave) {
            const result = await this._serverService.api.connectAsync(row.id);
            if (result.isOk) {
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    async loadServersUsageData() {
        try {
            const response = await this._serverService.usageApi.getServersUsageAsync(
                this._usageTimeUnit,
                format(this.startDate, "yyyy-MM-dd"),
                format(this.endDate, "yyyy-MM-dd")
            );

            if (response.isOk) {
                const jsonData: any = await response.value;
                if (jsonData.includes("No data")) {
                    this.labels = [];
                    this.data = [];
                    this.updateChart();
                    this._toasterService.showWarning(jsonData);
                }
                await this.processApiData(jsonData);
                this.loadChart();
            } else {
                this._toasterService.showUnexpectedError(`Usage API request failed with Error: ${response.value}`);
            }
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    }

    processApiData(apiData: any) {
        this.data = apiData.map((item) => item.count);
        this.labels = apiData.map((item) => item.date.split("T")[0]);
        this.updateChart();
    }

    private async loadChart() {
        if (this._chartCanvas.getAttribute("prepared") === "true") {
            return;
        }

        this._chartCanvas.setAttribute("prepared", "true");
        const ctx = this._chartCanvas.getContext("2d");

        // Chart data
        const data = {
            labels: this.labels,
            datasets: [
                {
                    label: "Servers Usage",
                    data: this.data,
                    backgroundColor: "#3F8CD9", //'#568e74',
                    borderColor: "#3F8CD9", //'#568e74',
                    borderWidth: 1,
                },
            ],
        };

        // Chart options
        const options = {
            responsive: false,
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: "Count",
                        color: "#1a1f4b", //#4e4ef0
                        font: {
                            family: "Helvetica",
                            size: 16,
                            weight: "bold", // Font weight
                        },
                    },
                },
            },
        };

        // Create Chart.js instance
        this.chart = new Chart(ctx, {
            type: "bar",
            data: data,
            options: options,
        });
    }

    private updateChart(): void {
        if (this.chart) {
            this.chart.data.labels = this.labels;
            this.chart.data.datasets[0].data = this.data;
            this.chart.update();
        }
    }

    private handleDateChange() {
        this._usageDateRangeValue = this._usageDateRange.liveValue;
        this.updateDateRange();
    }

    private updateDateRange() {
        const today = new Date();
        switch (this._usageDateRangeValue) {
            /*case '3d':
                this.startDate = startOfDay(sub(today, { days: 3 }));
                this.endDate = endOfDay(add(today, { days: 1 }));
                this._usageTimeUnit = 'hour';
                break;
              case '1w':
                this.startDate = startOfDay(sub(today, { days: 7 }));
                this.endDate = endOfDay(sub(today, { days: 1 }));
                this._usageTimeUnit = 'day';
                break;
           */
            case "1d":
                this.startDate = startOfMonth(today);
                this.endDate = endOfDay(today);
                this._usageTimeUnit = "day";
                break;
            case "1m":
                this.startDate = startOfMonth(sub(today, { months: 1 }));
                this.endDate = endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = "day";
                break;
            case "3m":
                this.startDate = startOfMonth(sub(today, { months: 3 }));
                this.endDate = endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = "day";
                break;
            case "6m":
                this.startDate = startOfMonth(sub(today, { months: 6 }));
                this.endDate = endOfMonth(sub(today, { months: 1 }));
                this._usageTimeUnit = "day";
                break;
            case "1y":
                this.startDate = startOfYear(sub(today, { years: 1 }));
                this.endDate = endOfYear(sub(today, { years: 1 }));
                this._usageTimeUnit = "year";
                break;
            default:
                // Default to the current date range
                this.startDate = startOfDay(today);
                this.endDate = endOfDay(today);
                break;
        }
        this.loadServersUsageData();
    }

    private async loadData() {
        if (this._selectAll) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        }
        this._columns = [
            { field: "type", title: "Type", template: (row, col) => html`${row.type == ServerType.Editor ? "Editor" : "Run"}` },
            //{ field: 'description', title: 'Description' },
            { field: "host", title: "Host" },
            { field: "organizationName", title: "Organization" },
            { field: "cloud", title: "Cloud", template: (row, col) => html`${CloudProvider[row.cloud]}` },
            {
                field: "sessions",
                title: "Sessions",
                align: "center",
                template: (row, col) => html`${row.sessions ?? 0}/${row.sessionThreshold}`,
            },
            { field: "cpu", title: "CPU", align: "center", template: (row, col) => html`${row.cpu ?? 0}/${row.cpuThreshold}%` },
            { field: "mem", title: "Memory", align: "center", template: (row, col) => html`${row.mem ?? 0}/${row.memThreshold}%` },
            {
                field: "status",
                title: "Status",
                sortable: true,
                align: "center",
                template: (row, col) =>
                    html`${choose(
                        row.status,
                        [
                            [undefined || null, () => html`N/A`],
                            [ServerStatus.Disconnected, () => html`<span style="color:red">Disconnected</span>`],
                            [ServerStatus.Paused, () => html`<span style="color:darkgoldenrod">Paused</span>`],
                            [ServerStatus.Running, () => html`<span style="color:green">Running</span>`],
                            [ServerStatus.Unavailable, () => html`<span style="color:red">Unavailable</span>`],
                            [ServerStatus.Stopped, () => html`<span style="color:red">Stopped</span>`],
                            [ServerStatus.Stopping, () => html`<span style="color:red">Stopping</span>`],
                            [ServerStatus.Pending, () => html`<span style="color:darkgoldenrod">Pending</span>`],
                            [ServerStatus.Connecting, () => html`<span style="color:darkgoldenrod">Connecting</span>`],
                        ],
                        () => html`${row.status}`
                    )}`,
            },
            {
                field: "lastUsed",
                title: "Last Used",
                align: "center",
                sortable: true,
                template: (row, col) => {
                    if (row.lastUsed == null) {
                        return null;
                    }

                    //get the date and time from the lastUsed property
                    const dateTime = new Date(row.lastUsed).toLocaleDateString() + " " + new Date(row.lastUsed).toLocaleTimeString();
                    return html`${dateTime}`;
                },
            },
            {
                field: "lastSeen",
                title: "Last Seen",
                align: "center",
                sortable: true,
                template: (row, col) => {
                    if (row.lastSeen == null) {
                        return null;
                    }

                    //get the date and time from the lastSeen property
                    const dateTime = new Date(row.lastSeen).toLocaleDateString() + " " + new Date(row.lastSeen).toLocaleTimeString();
                    return html`${dateTime}`;
                },
            },
            {
                field: "created",
                title: "Created",
                align: "center",
                sortable: true,
                template: (row, col) => {
                    //get the date and time from the created property
                    const dateTime = new Date(row.created).toLocaleDateString() + " " + new Date(row.created).toLocaleTimeString();
                    return html`${dateTime}`;
                },
            },
            { field: "message", title: "" },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];
        const result = await this._serverService.api.getAllAdminServersAsync(
            this._pageIndex,
            this._recordsPerPage,
            this._sortColumn,
            this._sortOrder,
            this._type
        );
        if (result.isOk) {
            this._data = result.value.servers;
            this._totalRecordCount = result.value.totalRecordCount;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        // Reset select all checkbox state
        if (this._selectAll) {
            this._selectAll.value = false;
            this._selectAll.liveValue = false;
        }
        this._hasSelectedRows = false;
        this.loadData();
    }

    // the Select element uses this function to filter the servers output
    private filterByType(evt: any) {
        evt.stopPropagation();
        this._type = evt.currentTarget.value;
        this.loadData();
    }

    private newMenu(): MenuItem[] {
        return [
            {
                text: "New Editor server",
                action: () => {
                    Router.go(`/manage/admin/edit/editor-server`);
                },
            },
            {
                text: "New Run server",
                action: () => {
                    Router.go(`/manage/admin/edit/server`);
                },
            },
        ];
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Servers",
            body: `Are you sure you want to delete ${count === 1 ? `${this._dataGrid.selectedRows[0].name}` : `${count} servers`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const serverIds = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._serverService.api.deleteAllAsync(serverIds);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    newServer() {
        Router.go(`/edit/server`);
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>
                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete Selected")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                        <select @change=${this.filterByType} style="font:var(--font-input);width:100%;" @valueChanged=${this.filterByType}>
                            <option value="-1">All</option>
                            <option value="2">Editor</option>
                            <option value="1">Run</option>
                        </select>
                        <se-select-editor
                            class="inputEditor"
                            id="usageDateRange"
                            .value="${this._usageDateRangeValue}"
                            width="100%"
                            name="usageDateRange"
                            label=""
                            labelPosition="top"
                            @valueChanged=${this.handleDateChange}
                            .options=${[
                                { id: "1d", name: "Daily" },
                                { id: "1m", name: "Past 1 month" },
                                { id: "3m", name: "Past 3 months" },
                                { id: "6m", name: "Past 6 months" },
                                { id: "1y", name: "Past 1 year" },
                            ]}
                        ></se-select-editor>
                    </div>
                    <se-dropdown-button show-menu-onclick="true" text="New Server" .menu=${this.newMenu()}>New Server</se-dropdown-button>
                </div>

                <div class="usage-list">
                    <div class="left-content">
                        <canvas id="chartCanvas" style="width: 100%;height: 300px;"></canvas>
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No servers available."
                ></se-data-grid>
                <se-pagination .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .inputEditor {
            width: 200px;
        }
        .labelsFilters {
            display: flex;
            gap: 30px;
            flex-direction: column;
            padding: 20px 20px;
            place-content: center flex-start;
            align-items: center;
            height: 100%;
        }
        .usage-list {
            padding: 0px 15px;
            display: flex;
        }
        .left-content {
            flex: 1;
        }
        .right-content {
            flex: 0.25;
        }
    `;
}
