import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import { when } from "lit/directives/when.js";

@customElement("se-pagination")
export class SePaginationElement extends LitElement {
    @property({ type: Number }) pageIndex = 1;
    @property({ type: Number }) recordsPerPage = 100;
    @property({ type: Number }) recordCount = 0;
    @property({ type: Boolean }) syncWithUrl = true;
    
    private _initialUrlProcessed = false;

    connectedCallback() {
        super.connectedCallback();
        
        // Listen for browser back/forward
        window.addEventListener('popstate', this.handlePopState);
        
        if (this.syncWithUrl) {
            // requestAnimationFrame runs after all other initialization and but before the first render
            requestAnimationFrame(() => {
                this.processUrlParameters();
                this._initialUrlProcessed = true;
                
                // send that init is complete
                this.dispatchEvent(new CustomEvent("url-initialized", { 
                    bubbles: true, 
                    composed: true,
                    detail: { pageIndex: this.pageIndex }
                }));
            });
        }
    }

    disconnectedCallback() {
        window.removeEventListener('popstate', this.handlePopState);
        super.disconnectedCallback();
    }
    
    private processUrlParameters() {
        const urlParams = new URLSearchParams(window.location.search);
        const gridPage = parseInt(urlParams.get('gridPage') || '1');
        
        if (gridPage !== this.pageIndex) {
            this.pageIndex = gridPage;
            this.notifyPageChange();
        }
    }

    private handlePopState = (event: PopStateEvent) => {
        if (this.syncWithUrl) {
            if (event.state && event.state.fromUserInteraction) {
                this.processUrlParameters();
            }
        }
    }

    private notifyPageChange() {
        // Notify parent components
        this.dispatchEvent(new CustomEvent("pagechanged", { 
            bubbles: true, 
            composed: true, 
            detail: { pageIndex: this.pageIndex } 
        }));
    }

    onPageChange(e: Event, pageIndex: number) {
        e.stopPropagation();
        if (pageIndex === this.pageIndex) return;
        
        this.pageIndex = pageIndex;

        if (this.syncWithUrl) {
            // Update URL without triggering navigation
            const url = this.getPageUrl(this.pageIndex);
            history.pushState({ 
                gridPage: this.pageIndex,
                fromUserInteraction: true
            }, '', url);
        }

        this.notifyPageChange();
    }

    private getPageUrl(pageIndex: number) {
        const currentUrl = new URL(window.location.href);
        const searchParams = new URLSearchParams(currentUrl.search);
        searchParams.set("gridPage", pageIndex.toString());
        currentUrl.search = searchParams.toString();
        return currentUrl.toString();
    }

    render() {
        const pageCount = Math.floor(this.recordCount / this.recordsPerPage) + (this.recordCount % this.recordsPerPage > 0 ? 1 : 0);
        if (this.pageIndex < 1) this.pageIndex = 1;
        if (this.pageIndex > pageCount) this.pageIndex = pageCount;

        return html`
            <div style="display: flex; gap:5px">
                ${this.pageIndex > 1
                    ? html`<button @click="${(evt) => this.onPageChange(evt, this.pageIndex - 1)}">Previous</button>`
                    : html`<button class="disabled">Previous</button>`}

                <button @click=${(evt) => this.onPageChange(evt, 1)} class="${classMap({ selected: this.pageIndex === 1 })}">1</button>
                ${when(this.pageIndex > 4, () => html`<div>...</div>`)}
                ${when(
                    this.pageIndex < 5 && pageCount > 2,
                    () =>
                        html`<button @click="${(evt) => this.onPageChange(evt, 2)}" class="${classMap({ selected: this.pageIndex === 2 })}">
                            2
                        </button>`
                )}
                ${when(
                    this.pageIndex < 5 && pageCount > 3,
                    () =>
                        html`<button @click=${(evt) => this.onPageChange(evt, 3)} class=${classMap({ selected: this.pageIndex === 3 })}>
                            3
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 3 && this.pageIndex < 5 && pageCount > 4,
                    () =>
                        html`<button @click=${(evt) => this.onPageChange(evt, 4)} class=${classMap({ selected: this.pageIndex === 4 })}>
                            4
                        </button>`
                )}
                ${when(
                    this.pageIndex === 4 && pageCount > 5,
                    () =>
                        html`<button @click=${(evt) => this.onPageChange(evt, 5)} class=${classMap({ selected: this.pageIndex === 5 })}>
                            5
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 6 && this.pageIndex === pageCount,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex - 2)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex - 2 })}
                        >
                            ${this.pageIndex - 2}
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 5,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex - 1)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex - 1 })}
                        >
                            ${this.pageIndex - 1}
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 5 && this.pageIndex < pageCount,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex })}
                        >
                            ${this.pageIndex}
                        </button>`
                )}
                ${when(
                    this.pageIndex >= 5 && this.pageIndex < pageCount - 1,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, this.pageIndex + 1)}
                            class=${classMap({ selected: this.pageIndex === this.pageIndex + 1 })}
                        >
                            ${this.pageIndex + 1}
                        </button>`
                )}
                ${when(pageCount > 5 && this.pageIndex < pageCount - 2, () => html`<div>...</div>`)}
                ${when(
                    pageCount > 1,
                    () =>
                        html`<button
                            @click=${(evt) => this.onPageChange(evt, pageCount)}
                            class=${classMap({ selected: this.pageIndex === pageCount })}
                        >
                            ${pageCount}
                        </button>`
                )}
                ${this.pageIndex < pageCount
                    ? html`<button @click=${(evt) => this.onPageChange(evt, this.pageIndex + 1)}>Next</button>`
                    : html`<button class="disabled">Next</button>`}
            </div>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: 5px;
        }
        button {
            border: 1px solid Silver;
            background-color: var(--color-light);
            padding: 1px 5px 1px 5px;
        }
        button:hover {
            background-color: white;
            cursor: pointer;
        }
        .selected {
            border: none;
            font-weight: bold;
        }
        .selected:hover {
            background-color: var(--color-light);
            cursor: default;
        }
        .disabled {
            color: DarkGray;
        }
        .disabled:hover {
            background-color: var(--color-light);
            cursor: default;
        }
    `;
}
