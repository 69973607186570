import { Router } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement } from "lit/decorators.js";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import '../components/cards/card.element';
import '../components/cards/card-buttons.element';
import { UserState } from "../../services/user.state";
import { ModalDialogService } from "../../services/modal-editor.service";

@customElement("se-user-logout")
export class SeUserLogoutElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _modalService: ModalDialogService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -4;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    private async onLogout() {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Confirm Logout",
            body: "Are you sure you want to log out?",
            saveCaption: "Logout",
            cancelCaption: "Cancel"
        });

        if (result.isSave) {
            this._authService.logout();
            document.location = "/login";
        }
    }



    render() {
        return html`
            <se-card simple>
                <div>Click the button below to log out of your account.</div>
            </se-card>
            <se-card-buttons>
                <se-primary-button 
                    text="Logout" 
                    @click="${this.onLogout}"
                ></se-primary-button>
            </se-card-buttons>
        `;
    }

    static styles = css`
        :host {
            display: block;
            height: 100%;
        }
    `;
} 