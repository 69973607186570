export class AppSettings {
    constructor() {}

    isSystemAuditEnabled: boolean;
    maxRunServers?: number;
    maxEditorServers?: number;
    maxOrganizationRunServers?: number;
    maxOrganizationEditorServers?: number;
    maxOrganizationPendingRunServers?: number;
    minOrganizationQueueSize?: number;
    minIdleRunServers?: number;
    minIdleEditorServers?: number;
    editorServerSessionThreshold?: number;
    runServerSessionThreshold?: number;
}
