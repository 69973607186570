import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { SortOrder } from "se-shared/enums/sort-order";
import { AuthService } from "../../services/auth.service";
import { LabelService } from "../../services/label.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ServerGroupService } from "../../services/server-group.service";
import { ServerService } from "../../services/server.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserState } from "../../services/user.state";
import { SeDataGrid } from "../components/data-grid.element";
import { DataGridColumn } from "../components/data-grid-template";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { choose } from "lit/directives/choose.js";
import { ServerStatus } from "../../enums/server-status";

@customElement("se-organization-servers")
export class SeOrganizationServesElement extends LitElement {
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _serverService: ServerService;
    private _serverGroupService: ServerGroupService;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn: string;
    private _sortOrder = -1;
    @state() private _data: any[] = [];

    @state() private _hasSelectedRows = false;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._serverService = container.resolve(ServerService);
        this._serverGroupService = container.resolve(ServerGroupService);
    }

    private menu(row: any, col: DataGridColumn): MenuItem[] {
        return [{ text: "Delete", action: this.deleteServerAsync.bind(this, row, col) }];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    private editServer(row: any, col: DataGridColumn) {
        Router.go(`/edit/server/${row.id}`);
    }

    private async deleteServerAsync(row: any, col: DataGridColumn) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Server",
            body: `Are you sure you want to delete ${row.name}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._serverService.api.deleteAsync(row.id);
            if (result.isOk) {
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    firstUpdated() {}

    private async onDescriptionChanged(
        newValue: string,
        row?: any,
        col?: DataGridColumn
    ): Promise<{ success: boolean; errorMessage?: string }> {
        const result = await this._serverService.api.updateAsync(row.id, {
            name: row.name,
            description: newValue,
            serverGroupId: row.serverGroupId,
        });
        if (result.isOk) {
            return { success: true };
        } else {
            return { success: false, errorMessage: result.err.message };
        }
    }
    private async onNameChanged(newValue: string, row?: any, col?: DataGridColumn): Promise<{ success: boolean; errorMessage?: string }> {
        const result = await this._serverService.api.updateAsync(row.id, {
            name: newValue,
            description: row.description,
            serverGroupId: row.serverGroupId,
        });
        if (result.isOk) {
            return { success: true };
        } else {
            return { success: false, errorMessage: result.err.message };
        }
    }
    private async onServerGroupChanged(
        newValue: string,
        row?: any,
        col?: DataGridColumn
    ): Promise<{ success: boolean; errorMessage?: string }> {
        const result = await this._serverService.api.updateAsync(row.id, {
            name: row.name,
            description: row.description,
            serverGroupId: newValue ? parseInt(newValue) : null,
        });
        if (result.isOk) {
            return { success: true };
        } else {
            return { success: false, errorMessage: result.err.message };
        }
    }

    private async loadData() {
        const groupResult = await this._serverGroupService.api.getListAsync();
        if (groupResult.isOk) {
            const groups = groupResult.value.serverGroups;
            groups.splice(0, 0, { id: null, name: "Default", description: "" });
            this._columns = [
                { field: "name", title: "Name", sortable: true, editor: "text", setValue: this.onNameChanged.bind(this) },
                { field: "description", title: "Description", editor: "text-area", setValue: this.onDescriptionChanged.bind(this) },
                { field: "regionName", title: "Region" },
                {
                    field: "serverGroupId",
                    title: "Group",
                    editor: "select",
                    options: groups,
                    setValue: this.onServerGroupChanged.bind(this),
                },
                {
                    field: "sessions",
                    title: "Sessions",
                    align: "center",
                    template: (row, col) => html`${row.sessions ?? 0}/${row.sessionThreshold}`,
                },
                { field: "cpu", title: "CPU", align: "center", template: (row, col) => html`${row.cpu ?? 0}/${row.cpuThreshold}%` },
                { field: "mem", title: "Memory", align: "center", template: (row, col) => html`${row.mem ?? 0}/${row.memThreshold}%` },
                {
                    field: "status",
                    title: "Status",
                    sortable: true,
                    align: "center",
                    template: (row, col) =>
                        html`${choose(
                            row.status,
                            [
                                [undefined || null, () => html`N/A`],
                                [ServerStatus.Disconnected, () => html`<span style="color:red">Disconnected</span>`],
                                [ServerStatus.Paused, () => html`<span style="color:darkgoldenrod">Paused</span>`],
                                [ServerStatus.Running, () => html`<span style="color:green">Running</span>`],
                                [ServerStatus.Unavailable, () => html`<span style="color:red">Unavailable</span>`],
                                [ServerStatus.Stopped, () => html`<span style="color:red">Stopped</span>`],
                                [ServerStatus.Stopping, () => html`<span style="color:red">Stopping</span>`],
                                [ServerStatus.Pending, () => html`<span style="color:darkgoldenrod">Pending</span>`],
                                [ServerStatus.Connecting, () => html`<span style="color:darkgoldenrod">Connecting</span>`],
                            ],
                            () => html`${row.status}`
                        )}`,
                },
                {
                    field: "updated",
                    title: "Updated",
                    align: "center",
                    sortable: true,
                    template: (row, col) => html`${new Date(row.updated).toLocaleDateString()}`,
                },
                { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
            ];
        } else {
            this._toasterService.showUnexpectedError(groupResult.err.message);
        }
        const result = await this._serverService.api.getAllOrganizationServersAsync(
            this._pageIndex,
            this._recordsPerPage,
            this._sortColumn,
            this._sortOrder
        );
        if (result.isOk) {
            this._data = result.value.servers;
            this._totalRecordCount = result.value.totalRecordCount;
        } else {
            this._toasterService.showUnexpectedError(result.err.message);
        }
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private gridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private refresh() {
        this.loadData();
    }

    newServer() {
        Router.go(`/buy/server`);
    }

    private onAddToServerGroup() {}

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${this._data.length === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onAddToServerGroup}
                                      ${htmlTitle("Add To Server Group")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                    <se-primary-button text="Buy Server" @click="${this.newServer}"></se-primary-button>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .recordsPerPage=${this._recordsPerPage}
                    .pageIndex=${this._pageIndex}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.gridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No servers available."
                ></se-data-grid>
                    <se-pagination .recordCount=${this._totalRecordCount} @pagechanged=${this.onPageChanged}></se-pagination>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
