import { LitElement, html, css } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { live } from "lit/directives/live.js";
import "element-internals-polyfill";
import { BaseEditor } from "../base-editor";
import { styleMap } from "lit/directives/style-map.js";

@customElement("se-form-input-editor")
export class FormInputEditorElement extends LitElement implements BaseEditor {
    @property() value: string;
    @property({ type: Boolean }) isSettingsStyle = true;
    @property({ type: Boolean }) checked = false;

    get liveValue(): string {
        return String(this._inputElement?.value ?? "");
    }
    get isChecked(): boolean {
        return this._inputElement?.checked ?? false;
    }

    @property() label?: string;
    @property() labelHelp?: string;

    @property({ attribute: "input-width" }) inputWidth?: string;
    @property({ type: Number }) step?: number;
    @property({ type: Number }) size?: number;
    @property({ type: Number }) minlength?: number;
    @property({ type: Number }) maxlength?: number;
    @property() min?: string;
    @property() max?: string;
    @property() width?: string;
    @property() pattern?: string;
    @property() placeholder?: string;
    @property({ type: Boolean, attribute: "use-auto-complete" }) useAutoComplete = false;
    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) required = false;
    @property({ type: Boolean }) disabled = false;
    @property({ type: Boolean }) nonNegative = false;
    @property() name: string;
    @property({ attribute: "size" }) editorSize: "small" | "normal";
    @property() gap = "0px";
    @property({ attribute: "input-type" }) type:
        | "button"
        | "checkbox"
        | "color"
        | "date"
        | "datetime-local"
        | "email"
        | "file"
        | "hidden"
        | "image"
        | "month"
        | "number"
        | "password"
        | "radio"
        | "range"
        | "reset"
        | "search"
        | "submit"
        | "tel"
        | "text"
        | "time"
        | "url"
        | "week" = "text";

    @query("input") private _inputElement: HTMLInputElement;

    constructor() {
        super();
    }

    valueChanged() {
        this.dispatchEvent(
            new CustomEvent("valueChanged", {
                bubbles: true,
                composed: true,
                detail: { editor: this, value: this.liveValue, oldValue: this.value },
            })
        );
    }

    updateValue(event) {
        // Reset any custom validity
        this._inputElement.setCustomValidity('');
        
        // Check for non-negative constraint if needed
        if (this.nonNegative) {
            const value = parseFloat(this._inputElement.value);
            if (!isNaN(value) && value < 0) {
                this._inputElement.setCustomValidity(`${this.label || 'Value'} cannot be negative`);
            }
        }
        
        if (this._inputElement.checkValidity()) this._inputElement.classList.remove("invalid");
        else this._inputElement.classList.add("invalid");
        this.dispatchEvent(
            new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this, value: this.liveValue } })
        );
    }

    reportValidity(): boolean {
        if (this._inputElement.reportValidity()) {
            this._inputElement.classList.remove("invalid");
            return true;
        } else {
            this._inputElement.classList.add("invalid");
            return false;
        }
    }

    setCustomValidity(message: string) {
        this._inputElement.setCustomValidity(message);
    }

    firstUpdated() {}

    cancel() {
        this._inputElement.classList.remove("invalid");
        this.requestUpdate();
    }

    render() {
        const editorStyle =
            this.editorSize === "small"
                ? { font: "var(--font-ui)", width: this.inputWidth ?? this.width }
                : { font: "var(--font-input)", width: this.inputWidth ?? this.width };

        return html`
            <div class="form-row">
                <label for="${this.name}">
                    <div><span>${this.label}</span></div>
                    <div class="help">${this.labelHelp}</div>
                </label>
                <div class="input-container">
                    <input
                        style="${styleMap(editorStyle)}"
                        class="input-settings"
                        name="${this.name}"
                        type="${this.type}"
                        .value="${live(this.value ?? "")}"
                        ?checked="${this.checked}"
                        @input="${this.updateValue}"
                        @change="${this.valueChanged}"
                        min="${ifDefined(this.min)}"
                        max="${ifDefined(this.max)}"
                        maxlength="${ifDefined(this.maxlength)}"
                        minlength="${ifDefined(this.minlength)}"
                        step="${ifDefined(this.step)}"
                        size="${ifDefined(this.size)}"
                        pattern="${ifDefined(this.pattern)}"
                        placeholder="${ifDefined(this.placeholder)}"
                        ?required="${this.required}"
                        ?disabled="${this.disabled}"
                        ?readonly="${this.readonly}"
                        autocomplete=${this.useAutoComplete ? "on" : "off"}
                    />
                </div>
                
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            width: 100%;
        }
        :host * {
            box-sizing: border-box;
        }
        input {
            font: var(--font-input);
            width: 100%;
        }
        input:disabled + label {
            color: gray;
        }
        input.invalid {
            outline: 2px solid pink;
        }
        .form-row {
            padding: 10px;
            align-items: center;
            display: flex;
            transition: background 0.15s;
            border-bottom: 1px solid rgb(240, 236, 243);
        }
        .form-row label {
            font-weight: 400;
            display: inline;
            margin-bottom: 0px;
            width: 50%;
            flex-shrink: 0;
        }
        .input-container {
            display: flex;
            flex: 1 1 0%;
            flex-direction: column;
            position: relative;
            max-width: 100%;
        }
        .input-settings {
            font: var(--font-input);
            border-radius: 8px;
            border: 1px solid var(--color-gray-4);
            padding: 8px 12px;
            background: var(--color-background);
        }
        label {
            font: var(--font-input-label);
            margin-bottom: 4px;
        }

        label .help {
            color: rgb(128, 112, 143);
            font-size: 12px;
            margin-top: 4px;
            line-height: 1.4;
        }

    `;
}
