import { container, singleton } from "tsyringe";
import { AppAuthDetails } from "../models/app-auth-details";
import { AppConfigApi } from "./app-config.api";
import { AuthService } from "./auth.service";
import * as Sentry from "@sentry/browser";

@singleton()
export class AppConfigService {
    private _appConfigApi: AppConfigApi;
    private _authService: AuthService;

    private _appAuthDetails: AppAuthDetails;

    get isNew() {
        return this._appAuthDetails.isNew;
    }

    get version() {
        return this._appAuthDetails.versionString;
    }
    get nativeVersion() {
        return this._appAuthDetails.nativeVersionString;
    }
    get isGoogleAuthenticationSupport() {
        return this._appAuthDetails.isGoogleAuthenticationSupport;
    }
    get googleAuthenticationClientId() {
        return this._appAuthDetails.googleAuthenticationClientId;
    }
    get isMicrosoftAuthenticationSupport() {
        return this._appAuthDetails.isMicrosoftAuthenticationSupport;
    }
    get isOktaAuthenticationSupport() {
        return this._appAuthDetails.isOktaAuthenticationSupport;
    }
    get isShowWelcome() {
        return this._appAuthDetails?.isShowWelcome ?? false;
    }
    set isShowWelcome(value: boolean) {
        if (this._appAuthDetails) {
            this._appAuthDetails.isShowWelcome = value;
        }
    }
    constructor() {
        this._appConfigApi = container.resolve(AppConfigApi);
        this._authService = container.resolve(AuthService);
    }

    async initializeAsync() {
        const res = await this._appConfigApi.getAuthDetailsAsync();
        if (res.isOk) {
            this._appAuthDetails = res.value;
            this.initSentry();
        } else {
            throw res.err;
        }
    }

    async createFirstUserAsync(name: string, organizationName: string, email: string, password: string) {
        const res = await this._appConfigApi.createFirstUserAsync(name, organizationName, email, password);
        if (res.isOk) {
            await this.initializeAsync();
            this._authService.login(res.value);
        } else {
            throw res.err;
            //alert(res.err.message);
        }
    }

    async registerUserAsync(name: string, organizationName: string, email: string, password: string) {
        const res = await this._appConfigApi.registerUserAsync(name, organizationName, email, password);
        if (res.isOk) {
            //logging in after registering is disabled
            //await this.initializeAsync();
            //this._authService.login(res.value);
        } else {
            throw res.err;
            //alert(res.err.message);
        }
    }

    /**
     * Turn on sentry debugging
    */
    private initSentry() {

        if (!this._appAuthDetails.isSentryEnabled) {
            return;
        }

        Sentry.init({
            dsn: "https://faee3fb15cd32f11ddbc18a8d9c01dd0@o4508303997927424.ingest.us.sentry.io/4508304047931392",
            environment: this._appAuthDetails.environment,
            release: this._appAuthDetails.versionString,
            integrations: [
                Sentry.browserTracingIntegration(),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
            ],
            // Tracing
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                "dashboard.sequentum.com",
                "dashboard-qa.sequentum.com"
            ],
            // Session Replay
            replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }

    /**
     * Validates password strength according to requirements:
     * - Minimum length of 8 characters
     * - At least one uppercase letter
     * - At least one lowercase letter
     * - At least one number
     * @param password The password to validate
     * @returns An object with isValid boolean and error message string
     */
    validatePassword(password: string): { isValid: boolean; message: string } {
        if (!password || password.length < 8) {
            return { isValid: false, message: 'Password must be at least 8 characters long' };
        }

        if (!/[A-Z]/.test(password)) {
            return { isValid: false, message: 'Password must contain at least one uppercase letter' };
        }

        if (!/[a-z]/.test(password)) {
            return { isValid: false, message: 'Password must contain at least one lowercase letter' };
        }

        if (!/[0-9]/.test(password)) {
            return { isValid: false, message: 'Password must contain at least one digit' };
        }

        return { isValid: true, message: '' };
    }
}
