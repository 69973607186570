import { ProxiesViewModel } from "./proxies-view-model";

export class ProxyViewModel {
        
    constructor() {        
    }
    
    name?: string;
    price?: number;
    displayPrice?: number;
    description?: string;
    shared?: boolean;
    defaultPool?: boolean;
    proxies?: ProxiesViewModel[]
}