import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AuthService } from "../../services/auth.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { SubscriptionPlanService } from "../../services/subscription-plan.service";
import { SubscriptionPlan } from "../../models/subscription-plan-view-model";
import { SeSubscriptionPlanEditorFormElement } from "../components/billing/subscription-plan-editor-form.element";

@customElement("se-subscription-plan-editor")
export class SeSubscriptionPlanEditorElement extends LitElement {
    private _authService: AuthService;
    private _modalService: ModalDialogService;
    private _isNew = false;
    private _planId?: number;
    private _subscriptionPlan?: SubscriptionPlan;
    private _subscriptionPlanService: SubscriptionPlanService;
    private _toasterService: ToasterService;

    @query("se-subscription-plan-editor-form") private _form: SeSubscriptionPlanEditorFormElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._subscriptionPlanService = container.resolve(SubscriptionPlanService);
        this._toasterService = container.resolve(ToasterService);
        this._modalService = container.resolve(ModalDialogService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isLoggedIn) {
            return commands.redirect("/login");
        }
        
        if (location.params.planId) {
            this._planId = parseInt(location.params.planId.valueOf() as string);
            this._isNew = false;
            const res = await this._subscriptionPlanService.api.getSubscriptionPlanAsync(this._planId);
            if (res.isOk && res.value) {
                this._subscriptionPlan = res.value;
            } else {
                this._toasterService.showError("Subscription plan not found");
                return commands.prevent();
            }
        } else {
            this._isNew = true;
            this._subscriptionPlan = new SubscriptionPlan();
        }
    }

    private async handleSave(
        name: string, 
        price: number, 
        runUsage: number, 
        exportedData: number, 
        exportedRows: number,
        proxyUsage: number,
        isActive: boolean
    ) {
        if (this._isNew) {
            await this.createNewSubscriptionPlan(name, price, runUsage, exportedData, exportedRows, proxyUsage, isActive);
        } else {
            await this.updateSubscriptionPlan(name, price, runUsage, exportedData, exportedRows, proxyUsage, isActive);
        }
    }

    async createNewSubscriptionPlan(
        name: string, 
        price: number, 
        runUsage: number, 
        exportedData: number, 
        exportedRows: number,
        proxyUsage: number,
        isActive: boolean
    ) {
        const res = await this._subscriptionPlanService.api.createSubscriptionPlanAsync({
            name,
            price,
            runUsage,
            exportedData,
            exportedRows,
            proxyUsage,
            isActive
        } as SubscriptionPlan);

        if (res.isOk) {
            history.back();
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    async updateSubscriptionPlan(
        name: string, 
        price: number, 
        runUsage: number, 
        exportedData: number, 
        exportedRows: number,
        proxyUsage: number,
        isActive: boolean
    ) {
        if (!this._planId) return;
        
        const res = await this._subscriptionPlanService.api.updateSubscriptionPlanAsync(this._planId, {
            id: this._planId,
            name,
            price,
            runUsage,
            exportedData,
            exportedRows,
            proxyUsage,
            isActive
        } as SubscriptionPlan);

        if (res.isOk) {
            history.back();
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    render() {
        return html`
            <se-subscription-plan-editor-form
                .isNew=${this._isNew}
                .subscriptionPlan=${this._subscriptionPlan}
                .onSave=${(name: string, price: number, runUsage: number, exportedData: number, exportedRows: number, proxyUsage: number, isActive: boolean) => 
                    this.handleSave(name, price, runUsage, exportedData, exportedRows, proxyUsage, isActive)}
                .onCancel=${() => history.back()}
            ></se-subscription-plan-editor-form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
    `;
}
