import { PreventAndRedirectCommands } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AppSettings } from "../../models/app-settings";
import { AppSettingsApi } from "../../services/app-settings.api";
import { AuthService } from "../../services/auth.service";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { UserState } from "../../services/user.state";
import { NumberEditorElement } from "../editors/number-editor.element";

@customElement("se-app-settings")
export class SeAppSettingsElement extends LitElement {
    private _authService: AuthService;
    private _appSettingsApi: AppSettingsApi;
    private _settings: AppSettings;
    private _toasterService: ToasterService;
    private _userState: UserState;

    @state() private _isLoading = true;

    @query("#isSystemAuditEnabled") private _isSystemAuditEnabledEditor: CheckboxEditorElement;
    @query("#maxEditorServers") private _maxEditorServers: NumberEditorElement;
    @query("#maxRunServers") private _maxRunServers: NumberEditorElement;
    @query("#maxOrganizationRunServers") private _maxOrganizationRunServers: NumberEditorElement;
    @query("#maxOrganizationPendingRunServers") private _maxOrganizationPendingRunServers: NumberEditorElement;
    @query("#minOrganizationQueueSize") private _minOrganizationQueueSize: NumberEditorElement;
    @query("#maxOrganizationEditorServers") private _maxOrganizationEditorServers: NumberEditorElement;
    @query("#minIdleEditorServers") private _minIdleEditorServers: NumberEditorElement;
    @query("#minIdleRunServers") private _minIdleRunServers: NumberEditorElement;
    @query("#runServerSessionThreshold") private _runServerSessionThreshold: NumberEditorElement;
    @query("#editorServerSessionThreshold") private _editorServerSessionThreshold: NumberEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._appSettingsApi = container.resolve(AppSettingsApi);
        this._settings = container.resolve(AppSettings);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
    }

    async onBeforeEnter(commands: PreventAndRedirectCommands) {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -7;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadSettingsAsync();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async loadSettingsAsync() {
        try {
            this._isLoading = true;
            const res = await this._appSettingsApi.getAppSettingsAsync();
            if (res.isOk) {
                this._settings = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        } finally {
            this._isLoading = false;
        }
    }

    firstUpdated() {}

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            this._settings.isSystemAuditEnabled = this._isSystemAuditEnabledEditor.liveValue;
            this._settings.maxEditorServers = this._maxEditorServers.liveValue;
            this._settings.maxRunServers = this._maxRunServers.liveValue;
            this._settings.maxOrganizationEditorServers = this._maxOrganizationEditorServers.liveValue;
            this._settings.maxOrganizationRunServers = this._maxOrganizationRunServers.liveValue;
            this._settings.minIdleEditorServers = this._minIdleEditorServers.liveValue;
            this._settings.maxOrganizationPendingRunServers = this._maxOrganizationPendingRunServers.liveValue;
            this._settings.minOrganizationQueueSize = this._minOrganizationQueueSize.liveValue;
            this._settings.minIdleRunServers = this._minIdleRunServers.liveValue;
            this._settings.runServerSessionThreshold = this._runServerSessionThreshold.liveValue;
            this._settings.editorServerSessionThreshold = this._editorServerSessionThreshold.liveValue;
            const res = await this._appSettingsApi.updateAppSettingsAsync(this._settings);
            if (res.isOk) {
                this._toasterService.showSuccess("App settings saved successfully");
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    render() {
        return html`
            <se-loading-panel
                id="loadingPanel"
                .loadingStyle=${{ borderTop: "solid 1px lightgray", backgroundColor: "white", minHeight: "200px", minWidth: "200px" }}
                .isLoading=${this._isLoading}
            >
                <se-edit-form-card cardTitle="Application Settings">
                    <se-form-toggle-editor
                        id="isSystemAuditEnabled"
                        label="Enable system audit log"
                        labelHelp="System audit includes changes such as organization and rate limit changes"
                        labelPosition="right"
                        style="width: 100%"
                        .value=${this._settings.isSystemAuditEnabled}
                    ></se-form-toggle-editor>
                    <se-form-number-editor
                        id="maxRunServers"
                        label="Max run servers"
                        labelHelp="Maximum number of run servers across all organizations"
                        labelPosition="top"
                        placeholder="100"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.maxRunServers}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="maxEditorServers"
                        label="Max editor servers"
                        labelHelp="Maximum number of editor servers across all organizations"
                        placeholder="50"
                        labelPosition="top"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.maxEditorServers}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="maxOrganizationEditorServers"
                        label="Default max editor servers per organization"
                        labelHelp="Default maximum number of editor servers that can be assigned to a single organization"
                        placeholder="10"
                        labelPosition="top"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.maxOrganizationEditorServers}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="maxOrganizationRunServers"
                        label="Default max run servers per organization"
                        labelHelp="Default maximum number of run servers that can be assigned to a single organization"
                        placeholder="20"
                        labelPosition="top"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.maxOrganizationRunServers}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="maxOrganizationPendingRunServers"
                        label="Default max pending run servers per organization"
                        labelHelp="Default maximum number of pending run servers that can be started by a single organization"
                        placeholder="20"
                        labelPosition="top"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.maxOrganizationPendingRunServers}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="minIdleRunServers"
                        label="Min idle run servers"
                        labelHelp="Minimum number of idle shared run servers ready to be assigned to organizations"
                        placeholder="1"
                        labelPosition="top"
                        min="0"
                        input-width="75px"
                        .value=${this._settings?.minIdleRunServers}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="minIdleEditorServers"
                        label="Min idle editor servers"
                        labelHelp="Minimum number of idle shared editor servers ready to be assigned to organizations"
                        placeholder="2"
                        labelPosition="top"
                        min="0"
                        input-width="75px"
                        .value=${this._settings?.minIdleEditorServers}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="runServerSessionThreshold"
                        label="Default run server session threshold"
                        labelHelp="Default number of agent run sessions that can be assigned to one run server"
                        placeholder="1"
                        labelPosition="top"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.runServerSessionThreshold}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="editorServerSessionThreshold"
                        label="Default editor server session threshold"
                        labelHelp="Default number of editor sessions that can be assigned to one editor server"
                        placeholder="3"
                        labelPosition="top"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.editorServerSessionThreshold}
                    ></se-form-number-editor>
                    <se-form-number-editor
                        id="minOrganizationQueueSize"
                        label="Default min queue size per organization"
                        labelHelp="Default min queue size required before an organization can start new run servers"
                        placeholder="1"
                        labelPosition="top"
                        min="1"
                        input-width="75px"
                        .value=${this._settings?.minOrganizationQueueSize}
                    ></se-form-number-editor>
                </se-edit-form-card>
                <se-card-buttons>
                    <se-primary-button .action="${() => this.saveAsync()}" action-delay="500" text="Save Settings"></se-primary-button>
                </se-card-buttons>
            </se-loading-panel>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            gap: 10px;
            flex-direction: column;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
}
